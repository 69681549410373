/**
 * This file created by HAUYANG on 27/09/2017.
 * Copyright 2019-present, Alpstein Technology.
 * All rights reserved.
 */

import axios from "axios";
import _ from "lodash";
import Helpers from "../../utils/Helpers";
import Environment from "../../utils/Environment";
import Constant from "../../constants";
import Router from "next/router";


export const AUTH_TOKEN_HEADER = "x-auth-token";
export const AUTH_AGENT_TOKEN_HEADER = "x-account-token";

/**
 * Axios Api Instance Configuration
 * @type {AxiosInstance}
 */
function createInstance() {
  const instance = axios.create({
    baseURL: process.env.API_URL,
    timeout: 30000 /** HttpTimeout:30sec **/,
    headers: {
      "Content-Type": "application/json",

    }
  });

  instance.interceptors.request.use(async config => {
    config.headers[AUTH_TOKEN_HEADER] = await localStorage.getItem(
      Constant.PG_TOKEN
    );
    config.headers[AUTH_AGENT_TOKEN_HEADER] = await localStorage.getItem(
        Constant.PG_AGENT_TOKEN
    );
    // config.headers["Accept-Language"] = await localStorage.getItem(
    //   "EWF_LANGUAGE"
    // );

    return config;
  });

  instance.interceptors.response.use(
    config => {
  if (config.status === 201){
    Router.push("/agent")
  }
      return config
    },
    error => {
      if (
        error.code === "ECONNABORTED" ||
        _.get(error, ["response", "status"], "") === 408
      ) {
        // Toast.error(I18n.t("general.errorMsg.networkTimeoutError"));
      }
      return Promise.reject(error);
    }
  );

  return instance;
}

const apiInstance = createInstance();

export default apiInstance;
