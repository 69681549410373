/**
 * This file created by HAUYANG on 27/09/2017.
 * Copyright 2017-present, rn-boilerplate.
 * All rights reserved.
 */

import Toast from "../../utils/Toast";
import _ from "lodash";
import Router  from "next/router";
import * as profileActions from "../../actions/profile";
import {getAuth,signOut} from "firebase/auth"
import * as authActions from "../../actions/auth";

/**
 * The request was made and the server responded with a status code
 * that falls out of the range of 2xx
 * @param error
 * @param action
 * @param effects
 * @param ignoreToast
 * @param overrideErrorToastToInfo - To change error to info message
 * @param errorData
 */
function* httpErrorHelpers(
  error,
  action,
  effects,
  ignoreToast = false,
  overrideErrorToastToInfo = false,
  errorData
) {
  const { call, put } = effects;
  if (error.response) {
    yield call(
      handleApiResponseError,
      error.response,
      effects,
      ignoreToast,
      overrideErrorToastToInfo,
      action,
      errorData
    );

    return;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  }

  const status = _.get(error, "response.status", null);

  if (!_.isEmpty(error.message)) {
    !ignoreToast
      ? !overrideErrorToastToInfo
        ? Toast.error(error.message)
        : Toast.info(error.message)
      : false;
    yield put(action(!_.isEmpty(errorData) ? errorData : error.message), status);
  } else {
    const unknownErrorMsg = `Unknown ${status} Error`;

    Toast.error(unknownErrorMsg);
    yield put(action(unknownErrorMsg, status));
  }
}

/**
 * This function will handle error from the response
 * @param response
 * @param put
 * @param select
 * @param ignoreToast
 * @param overrideErrorToastToInfo - To change error to info message
 * @param action
 * @param errorData
 */
function* handleApiResponseError(
  response,
  { put },
  ignoreToast,
  overrideErrorToastToInfo = false,
  action,
  errorData
) {
  const statusCode = _.get(response, "status", null);
  if (statusCode === 401) {
    Router.push("/login");
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
    yield put(authActions.requestLogout());

  }

  if (statusCode === 403) {
   // Router.push("/contact");
  }

  const messages = _.get(response, "data.messages", null);

  if (!_.isEmpty(messages) && !ignoreToast) {
    const { text, type } = messages[0];
    type === "error" ? (!overrideErrorToastToInfo ? Toast.error(text) : Toast.info(text)) : Toast.info(text);

    yield put(action(!_.isEmpty(errorData) ? errorData : text, statusCode), statusCode);
  } else {
    const unknownErrorMsg = `Unknown ${statusCode} Error`;

    Toast.error(unknownErrorMsg);
    yield put(action(unknownErrorMsg, statusCode));
  }
}

export default httpErrorHelpers;
