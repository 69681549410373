import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";

export default api => {
  function* watcherGetCommonDataRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getCommonData);

      yield put(commonAction.getCommonDataSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, commonAction.getCommonDataFailure, effects);
    }
  }
  function* watcherGetTopUpContentRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getTopUpContent);

      yield put(commonAction.getTopUpContentSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, commonAction.getTopUpContentFailure, effects);
    }
  }

  function* watcherGetCategoryListRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getCategoryList);

      yield put(commonAction.getCategoryListSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, commonAction.getCategoryListFailure, effects);
    }
  }

  function* watcherGetTagListRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getTagList);

      yield put(commonAction.getTagListSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, commonAction.getTagListFailure, effects);
    }
  }

  function* watcherPostLoggingInterestRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postLoggingInterest,postData);

      yield put(commonAction.postLoggingInterestSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, commonAction.postLoggingInterestFailure, effects);
    }
  }

  function* watcherGetCommonFaqGeneralRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getFaqGeneral);

      const {items} = response.data
      yield put(commonAction.getCommonFaqGeneralSuccess(items));
    } catch (error) {
      yield call(httpErrorHelpers, error, commonAction.getCommonFaqGeneralFailure, effects);
    }
  }
// ====  Loan Calculation Form ====
  function* watcherPostLoggingInterestLoanRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postLoggingInterestLoan,postData);

      yield put(commonAction.postLoggingInterestLoanSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, commonAction.postLoggingInterestLoanFailure, effects);
    }
  }


  const INITIAL_STATE = Immutable({
    commonData: null
  });

  return {
    namespace: "common",
    state: INITIAL_STATE,
    reducers: {
      GET_COMMON_DATA_REQUEST(state){
        return state.setIn(['commonDataLoading'],true)
      },
      GET_COMMON_DATA_SUCCESS(state, action) {
        return state.setIn(["commonData"], action.data).setIn(['commonDataLoading'],false)
      },
      GET_COMMON_DATA_FAILURE(state){
        return state.setIn(['commonDataLoading'],false)
      },
      GET_CATEGORY_LIST_SUCCESS(state, action) {
        return state.setIn(["categoryData"], action.data);
      },
      GET_TAG_LIST_SUCCESS(state, action) {
        return state.setIn(["tagData"], action.data);
      },
      GET_TOP_UP_CONTENT_REQUEST(state, action) {
        return state.setIn(["topUp", "loading"], true);
      },
      GET_TOP_UP_CONTENT_SUCCESS(state, action) {
        return state.setIn(["topUp", "loading"], false).setIn(['topUpData'],action.data);
      },
      GET_TOP_UP_CONTENT_FAILURE(state, action) {
        return state.setIn(["topUp", "loading"], false);
      },
      GET_COMMON_FAQ_GENERAL_REQUEST(state){
        return state.setIn(['faq','loading'],true)
      },
      GET_COMMON_FAQ_GENERAL_SUCCESS(state,action){
        return state.setIn(['faq','loading'],false).setIn(['faq','data'],action.data)
      },
      GET_COMMON_FAQ_GENERAL_FAILURE(state){
        return state.setIn(['faq','loading'],false)
      }
    },
    effects: {
      GET_COMMON_DATA_REQUEST: [watcherGetCommonDataRequest],
      GET_CATEGORY_LIST_REQUEST: [watcherGetCategoryListRequest],
      GET_TAG_LIST_REQUEST: [watcherGetTagListRequest],
      GET_TOP_UP_CONTENT_REQUEST: [watcherGetTopUpContentRequest],
      POST_LOGGING_INTEREST_REQUEST:[watcherPostLoggingInterestRequest],
      GET_COMMON_FAQ_GENERAL_REQUEST:[watcherGetCommonFaqGeneralRequest],
      POST_LOGGING_INTEREST_LOAN_REQUEST:[watcherPostLoggingInterestLoanRequest],

    }
  };
};
