import Immutable from "seamless-immutable";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import _ from "lodash";
import * as listingTransActions from "../actions/listingTrans";
import * as searchActions from "../actions/search";
import * as orderActions from "../actions/order";
import {getTransactionCommonFailure, getTransactionCommonSuccess} from "../actions/listingTrans";
import * as agentAction from "../actions/agent";

export default api => {

    function* watcherPostTransactionSearchDataRequest({ postData, type }, effects) {
        const { call, put } = effects;
        try {
            const response = yield call(api.postTransactionSearchData, postData);
            yield put(listingTransActions.postTransactionSearchDataSuccess(response.data));
            // console.log("returnData", response.data)

        } catch (error) {
            yield call(
                httpErrorHelpers,
                error,
                listingTransActions.postTransactionSearchDataFailure,
                effects
            );
        }
    }
// For GET Transaction Listing Data
    function* watcherGetTransactionDataListingRequest({ page =1 ,data, category,filter }, effects) {
        const { call, put, select } = effects;
        try {
            const response = yield call(api.getTransactionDataListing, page,data, category,filter);
            const { items } = response.data;
            yield put(listingTransActions.getTransactionDataListingSuccess(page,response.data, category,data));
        } catch (error) {
            yield call(httpErrorHelpers, error, listingTransActions.getTransactionDataListingFailure, effects);
        }
    }
// For GET Transaction Common
    function* watcherGetTransactionCommonRequest({ payload }, effects) {
        const { call, put } = effects;
        try {
            const response = yield call(api.getTransactionCommonData);
            yield put(listingTransActions.getTransactionCommonSuccess(response.data));
        } catch (error) {
            yield call(
                httpErrorHelpers,
                error,
                listingTransActions.getTransactionCommonFailure,
                effects
            );
        }
    }

    const INITIAL_STATE = Immutable({
        listingTrans: null
    });

    return {
        namespace: "listingTrans",
        state: INITIAL_STATE,
        reducers: {
            POST_TRANSACTION_SEARCH_DATA_REQUEST(state, action) {
                return state
                    .setIn(["transactionSearchData", "loading"], true)
            },
            POST_TRANSACTION_SEARCH_DATA_SUCCESS(state, action) {
                return state
                    .setIn(["transactionSearchData", "loading"], false)
                    .setIn(["transactionSearchData", "items"], action.data);
            },
            POST_TRANSACTION_SEARCH_DATA_FAILURE(state, action) {
                return state
                    .setIn(["transactionSearchData", "loading"], false)
            },
            // For GET Transaction Listing Data
            GET_TRANSACTION_DATA_LISTING_REQUEST(state) {
                return state.setIn(["transactionDataListing", "loading"], true);
            },
            GET_TRANSACTION_DATA_LISTING_SUCCESS(state, action) {
                const stateData = _.get(action,['slug','state'],"")
                const cityData = _.get(action,['slug','city'],"")
                const schemeData = _.get(action,['slug','scheme'],"")
                const categoryData = _.get(action,['slug','type'],"")
                const slugName = `${!_.isEmpty(categoryData)?`${categoryData}`:''}${!_.isEmpty(stateData)?`-${stateData}`:''}${!_.isEmpty(cityData)?`-${cityData}`:''}${!_.isEmpty(schemeData)?`-${schemeData}`:''}`
                return state
                    .setIn(["transactionDataListing", "loading"], false)
                    .setIn(["transactionDataListing", "items", slugName,action.page], action.data);
            },
            GET_TRANSACTION_DATA_LISTING_FAILURE(state) {
                return state.setIn(["transactionDataListing", "loading"], false);
            },
            // For GET Transaction Common Data
            GET_TRANSACTION_COMMON_REQUEST(state) {
                return state.setIn(["transactionCommonDataListing", "loading"], true);
            },
            GET_TRANSACTION_COMMON_SUCCESS(state, action) {
                return state
                    .setIn(["transactionCommonDataListing", "loading"], false)
                    .setIn(["transactionCommonDataListing", "items"], action.data);
            },
            GET_TRANSACTION_COMMON_FAILURE(state) {
                return state.setIn(["transactionCommonDataListing", "loading"], false);
            },
        },
        effects: {
            POST_TRANSACTION_SEARCH_DATA_REQUEST: [watcherPostTransactionSearchDataRequest],
            GET_TRANSACTION_DATA_LISTING_REQUEST: [watcherGetTransactionDataListingRequest],
            GET_TRANSACTION_COMMON_REQUEST: [watcherGetTransactionCommonRequest]
        }
    };
};