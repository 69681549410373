import Immutable from "seamless-immutable";
import Toast from "../utils/Toast";
import _ from "lodash";
import * as movementActions from "../actions/movement";
import Constants from "../constants";
import { delay } from "dva/saga";

export default () => {
  function* watcherMovementListRequest({ id }, effects) {
    const { call, put } = effects;

    try {
      // yield put(addressActions.getAddressListSuccess(data));
    } catch (error) {}
  }

  function* watcherNewMovementRequest({ data }, effects) {
    const { put, call } = effects;

    try {
      let formattedData = calculateTotalMarginAndMarketValue(data);
      yield call(delay, 1000);

      yield put(movementActions.addNewMovementSuccess(formattedData));
    } catch (error) {}
  }

  function calculateTotalMarginAndMarketValue(data) {
    const { actionType, cap_price, qty, price, mof } = data;
    let formattedData = data;

    if (actionType !== Constants.CASH_IN || actionType !== Constants.CASH_OUT) {
      let marginValue = 0;
      if (cap_price && cap_price !== 0) {
        if (cap_price < price) {
          marginValue = qty * cap_price;
        } else {
          marginValue = qty * price * mof;
        }
      } else {
        marginValue = qty * price * mof;
      }

      formattedData = {
        ...data,
        marginValue: marginValue,
        marketValue: qty * price
      };
    }

    return formattedData;
  }

  function* watcherUpdateMovementRequest({ data, index }, effects) {
    const { put, call } = effects;

    try {
      let formattedData = calculateTotalMarginAndMarketValue(data);

      yield call(delay, 1000);

      yield put(movementActions.putUpdateMovementSuccess(formattedData, index));

      Toast.success("Successfully updated action");
    } catch (error) {}
  }

  function* watcherDeleteMovementRequest({ id }, effects) {
    const { call, put } = effects;

    try {
      yield put(movementActions.deleteMovementSuccess(id));

      Toast.success("Successfully delete an action");
    } catch (error) {}
  }

  const INITIAL_STATE = Immutable({
    list: [],
    isSuccessUpdated: null
  });

  return {
    namespace: "movement",
    state: INITIAL_STATE,
    reducers: {
      ADD_NEW_MOVEMENT_REQUEST(state) {
        return state.setIn(["isSuccessUpdated", false]);
      },
      ADD_NEW_MOVEMENT_SUCCESS(state, action) {
        return state
          .setIn(["list"], !_.isEmpty(state.list) ? state.list.concat(action.data) : [action.data])
          .setIn(["isSuccessUpdated"], true);
      },
      ADD_NEW_MOVEMENT_FAILURE(state, action) {
        return state.setIn(["isSuccessUpdated", false]);
      },
      DELETE_MOVEMENT_REQUEST(state, action) {
        return state.merge({ messages: null });
      },
      DELETE_MOVEMENT_SUCCESS(state, action) {
        let listData = Object.assign([], state.list);
        const updatedData = listData.filter((element, index) => index !== action.id);
        return state.setIn(["list"], updatedData);
      },
      DELETE_MOVEMENT_FAILURE(state, action) {
        return state.merge({ messages: action.messages, isSuccessUpdated: false });
      },
      "PUT_UPDATE_MOVEMENT_REQUEST/@@start"(state) {
        return state.merge({ messages: null, isSuccessUpdated: false });
      },
      PUT_UPDATE_MOVEMENT_SUCCESS(state, action) {
        let updatedData = Object.assign([], state.list);
        updatedData[action.index] = action.data;
        return state.setIn(["isSuccessUpdated"], true).setIn(["list"], updatedData);
      },
      PUT_UPDATE_MOVEMENT_FAILURE(state, action) {
        return state.merge({ messages: action.messages, isSuccessUpdated: false });
      },
      REHYDRATE_MOVEMENT_STATE_SUCCESS(state, action) {
        return state.setIn(["list"], action.data && action.data.list ? action.data.list : []);
      }
    },
    effects: {
      GET_ADDRESS_LIST_REQUEST: [watcherMovementListRequest],
      ADD_NEW_MOVEMENT_REQUEST: [watcherNewMovementRequest],
      DELETE_MOVEMENT_REQUEST: [watcherDeleteMovementRequest],
      PUT_UPDATE_MOVEMENT_REQUEST: [watcherUpdateMovementRequest]
    }
  };
};
