import { createTheme } from "@mui/material/styles";
import {breakpoints} from "./utils/breakpoints";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#3462F4"
    },
    primaryHover: {
      main: "#A56F33"
    },
    secondary: {
      main: "#D3D3D3"
    },
    tertiary: {
      main: "#2c2c2c"
    },
    error: {
      main: "#D93F2A"
    },
    success: {
      main: "#0E952D"
    },
    background: {
      default: "#fff",
      dark: "#0C132D",
      grey: "#f3f5f7",
      light: "#f2f2f2"
    },
    text: {
      title: "#00AFF7",
      primary: "#2c2c2c",
      secondary: "#757575"
    },
    divider: "#BDBDBD",
    like: {
      main: "#3b5998"
    },
    link: {
      main: "#6666FF"
    },
    border: {
      main: "#b69929"
    }
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    h1: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 30
    },
    h2: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 25
    },
    h3: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 20
    },
    h4: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 16
    }
  },
  overrides: {
    MuiInput: {
      root: {
        fontFamily: "'Poppins', sans-serif",
        backgroundColor: "white"
      },
      underline: {
        "&:after": {
          borderBottom: 0
        },
        "&$focused:after": {
          borderBottom: 0
        },
        "&$error:after": {
          borderBottom: 0
        },
        "&:before": {
          borderBottom: "0px !important"
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: 0
        },
        "&$disabled:before": {
          borderBottom: 0
        }
      }
    },
    MuiButton: {
      root: {
        fontFamily: "'Poppins', sans-serif"
      }
    },
    MuiButtonBase: {
      root: {
        fontFamily: "'Poppins', sans-serif"
      },
      primary: {
        main: "#3462F4"
      },
      primaryHover: {
        main: "#A56F33"
      },
      secondary: {
        main: "#D3D3D3"
      },
      tertiary: {
        main: "#2c2c2c"
      },
      error: {
        main: "#D93F2A"
      },
      success: {
        main: "#0E952D"
      },
    },
    MuiTypography:{
      body1: {
        fontFamily: "'Poppins', sans-serif"
      }
    },

  },
  props:{
    MuiAccordion:{
        background:"#2c2c2c",
        color:"white"

    }
  },
  body: {
    fontFamily: "'Poppins', sans-serif !important"
  },
  // breakpoints: [`${breakpoints.sm}px`, `${breakpoints.md}px`, `${breakpoints.lg}px`, `${breakpoints.xl}px`],
  // xs : `${breakpoints.sm}px`

});

export default theme;
