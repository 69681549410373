export const postPasswordForgetRequest = postData => ({
  type: "password/POST_PASSWORD_FORGET_REQUEST",
  postData
});

export const postPasswordForgetSuccess = data => ({
  type: "POST_PASSWORD_FORGET_SUCCESS",
  data
});

export const postPasswordForgetFailure = message => ({
  type: "POST_PASSWORD_FORGET_FAILURE",
  message
});
export const postPasswordResetRequest = postData => ({
  type: "password/POST_PASSWORD_RESET_REQUEST",
  postData
});

export const postPasswordResetSuccess = data => ({
  type: "POST_PASSWORD_RESET_SUCCESS",
  data
});

export const postPasswordResetFailure = message => ({
  type: "POST_PASSWORD_RESET_FAILURE",
  message
});
