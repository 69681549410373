import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as profileActions from "../actions/profile";
import _ from "lodash";
import Toast from "../utils/Toast";
import * as imageActions from "../actions/image";
import * as authActions from "../actions/auth";
import * as profileSelectors from "../selectors/profile";
import * as agentAction from "../actions/agent";
import * as orderActions from "../actions/order";
import {
  getAgentListSaleDetailFailure,
  getAgentListSaleDetailSuccess,
  getReferralCodeFailure,
  getReferralCodeSuccess
} from "../actions/profile";

export default api => {
  function* watcherGetMeDataRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getMeData);
      yield put(profileActions.getMeDataSuccess(response.data));
      const agent = profileSelectors.getProfileAgent(response.data);
      if (!_.isEmpty(agent)) {
        const agentToken = profileSelectors.getAgentAccessToken(_.first(agent));
        yield put(authActions.updateAgentAccessToken(agentToken));
        yield put(agentAction.getAgentCommonDataRequest());
      }
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.getMeDataFailure, effects);
    }
  }

  function* watcherPutChangePasswordRequest({ putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putChangePassword, putData);

      const { token } = response.data;

      const messages = _.get(response, "data.messages", null);

      if (!_.isEmpty(messages)) {
        const { text, type } = messages[0];
        type === "error" ? Toast.error(text) : Toast.success(text);
      }

      yield put(profileActions.putChangePasswordSuccess());

      yield put(authActions.updateAccessToken(token));
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.putChangePasswordFailure, effects);
    }
  }

  function* watcherPutProfileAvatarRequest({ putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putProfileAvatar, putData);
      const { profile } = response.data;
      const messages = _.get(response, "data.messages", null);

      if (!_.isEmpty(messages)) {
        const { text, type } = messages[0];
        type === "error" ? Toast.error(text) : Toast.success(text);
      }

      yield put(profileActions.putProfileAvatarSuccess(profile));
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.putProfileAvatarFailure, effects);
    }
  }

  function* watcherPutProfileBasicRequest({ putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putProfileBasic, putData);
      const messages = _.get(response, "data.messages", null);

      const { profile } = response.data;

      if (!_.isEmpty(messages)) {
        const { text, type } = messages[0];
        type === "error" ? Toast.error(text) : Toast.success(text);
      }

      yield put(profileActions.putProfileDataSuccess(profile));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.putProfileDataFailure, effects);
    }
  }

  function* watcherPostBindPhoneNumberRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postMeBindPhone, postData);
      const messages = _.get(response, "data.messages", null);

      const { profile } = response.data;

      if (!_.isEmpty(messages)) {
        const { text, type } = messages[0];
        type === "error" ? Toast.error(text) : Toast.success(text);
      }

      yield put(profileActions.postBindPhoneNumberSuccess(profile));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.postBindPhoneNumberFailure, effects);
    }
  }

  function* watcherGetAgentAccountRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAccountDetail, id);

      yield put(profileActions.getAgentAccountSuccess(id, response.data));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.postBindPhoneNumberFailure, effects);
    }
  }

  function* watcherGetAccountListAgentRequest({ page, keyword,stateValue }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAccountListAgent, page, keyword,stateValue);
      const { items, _meta } = response.data;

      yield put(profileActions.getAccountListAgentSuccess(page, items, _meta));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.getAccountListAgentFailure, effects);
    }
  }

  function* watcherGetAccountListDeveloperRequest({ page, keyword ,stateValue}, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAccountListDeveloper, page, keyword,stateValue);
      const { items, _meta } = response.data;

      yield put(profileActions.getAccountListDeveloperSuccess(page, items, _meta));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.getAccountListDeveloperFailure, effects);
    }
  }

  function* watcherPostMeChangePhoneRequest({ postData}, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postMeChangePhone,postData);
      const { item } = response.data;
      const messages = _.get(response, "data.messages", null);

      if (!_.isEmpty(messages)) {
        const { text, type } = messages[0];
        type === "error" ? Toast.error(text) : Toast.success(text);
      }

      yield put(profileActions.postMeChangePhoneSuccess(item));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.postMeChangePhoneFailure, effects);
    }
  }

  function* watcherPostMeChangeEmailRequest({ postData}, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postMeChangeEmail,postData);
      const { item } = response.data;
      const messages = _.get(response, "data.messages", null);

      if (!_.isEmpty(messages)) {
        const { text, type } = messages[0];
        type === "error" ? Toast.error(text) : Toast.success(text);
      }

      yield put(profileActions.postMeChangeEmailSuccess(item));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.postMeChangeEmailFailure, effects);
    }
  }

  function* watcherGetReferralCodeRequest({ page, listingStatus, createdAt }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getReferralCode,
          page,
          listingStatus,
          createdAt
      );

      const { items, _meta } = response.data;
      yield put(profileActions.getReferralCodeSuccess(items, _meta, page));
    } catch (error) {
      yield call(
          httpErrorHelpers,
          error,
          profileActions.getReferralCodeFailure,
          effects
      );
    }
  }

  function* watcherGetAgentListSaleDetailRequest({ page, slug}, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAgentListingSaleDetail, page, slug);
      const { items, _meta } = response.data;
      yield put(profileActions.getAgentListSaleDetailSuccess(page, items, _meta));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.getAgentListSaleDetailFailure, effects);
    }
  }
  function* watcherGetAgentListRentDetailRequest({ page, slug}, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getAgentListingRentDetail, page, slug);
      const { items, _meta } = response.data;
      yield put(profileActions.getAgentListRentDetailSuccess(page, items, _meta));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.getAgentListRentDetailFailure, effects);
    }
  }

  function* watcherGetDeveloperListDetailRequest({ page, slug}, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getDeveloperListingDetail, page, slug);
      const { items, _meta } = response.data;
      yield put(profileActions.getDeveloperListDetailSuccess(page, items, _meta));
      // yield put(profileActions.getMeDataRequestInLocal());
    } catch (error) {
      yield call(httpErrorHelpers, error, profileActions.getDeveloperListDetailFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    profile: null
  });

  return {
    namespace: "profile",
    state: INITIAL_STATE,
    reducers: {
      UPDATE_PROFILE_DETAIL(state, action) {
        return state.setIn(["profile"], action.data);
      },
      GET_ME_DATA_SUCCESS(state, action) {
        return state.setIn(["profile"], action.data);
      },
      PUT_CHANGE_PASSWORD_REQUEST(state) {
        return state.setIn(["changePassword", "loading"], true).setIn(["changePassword", "status"], false);
      },
      PUT_CHANGE_PASSWORD_SUCCESS(state) {
        return state.setIn(["changePassword", "loading"], false).setIn(["changePassword", "status"], true);
      },
      PUT_CHANGE_PASSWORD_FAILURE(state) {
        return state.setIn(["changePassword", "loading"], false).setIn(["changePassword", "loading"], false);
      },
      PUT_PROFILE_AVATAR_REQUEST(state) {
        return state.setIn(["avatarLoading"], true);
      },
      PUT_PROFILE_AVATAR_SUCCESS(state, action) {
        const { profile } = state;

        let newProfile = {
          ...profile,
          ...action.data
        };

        return state.setIn(["avatarLoading"], false).setIn(["profile"], newProfile);
      },
      PUT_PROFILE_AVATAR_FAILURE(state) {
        return state.setIn(["avatarLoading"], false);
      },
      PUT_PROFILE_DATA_REQUEST(state) {
        return state.setIn(["updateProfileLoading"], true);
      },
      PUT_PROFILE_DATA_SUCCESS(state, action) {
        return state.setIn(["updateProfileLoading"], false).setIn(["profile"], action.data);
      },
      PUT_PROFILE_DATA_FAILURE(state) {
        return state.setIn(["updateProfileLoading"], false);
      },
      POST_BIND_PHONE_NUMBER_REQUEST(state) {
        return state.setIn(["bindPhoneNumber", "loading"], true).setIn(["bindPhoneNumber", "status"], false);
      },
      POST_BIND_PHONE_NUMBER_SUCCESS(state, action) {
        return state
          .setIn(["bindPhoneNumber", "loading"], false)
          .setIn(["bindPhoneNumber", "status"], true)
          .setIn(["bindPhoneNumber", "data"], action.data);
      },
      POST_BIND_PHONE_NUMBER_FAILURE(state) {
        return state.setIn(["bindPhoneNumber", "loading"], false).setIn(["bindPhoneNumber", "status"], false);
      },
      GET_AGENT_ACCOUNT_REQUEST(state) {
        return state.setIn(["agentProfile", "loading"], true);
      },
      GET_AGENT_ACCOUNT_SUCCESS(state, action) {
        return state
          .setIn(["agentProfile", "loading"], false)
          .setIn(["agentProfile", action.id], action.data);
      },
      GET_AGENT_ACCOUNT_FAILURE(state) {
        return state.setIn(["agentProfile", "loading"], false);
      },
      GET_ACCOUNT_LIST_AGENT_REQUEST(state) {
        return state.setIn(["accountListAgent", "loading"], true);
      },
      GET_ACCOUNT_LIST_AGENT_SUCCESS(state, action) {
        return state
          .setIn(["accountListAgent", "loading"], false)
          .setIn(["accountListAgent", "items", action.page], action.data)
          .setIn(["accountListAgent", "pagination"], action.pagination);
      },
      GET_ACCOUNT_LIST_AGENT_FAILURE(state) {
        return state.setIn(["accountListAgent", "loading"], false);
      },
      GET_ACCOUNT_LIST_DEVELOPER_REQUEST(state) {
        return state.setIn(["accountListDeveloper", "loading"], true);
      },
      GET_ACCOUNT_LIST_DEVELOPER_SUCCESS(state, action) {
        return state
          .setIn(["accountListDeveloper", "loading"], false)
          .setIn(["accountListDeveloper", "items", action.page], action.data)
          .setIn(["accountListDeveloper", "pagination"], action.pagination);
      },
      GET_ACCOUNT_LIST_DEVELOPER_FAILURE(state) {
        return state.setIn(["accountListDeveloper", "loading"], false);
      },
      POST_ME_CHANGE_PHONE_REQUEST(state){
        return state.setIn(['changePhone','status'],false).setIn(['changePhone','loading'],true)
      },
      POST_ME_CHANGE_PHONE_SUCCESS(state){
        return state.setIn(['changePhone','status'],true).setIn(['changePhone','loading'],false)
      },
      POST_ME_CHANGE_PHONE_FAILURE(state){
        return state.setIn(['changePhone','status'],false).setIn(['changePhone','loading'],false)
      },
      POST_ME_CHANGE_EMAIL_REQUEST(state){
        return state.setIn(['changeEmail','status'],false).setIn(['changeEmail','loading'],true)
      },
      POST_ME_CHANGE_EMAIL_SUCCESS(state){
        return state.setIn(['changeEmail','status'],true).setIn(['changeEmail','loading'],false)
      },
      POST_ME_CHANGE_EMAIL_FAILURE(state){
        return state.setIn(['changeEmail','status'],false).setIn(['changeEmail','loading'],false)
      },
      // Get referral code
      GET_REFERRAL_CODE_REQUEST(state) {
        return state.setIn(["referralCodeList", "loading"], true);
      },
      GET_REFERRAL_CODE_SUCCESS(state, action) {
        return state
            .setIn(["referralCodeList", "loading"], false)
            .setIn(["referralCodeList", "items", action.page], action.data);
      },
      GET_REFERRAL_CODE_FAILURE(state) {
        return state.setIn(["referralCodeList", "loading"], false);
      },

      GET_AGENT_LIST_SALE_DETAIL_REQUEST(state) {
        return state.setIn(["agentListSale", "loading"], true);
      },
      GET_AGENT_LIST_SALE_DETAIL_SUCCESS(state, action) {
        return state
            .setIn(["agentListSale", "loading"], false)
            .setIn(["agentListSale", "items", action.page], action.data)
            .setIn(["agentListSale", "pagination"], action.pagination);
      },
      GET_AGENT_LIST_SALE_DETAIL_FAILURE(state) {
        return state.setIn(["agentListSale", "loading"], false);
      },

      GET_AGENT_LIST_RENT_DETAIL_REQUEST(state) {
        return state.setIn(["agentListRent", "loading"], true);
      },
      GET_AGENT_LIST_RENT_DETAIL_SUCCESS(state, action) {
        return state
            .setIn(["agentListRent", "loading"], false)
            .setIn(["agentListRent", "items", action.page], action.data)
            .setIn(["agentListRent", "pagination"], action.pagination);
      },
      GET_AGENT_LIST_RENT_DETAIL_FAILURE(state) {
        return state.setIn(["agentListRent", "loading"], false);
      },

      GET_DEVELOPER_LIST_DETAIL_REQUEST(state) {
        return state.setIn(["developerList", "loading"], true);
      },
      GET_DEVELOPER_LIST_DETAIL_SUCCESS(state, action) {
        return state
            .setIn(["developerList", "loading"], false)
            .setIn(["developerList", "items", action.page], action.data)
            .setIn(["developerList", "pagination"], action.pagination);
      },
      GET_DEVELOPER_LIST_DETAIL_FAILURE(state) {
        return state.setIn(["developerList", "loading"], false);
      },
    },
    effects: {
      GET_ME_DATA_REQUEST: [watcherGetMeDataRequest],
      PUT_CHANGE_PASSWORD_REQUEST: [watcherPutChangePasswordRequest],
      PUT_PROFILE_AVATAR_REQUEST: [watcherPutProfileAvatarRequest],
      PUT_PROFILE_DATA_REQUEST: [watcherPutProfileBasicRequest],
      POST_BIND_PHONE_NUMBER_REQUEST: [watcherPostBindPhoneNumberRequest],
      GET_AGENT_ACCOUNT_REQUEST: [watcherGetAgentAccountRequest],
      GET_ACCOUNT_LIST_AGENT_REQUEST: [watcherGetAccountListAgentRequest],
      GET_ACCOUNT_LIST_DEVELOPER_REQUEST: [watcherGetAccountListDeveloperRequest],
      POST_ME_CHANGE_EMAIL_REQUEST:[watcherPostMeChangeEmailRequest],
      POST_ME_CHANGE_PHONE_REQUEST:[watcherPostMeChangePhoneRequest],
      GET_REFERRAL_CODE_REQUEST: [watcherGetReferralCodeRequest],
      GET_AGENT_LIST_SALE_DETAIL_REQUEST: [watcherGetAgentListSaleDetailRequest],
      GET_AGENT_LIST_RENT_DETAIL_REQUEST: [watcherGetAgentListRentDetailRequest],
      GET_DEVELOPER_LIST_DETAIL_REQUEST: [watcherGetDeveloperListDetailRequest]

    }
  };
};
