import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import CustomFont from "../CustomFont";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Hidden from "@material-ui/core/Hidden";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Color as Colors } from "../../src/utils/Color";
const ariaLabel = { "aria-label": "description" };
import _ from "lodash";
import FormHelperText from "@mui/material/FormHelperText";
import ReactLoading from "react-loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxWidth: "90%",
  bgcolor: "white",
  borderRadius: 10,
  boxShadow: 24,
  p: 4
};

const StyledInputContainer = styled(Input)`
  width: 100%;
  padding: 10px 15px;
  margin-top: 1.5rem;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledInputLabelContainer = styled(InputLabel)`
  padding: 0px 15px;
  color: #2c2c2c;
  &.MuiInputLabel-root {
    padding: 0px 15px;
    font-size: 1rem;
    color: #2c2c2c;
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

class VerifyPhoneNumberModal extends Component {
  handleClose = (e) => {
    const { handleClose } = this.props;
    handleClose && handleClose();
  };

  onChangePhoneNumberText = e => {
    const { onChangePhoneNumberText } = this.props;
    e.preventDefault();

    onChangePhoneNumberText && onChangePhoneNumberText(e.target.value);
  };

  onChangeOTPText = e => {
    const { onChangeOTPText } = this.props;
    e.preventDefault();

    onChangeOTPText && onChangeOTPText(e.target.value);
  };

  onClickVerifyPhoneNumber = () => {
    const { onClickVerifyPhoneNumber } = this.props;

    onClickVerifyPhoneNumber && onClickVerifyPhoneNumber();
  };

  onClickResend= () => {
    const {onClickResend} = this.props
    onClickResend && onClickResend()
  }

  onClickSubmitSignUp= () => {
    const {onClickSubmitSignUp} = this.props

    onClickSubmitSignUp && onClickSubmitSignUp()
  }

  render() {
    const {
      visible,
      phoneNumber,
      otp,
      errorPhoneReason,
      verifyPhoneLoading,
      isResend = false,
      expiryTime,
      signUpLoading,
        isBindPhone=false
    } = this.props;
    return (
      <Modal
        open={visible}
        onClose={this.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!isBindPhone  ? <CloseButtonContainer>
            <IconButton aria-label="delete" onClick={this.handleClose}>
              <CancelOutlinedIcon fontSize="inherit" style={{ color: "#2c2c2c", fontSize: "40px" }} />
            </IconButton>
          </CloseButtonContainer>:false}
          <Hidden smDown>
            <CustomFont
              size="xxlarge"
              styles={{ fontWeight: "600", color: "#2c2c2c", textAlign: "center", paddingHorizontal: 20 }}
            >
              Verify Your Phone Number
            </CustomFont>
            <CustomFont size="large" styles={{ color: "#2c2c2c", textAlign: "center", marginTop: 20 }}>
              Verifying your phone number is a security measure that confirms your identity and protects your
              account from unauthorized access.
            </CustomFont>
          </Hidden>
          <Hidden mdUp>
            <CustomFont size="xlarge" styles={{ fontWeight: "600", color: "#2c2c2c", textAlign: "center" }}>
              Verify Your Phone Number
            </CustomFont>
            <CustomFont styles={{ color: "#2c2c2c", textAlign: "center", marginTop: 20 }}>
              Verifying your phone number is a security measure that confirms your identity and protects your
              account from unauthorized access.
            </CustomFont>
          </Hidden>
          <StyledFormControl variant="standard" style={{ marginTop: 40 }}>
            <StyledInputLabelContainer htmlFor="standard-phone-number" shrink={true}>
              Phone Number
            </StyledInputLabelContainer>
            <StyledInputContainer
              placeholder="Enter Phone Number"
              type={"number"}
              inputProps={ariaLabel}
              value={phoneNumber}
              onChange={this.onChangePhoneNumberText}
              style={{ marginTop: 15 }}
              disabled={expiryTime && expiryTime>0}
              endAdornment={
                <InputAdornment position="end">
                  {isResend ? (
                    <Button
                      style={{
                        color:  "white",
                        width: "100%",
                        fontWeight: "600",
                        fontSize: "1rem",
                        borderRadius: 0,
                        textTransform: "none",
                        boxShadow: "none",
                        backgroundColor: expiryTime > 0 ? Colors.borderColor:Colors.primaryColor,
                      }}
                      disabled={ expiryTime > 0}
                      variant="contained"
                      onClick={this.onClickResend}
                    >
                      <CustomFont
                        size="xsmall"
                        styles={{
                          color: "white",
                          fontWeight: "600"
                        }}
                      >
                        {expiryTime > 0 ? `Resend In ${expiryTime}s` : "Resend"}
                      </CustomFont>
                    </Button>
                  ) : (
                    <Button
                      style={{
                        color: _.isEmpty(phoneNumber) || verifyPhoneLoading ? Colors.borderColor : "#2c2c2c",
                        width: "100%",
                        fontWeight: "600",
                        fontSize: "1.1rem",
                        borderRadius: 0,
                        textTransform: "none",
                        boxShadow: "none",
                        backgroundColor: "white",
                        border: `1px solid ${
                          _.isEmpty(phoneNumber) || verifyPhoneLoading ? Colors.borderColor : "#2c2c2c"
                        }`
                      }}
                      disabled={_.isEmpty(phoneNumber) || verifyPhoneLoading}
                      variant="contained"
                      onClick={this.onClickVerifyPhoneNumber}
                    >
                      <CustomFont
                        size="xsmall"
                        styles={{
                          color: _.isEmpty(phoneNumber) || verifyPhoneLoading ? Colors.borderColor : "#2c2c2c",
                          fontWeight: "600"
                        }}
                      >
                        Verify
                      </CustomFont>
                      {verifyPhoneLoading ? (
                        <div style={{ marginLeft: 5, marginTop: -15 }}>
                          <ReactLoading
                            type="spinningBubbles"
                            color={Colors.borderColor}
                            height={16}
                            width={16}
                          />
                        </div>
                      ) : (
                        false
                      )}
                    </Button>
                  )}
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <CustomFont>+60 |</CustomFont>
                </InputAdornment>
              }
            />
            {!_.isEmpty(errorPhoneReason) ? (
              <FormHelperText error id="my-helper-text">
                {errorPhoneReason}
              </FormHelperText>
            ) : (
              false
            )}
          </StyledFormControl>
          <StyledInputContainer
            placeholder="Enter Your OTP"
            type={"number"}
            inputProps={ariaLabel}
            value={otp}
            onChange={this.onChangeOTPText}
            style={{ marginTop: 25 }}
          />
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Button
              style={{
                fontWeight: "600",
                fontSize: "1.1rem",
                borderRadius: 4,
                textTransform: "none",
                boxShadow: "none",
                backgroundColor: _.isEmpty(otp) || signUpLoading ? Colors.borderColor:Colors.primaryColor,
                border: `1px solid ${_.isEmpty(otp)  || signUpLoading ? Colors.borderColor:Colors.primaryColor}`,
                marginTop: 30
              }}
              disabled={_.isEmpty(otp) || signUpLoading}
              variant="contained"
              onClick={this.onClickSubmitSignUp}
            >
              <CustomFont styles={{ color: "white", fontWeight: "600" }}>Submit</CustomFont>
              {signUpLoading ? (
                  <div style={{ marginLeft: 5, marginTop: -15 }}>
                    <ReactLoading
                        type="spinningBubbles"
                        color={Colors.borderColor}
                        height={16}
                        width={16}
                    />
                  </div>
              ) : (
                  false
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default withTheme(VerifyPhoneNumberModal);
