export const postVerificationRequest = postData => ({
    type:"verification/POST_VERIFICATION_REQUEST",
    postData
})

export const postVerificationSuccess = data => ({
    type:"POST_VERIFICATION_SUCCESS",
    data
})

export const postVerificationFailure = messages => ({
    type:"POST_VERIFICATION_FAILURE",
    messages
})
export const postVerificationRequestInRoot = postData => ({
    type:"verification/POST_VERIFICATION_REQUEST_IN_ROOT",
    postData
})

export const postVerificationSuccessInRoot = data => ({
    type:"POST_VERIFICATION_SUCCESS_IN_ROOT",
    data
})

export const postVerificationFailureInRoot = messages => ({
    type:"POST_VERIFICATION_FAILURE_IN_ROOT",
    messages
})

export const postVerificationChangeEmailRequest = postData => ({
    type:"verification/POST_VERIFICATION_CHANGE_EMAIL_REQUEST",
    postData
})

export const postVerificationChangeEmailSuccess = data => ({
    type:"POST_VERIFICATION_CHANGE_EMAIL_SUCCESS",
    data
})

export const postVerificationChangeEmailFailure = message => ({
    type:"POST_VERIFICATION_CHANGE_EMAIL_FAILURE",
    message
})

export const postVerificationChangePhoneRequest = postData =>({
    type:"verification/POST_VERIFICATION_CHANGE_PHONE_REQUEST",
    postData
})

export const postVerificationChangePhoneSuccess = data => ({
    type:"POST_VERIFICATION_CHANGE_PHONE_SUCCESS",
    data
})

export const postVerificationChangePhoneFailure = message => ({
    type:"POST_VERIFICATION_CHANGE_PHONE_FAILURE",
    message
})

