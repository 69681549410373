/**
 * This file created by HAUYANG on 27/09/2017.
 * Copyright 2017-present, rn-boilerplate.
 * All rights reserved.
 */

import apiInstance, { AUTH_TOKEN_HEADER, AUTH_AGENT_TOKEN_HEADER } from "./httpManager";
import _ from "lodash";
import Constant from "../../constants/index";

const version = "v1";
/**
 * To update api authorization header
 * @param token
 */
const setHeaderToken = token => {
  apiInstance.defaults.headers[AUTH_TOKEN_HEADER] = token;
};
const setHeaderAgentToken = token => {
  apiInstance.defaults.headers[AUTH_AGENT_TOKEN_HEADER] = token;
};

/**
 * To update language in api header
 * @param locale
 */
const setHeaderLanguage = locale => {
  apiInstance.defaults.headers["Accept-Language"] = locale;
};

/**
 * To get common data
 */
const getCommonData = () => apiInstance.get(`${version}/common`);

/**
 * to post phone verification
 */

const postPhoneVerification = data => apiInstance.post("/verification", data);

/**
 * to put phone verification
 */

const putPhoneVerification = data => apiInstance.put("/verification", data);

/**
 * to sign up
 */

const postSigUp = data => apiInstance.post("/sign-up", data);

/**
 * Get Category List
 */
const getCategoryList = () => apiInstance.get(`/category`);

/**
 * Get Tag List
 */
const getTagList = () => apiInstance.get(`/tag/list`);

/** Dashboard Start **/

/**
 * User List Action
 */
const getPortalUserList = queryParam => apiInstance.get(`/user/list${queryParam ? queryParam : ""}`);

const postPortalUserRole = (userId, role) => apiInstance.post(`/user/${userId}/role`, { role: role });

/**
 * Category List Action
 */
const getPortalCategoryList = queryParam => apiInstance.get(`/category/list${queryParam ? queryParam : ""}`);

const postPortalCategory = postData => apiInstance.post(`/category`, postData);

const putPortalCategory = (id, putData) => apiInstance.put(`category/${id}`, putData);

const deletePortalCategory = id => apiInstance.delete(`/category/${id}`);

/**
 * Blog List Action
 */
const getPortalBlogList = queryParam => apiInstance.get(`/blog/list${queryParam ? queryParam : ""}`);

const postPortalBlog = postData => apiInstance.post(`/blog`, postData);

const getBlogDetail = slug => apiInstance.get(`/blog/${slug}`);

const putBlogDetail = (id, data) => apiInstance.put(`/blog/${id}`, data);

const updateBlogStatus = (id, status) => apiInstance.post(`/blog/${id}/${status}`);

const deletePostBlogDetail = id => apiInstance.delete(`/blog/${id}`);

const getBlogList = (page, queryParam) =>
  apiInstance.get(`/blog?page=${page}${!_.isEmpty(queryParam) ? queryParam : ""}`);

/**
 * Common Upload Image
 */
const postImage = postData => apiInstance.post("/image", postData);

/** Dashboard End **/

const getPortalBlogDetail = id => apiInstance.get(`/blog/dashboard/${id}?expand=status_block`);

/**
 * To login account
 * @param phone
 * @param password
 */
const loginAccount = (phone, password) =>
  apiInstance.post(
    "/sign-in",
    { data: "token" },
    {
      auth: {
        username: phone,
        password: password
      }
    }
  );

/**
 * To get me data
 */
const getMeData = () => apiInstance.get(`${version}/me/profile`);

/**
 * Password forget request
 */
const postPasswordForget = postData => apiInstance.post(`/${version}/password/forget`, postData);

/**
 * Password reset verify
 *
 */
const postPasswordReset = postData => apiInstance.post(`/password/reset`, postData);

/**
 * Password change
 *
 */
const putChangePassword = putData => apiInstance.put(`/${version}/me/password`, putData);

const putProfileAvatar = putData => apiInstance.put(`${version}/me/avatar`, putData);

const putProfileBasic = putData => apiInstance.put(`${version}/me/profile`, putData);

const getUserData = id => apiInstance.get(`/user/${id}`);

// Referral Code
const getReferralCode = id => apiInstance.get(`${version}/referral/summary`);




//top up
const postTopUp = postData => apiInstance.post(`/point/top-up`, postData);

const getTopUpContent = () => apiInstance.get(`/page/top-up`);

const getTopUpRequestList = (page, startDate, endDate, status) => {
  const statusQuery = `${!_.isEmpty(status) ? `&status=${status}` : ""}`;
  const dateQuery = `${!_.isEmpty(startDate) ? `&start_date=${startDate}` : ""}`;
  const endDateQuery = `${!_.isEmpty(endDate) ? `&end_date=${endDate}` : ""}`;

  return apiInstance.get(`/point/request?page=${page}${statusQuery}${dateQuery}${endDateQuery}`);
};

const getTopUpTransactionList = (page, status, date) => {
  const dateQuery = `${!_.isEmpty(date) ? `&date=${date}` : ""}`;

  return apiInstance.get(`/point/transaction?page=${page}${dateQuery}`);
};

const getProductList = page => apiInstance.get(`/product?page=${page}`);

const getProductDetail = id => apiInstance.get(`product/${id}`);

const getOrderList = (page, startDate, endDate) => {
  const dateQuery = `${!_.isEmpty(startDate) ? `&start_date=${startDate}` : ""}`;
  const endDateQuery = `${!_.isEmpty(endDate) ? `&end_date=${endDate}` : ""}`;
  return apiInstance.get(`/order?page=${page}${dateQuery}${endDateQuery}`);
};

const postCreateOrder = postData => apiInstance.post(`/order`, postData);

const postAuthFirebase = postData => apiInstance.post(`${version}/auth/firebase`, postData);

const getPropertyList = page => apiInstance.get(`${version}/property?page=${page}`);

const getProjectList = page => apiInstance.get(`${version}/project?page=${page}`);

const getPropertyDetail = id => apiInstance.get(`${version}/property/${id}`);

const postSearchListing = (page, postData, queryParam, suggestion) => {
  const sortQuery = !_.isEmpty(queryParam) && queryParam !== "-1" ? `&sort=${queryParam}` : "";
  return apiInstance.post(`${version}/search/listing?page=${page}${sortQuery}&suggestion=${suggestion}`, postData);
};

const postSearchSuggestion = postData => apiInstance.post(`${version}/search/suggestion`, postData);

const getNewsList = page => apiInstance.get(`${version}/news?page=${page}`);

const getHomeData = () => apiInstance.get(`${version}/home`);

// agent
const postAgentAccount = postData => apiInstance.post("/agent/account", postData);

const postAgentAccountVerify = postData => apiInstance.post("/agent/account/verify", postData);

const postAgentSearchAgency = postData => apiInstance.post("/agent/search/agency", postData);

const postAgentSearchProject = postData => apiInstance.post(`/agent/search/project`, postData);

const postAgentListing = postData => apiInstance.post(`/agent/listing`, postData);

const postAgentListingv2 = (page, postData) => apiInstance.post(`/agent/listing/v2?page=${page}&expand=_metadata`, postData);

const putAgentListingData = (id, putData) => apiInstance.put(`/agent/listing/${id}`, putData);

const postAgentListingDataImage = (id, postData) => apiInstance.post(`/agent/listing/${id}/image`, postData);

const deleteAgentListingImageData = id => apiInstance.delete(`/agent/listing/image/${id}`);

const postAgentListingDataImageDefault = id => apiInstance.post(`/agent/listing/image/${id}`);


const getAgentListing = (page, status, propertyStatus, keyword, propertyType) => {
  // console.log("getList", keyword)
  let filterStatus = "";

  switch (status) {
    case Constant.PROPERTY_SITUATION_ACTIVE:
      filterStatus = "&isActive=true";
      break;
    case Constant.PROPERTY_SITUATION_INACTIVE:
      filterStatus = "&isActive=false";
      break;
  }
  return apiInstance.get(
    `/agent/listing?page=${page}${!_.isEmpty(filterStatus) ? filterStatus : ""}${
      !_.isEmpty(propertyStatus) && !_.isEqual(propertyStatus, Constant.PROPERTY_SITUATION_ALL)
        ? `&status=${propertyStatus}&expand=_metadata`
        : ""}${!_.isEmpty(keyword) ? `&keyword=${keyword}` : ""}`
  );
};

const getAgentListingDetail = id => apiInstance.get(`/agent/listing/${id}`);

const getAgentCommonData = () => apiInstance.get(`/agent/common`);
const getAgentCommonState = () => apiInstance.get(`/agent/common/state`);
const getAgentCommonCity = () => apiInstance.get(`/agent/common/city`);

const postAgentListingDataPublish = id => apiInstance.post(`/agent/listing/${id}/publish`);

const postAgentSearchState = postData => apiInstance.post(`agent/search/state`, postData);

const postAgentSearchCity = postData => apiInstance.post(`agent/search/city`, postData);

const postAgentProjectCreate = postData => apiInstance.post(`agent/project`, postData);

const postAuthVerify = postData => apiInstance.post(`/${version}/auth/verify`, postData);

const postVerification = postData => apiInstance.post(`/${version}/verification`, postData);

const postMeBindPhone = postData => apiInstance.post(`/${version}/me/bind/phone`, postData);

const postAuthSignUp = postData => apiInstance.post(`/${version}/auth/sign-up`, postData);

const postAuthSignIn = postData => apiInstance.post(`/${version}/auth/sign-in`, postData);

const postLoggingInterest = postData => apiInstance.post(`/${version}/logging/interest`, postData);
// Loan Calculation Form
const postLoggingInterestLoan = postData => apiInstance.post(`/${version}/logging/interest-loan`, postData);

const getAccountDetail = id => apiInstance.get(`/${version}/${id}`);

const getAgentLead = (page, listingStatus, createdAt) => {
  const sortCreateTime = !_.isEmpty(createdAt) && createdAt !== "-1" ? `&createTime=${createdAt}` : "";
  return apiInstance.get(`/agent/lead?page=${page}&status=${listingStatus}${sortCreateTime}`);
};

const putAgentLeadDetail = (id, data) => apiInstance.put(`/agent/lead/${id}`, data);

//Userpropery


//post
const getPostListing = (page, params) => apiInstance.get(`/${version}/post?page=${page}${params}`);

const getPostDetail = id => apiInstance.get(`/${version}/post/${id}`);
const getAccountListAgent = (page, keyword, stateValue) => {
  return apiInstance.get(
    `/${version}/account/list/agent?page=${page}${!_.isEmpty(keyword) ? `&name=${keyword}` : ""}${
      !_.isEmpty(stateValue) ? `&state=${stateValue}` : ""
    }`
  );
};

const getAccountListDeveloper = (page, keyword, stateValue) => {
  return apiInstance.get(
    `/${version}/account/list/developer?page=${page}${!_.isEmpty(keyword) ? `&name=${keyword}` : ""}${
      !_.isEmpty(stateValue) ? `&state=${stateValue}` : ""
    }`
  );
};

const putPropertyDetailWishlist = id => apiInstance.put(`${version}/property/${id}/wishlist`);
const putProjectDetailWishlist = id => apiInstance.put(`${version}/project/${id}/wishlist`);
const getWishlistListing = page => apiInstance.get(`${version}/wishlist/listing?page=${page}`);

const postMeChangeEmail = postData => apiInstance.post(`${version}/me/change/email`,postData)
const postMeChangePhone = postData => apiInstance.post(`${version}/me/change/phone`,postData)

const getFaqGeneral = () => apiInstance.get(`${version}/faq/general`)
const putAgentListingDetailImage = (id,putData) => apiInstance.put(`/agent/listing/${id}/image`,putData)

//Property
const postUserPropertyProjectCreate = postData => apiInstance.post(`${version}/property/project`, postData);
const postUserPropertyListing = (postData) => apiInstance.post(`${version}/property`,postData)
const putUserPropertyListingData = (id, putData) => apiInstance.put(`${version}/property/${id}`, putData);
const postUserPropertySearchProject = postData => apiInstance.post(`${version}/property/search/project`, postData);
const postUserPropertySearchState = postData => apiInstance.post(`${version}/property/search/state`, postData);
const postUserPropertySearchCity = postData => apiInstance.post(`${version}/property/search/city`, postData);

const getUserPropertyListingDetail = id => apiInstance.get(`${version}/property/me/${id}`);
const putUserPropertyListingDetailImage = (id,putData) => apiInstance.put(`${version}/property/${id}/image`,putData)
const postUserPropertyListingDataImageDefault = id => apiInstance.post(`${version}/property/image/${id}`);
const deleteUserPropertyListingImageData = id => apiInstance.delete(`${version}/property/image/${id}`);
const postUserPropertyListingDataImage = (id, postData) => apiInstance.post(`${version}/property/${id}/image`, postData);
const putUserPropertyListingPrice = (id,putData) => apiInstance.put(`${version}/property/${id}/price`, putData)
const putUserPropertyStatus = (id,putData) => apiInstance.put(`${version}/property/${id}/status`, putData)
const getUserPropertyMetaDetail = id => apiInstance.get(`${version}/me/profile/`);
const getUserPropertyDataFilter = (page,statusFilter) => apiInstance.get(`${version}/property/me?page=${page}&status=${statusFilter}`);

const putPropertyDetailStatus = (id,putData) => apiInstance.put(`${version}/property/${id}/status`,putData)

const getUserPropertyListing = (page) => apiInstance.get(`${version}/property/me?page=${page}`);
const getPropertyMeStatistic = () => apiInstance.get(`${version}/property/me/statistic`);

// Subscription
const postAgentSubscriptionPackage = id => apiInstance.post(`/agent/subscription/${id}`);
const postOrderPaymentDetail = (id, postData) => apiInstance.post(`${version}/order/${id}/payment`, postData);
const postOrderFreePaymentDetail = (id, postData) => apiInstance.post(`${version}/order/${id}/payment`, postData);
const postOrderPaymentReceiveDetail = (postData) => apiInstance.post(`${version}/order/payment-receive`, postData);
const getOrderHistoryList = () => apiInstance.get(`${version}/order`);

// Napic
const postTransactionSearchData = (postData, type) => apiInstance.post(`${version}/listing-transaction/search`, postData);
const getTransactionCommonData = () => apiInstance.get(`${version}/listing-transaction/common`);

const getTransactionDataListing = (page,data, typeC,filter) => {

  const { state, city, scheme, type } = data;
  const stateQuery = !_.isEmpty(state) ? `/${state}` : "";
  const cityQuery = !_.isEmpty(city) ? `/${city}` : "";
  const schemeQuery = !_.isEmpty(scheme) ? `/${scheme}` : "";
  const typeQuery = type;
  const tenureQuery = !_.isEmpty(tenure) ? `/${tenure}` : "";


  const {tenure,property,minPrice,maxPrice} = filter

  const tenureFilter = !_.isEmpty(tenure) ? `&tenure=${tenure}` : "";
  const propertyTypeFilter = !_.isEmpty(property) ? `&propertyType=${property}` : "";
  const minPriceFilter = !_.isEmpty(minPrice) ? `&minPrice=${minPrice}` : "";
  const maxPriceFilter = !_.isEmpty(maxPrice) ? `&maxPrice=${maxPrice}` : "";


  return apiInstance.get(`${version}/listing-transaction/${typeQuery}${stateQuery}${cityQuery}${schemeQuery}?page=${page}${tenureFilter}${propertyTypeFilter}${minPriceFilter}${maxPriceFilter}`);
  // return apiInstance.get(`${version}/listing-transaction/${typeQuery}/${stateQuery}?page=${pageQuery}`);
}

const getAgentListingSaleDetail = (page, slug) => apiInstance.get(`${version}/account/${slug}/listing/sale?page=${page}`);
const getAgentListingRentDetail = (page, slug) => apiInstance.get(`${version}/account/${slug}/listing/rent?page=${page}`);
const getDeveloperListingDetail = (page, slug) => apiInstance.get(`${version}/account/${slug}/listing/all?page=${page}`);

export default {
  setHeaderToken,
  setHeaderAgentToken,
  setHeaderLanguage,
  getCommonData,
  postPhoneVerification,
  putPhoneVerification,
  postSigUp,
  loginAccount,
  getMeData,
  postPasswordForget,
  postPasswordReset,
  getPortalUserList,
  postPortalUserRole,
  getPortalCategoryList,
  getCategoryList,
  postPortalCategory,
  putPortalCategory,
  deletePortalCategory,
  postImage,
  getPortalBlogList,
  postPortalBlog,
  getBlogDetail,
  putBlogDetail,
  getPortalBlogDetail,
  deletePostBlogDetail,
  updateBlogStatus,
  getBlogList,
  getTagList,
  putChangePassword,
  putProfileAvatar,
  putProfileBasic,
  getUserData,
  postTopUp,
  getTopUpContent,
  getTopUpRequestList,
  getTopUpTransactionList,
  getProductList,
  getProductDetail,
  getOrderList,
  postCreateOrder,
  postAuthFirebase,
  postUserPropertyListing,
  postUserPropertyProjectCreate,
  putUserPropertyListingData,
  postUserPropertySearchProject,
  postUserPropertySearchState,
  postUserPropertySearchCity,
  getUserPropertyListingDetail,
  putUserPropertyListingDetailImage,
  postUserPropertyListingDataImageDefault,
  deleteUserPropertyListingImageData,
  postUserPropertyListingDataImage,
  putUserPropertyListingPrice,
  putUserPropertyStatus,
  getUserPropertyMetaDetail,
  getUserPropertyDataFilter,
  getPropertyList,
  getPropertyDetail,
  getProjectList,
  postSearchListing,
  getNewsList,
  getHomeData,
  postSearchSuggestion,
  postAgentAccount,
  postAgentAccountVerify,
  postAgentSearchAgency,
  postAgentSearchProject,
  postAgentListing,
  postAgentListingv2,
  putAgentListingData,
  postAgentListingDataImage,
  deleteAgentListingImageData,
  getAgentListing,
  getAgentListingDetail,
  getAgentListingSaleDetail,
  getAgentListingRentDetail,
  getDeveloperListingDetail,
  getAgentCommonData,
  getAgentCommonState,
  getAgentCommonCity,
  postAgentListingDataPublish,
  postAgentListingDataImageDefault,
  postAgentSearchState,
  postAgentSearchCity,
  postAgentProjectCreate,
  postAuthVerify,
  postAuthSignUp,
  postAuthSignIn,
  postVerification,
  postMeBindPhone,
  postLoggingInterest,
  getAccountDetail,
  getAgentLead,
  putAgentLeadDetail,
  getPostListing,
  getPostDetail,
  getAccountListAgent,
  getAccountListDeveloper,
  getWishlistListing,
  putPropertyDetailWishlist,
  putProjectDetailWishlist,
  postMeChangeEmail,
  postMeChangePhone,
  getFaqGeneral,
  putAgentListingDetailImage,
  putPropertyDetailStatus,
  getUserPropertyListing,
  getPropertyMeStatistic,
  postAgentSubscriptionPackage,
  postOrderPaymentDetail,
  postOrderFreePaymentDetail,
  postOrderPaymentReceiveDetail,
  getOrderHistoryList,
  getReferralCode,
  postTransactionSearchData,
  getTransactionDataListing,
  getTransactionCommonData,
  postLoggingInterestLoan
};
