import React, { PureComponent } from "react";
import styled from "styled-components";
import { withTheme } from "styled-components";
import ReactLoading from "react-loading";
import CustomFont from "../../components/CustomFont";
import Image from "next/image";

const MainContainer = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  overflow: hidden;
  max-height: ${props => (props.ignoreHeight ? "auto" : "100vh")};
`;

class LoadingOverlay extends PureComponent {
  render() {
    const {
      hideFont = false,
      overlay = false,
      height = 64,
      width = 64,
      ignoreHeight = false
    } = this.props;
    return (
      <MainContainer
        ignoreHeight={ignoreHeight}
        style={{
          backgroundColor: overlay ? "rgba(255,255,255,0.7)" : "transparent"
        }}
      >
        <div style={{ height: height, width: width, position: "relative" }}>
          <Image src={"/pg-loading.gif"} layout="fill" alt={"Loading Property Genie"}/>
        </div>
        {hideFont ? (
          false
        ) : (
          <CustomFont styles={{ marginTop: "1rem" }}>Loading</CustomFont>
        )}
      </MainContainer>
    );
  }
}

export default withTheme(LoadingOverlay);
{
  /*<ReactLoading*/
}
{
  /*type="spinningBubbles"*/
}
{
  /*color="#000"*/
}
{
  /*height={height}*/
}
{
  /*width={width}*/
}
{
  /*/>*/
}
