export const getWishlistListRequest = (page) => ({
    type:"wishlist/GET_WISH_LIST_LIST_REQUEST",
    page
})

export const getWishlistListSuccess = (page,data,pagination) => ({
    type:"GET_WISH_LIST_LIST_SUCCESS",
    page,
    data,
    pagination
})

export const getWishlistListFailure = messages => ({
    type:"GET_WISH_LIST_LIST_FAILURE",
    messages
})

export const putWishlistSuccess =  (id,data) => ({
    type:"wishlist/PUT_WISHLIST_SUCCESS",
    id,data
})
