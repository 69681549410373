import Types from "./actionTypes";

export const rehydrateMovementStateSuccess = data => ({
  type: Types.REHYDRATE_MOVEMENT_STATE_SUCCESS,
  data
});

export const getMovementListRequest = () => ({
  type: Types.GET_MOVEMENT_LIST_REQUEST
});

export const getMovementListSuccess = (data, meta) => ({
  type: Types.GET_MOVEMENT_LIST_SUCCESS,
  data,
  meta
});

export const getMovementListFailure = messages => ({
  type: Types.GET_MOVEMENT_LIST_FAILURE,
  messages
});

export const addNewMovementRequest = data => ({
  type: Types.ADD_NEW_MOVEMENT_REQUEST,
  data
});

export const addNewMovementSuccess = data => ({
  type: Types.ADD_NEW_MOVEMENT_SUCCESS,
  data
});

export const addNewMovementFailure = messages => ({
  type: Types.ADD_NEW_MOVEMENT_FAILURE,
  messages
});

export const deleteMovementRequest = id => ({
  type: Types.DELETE_MOVEMENT_REQUEST,
  id
});

export const deleteMovementSuccess = id => ({
  type: Types.DELETE_MOVEMENT_SUCCESS,
  id
});

export const deleteMovementFailure = messages => ({
  type: Types.DELETE_MOVEMENT_FAILURE,
  messages
});

export const putUpdateMovementRequest = (data, index) => ({
  type: Types.PUT_UPDATE_MOVEMENT_REQUEST,
  data,
  index
});

export const putUpdateMovementSuccess = (data, index) => ({
  type: Types.PUT_UPDATE_MOVEMENT_SUCCESS,
  data,
  index
});

export const putUpdateMovementFailure = messages => ({
  type: Types.PUT_UPDATE_MOVEMENT_FAILURE,
  messages
});
