import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as topUpAction from "../actions/topUp";
import _ from "lodash";
export default api => {
  function* watcherPostTopUpRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postTopUp, postData);

      yield put(topUpAction.postTopUpSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, topUpAction.postTopUpFailure, effects);
    }
  }

  function* watcherGetTopUpRequestListRequest({ page = 1, filterData }, effects) {
    const { call, put, select } = effects;

    try {
      const startDate = _.get(filterData, ["start_date"], "");
      const endDate = _.get(filterData, ["end_date"], "");
      const status = _.get(filterData, ["status"], "");
      const response = yield call(api.getTopUpRequestList, page, startDate, endDate, status);

      const { items, _meta } = response.data;

      yield put(topUpAction.getTopUpRequestListSuccess(items, _meta, page));
    } catch (error) {
      yield call(httpErrorHelpers, error, topUpAction.getTopUpRequestListFailure, effects);
    }
  }
  function* watcherGetTopUpTransactionListRequest({ page = 1,filterData }, effects) {
    const { call, put, select } = effects;

    try {
      const startDate = _.get(filterData, ["start_date"], "");
      const endDate = _.get(filterData, ["end_date"], "");
      const response = yield call(api.getTopUpTransactionList, page,startDate,endDate);
      const { items, _meta } = response.data;

      yield put(topUpAction.getTopUpTransactionListSuccess(items, _meta, page));
    } catch (error) {
      yield call(httpErrorHelpers, error, topUpAction.getTopUpTransactionListFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    commonData: null
  });

  return {
    namespace: "topUp",
    state: INITIAL_STATE,
    reducers: {
      POST_TOP_UP_REQUEST(state, action) {
        return state.setIn(["postTopUp", "loading"], true).setIn(["postTopUp", "status"], false);
      },
      POST_TOP_UP_SUCCESS(state, action) {
        return state.setIn(["postTopUp", "loading"], false).setIn(["postTopUp", "status"], true);
      },
      POST_TOP_UP_FAILURE(state, action) {
        return state.setIn(["postTopUp", "loading"], false).setIn(["postTopUp", "status"], false);
      },
      GET_TOP_UP_REQUEST_LIST_REQUEST(state, action) {
        return state.setIn(["requestList", "loading"], true);
      },
      GET_TOP_UP_REQUEST_LIST_SUCCESS(state, action) {
        const { currentPage } = action.pagination;
        const { requestList } = state;

        return state
          .setIn(["requestList", "items", action.page], action.data)
          .setIn(["requestList", "pagination"], action.pagination)
          .setIn(["requestList", "loading"], false);
      },
      GET_TOP_UP_REQUEST_LIST_FAILURE(state) {
        return state.setIn(["requestList", "loading"], false);
      },
      GET_TOP_UP_TRANSACTION_LIST_REQUEST(state, action) {
        return state.setIn(["transactionList", "loading"], true);
      },
      GET_TOP_UP_TRANSACTION_LIST_SUCCESS(state, action) {
        const { currentPage } = action.pagination;
        const { transactionList } = state;

        return state
          .setIn(["transactionList", "items", action.page], action.data)
          .setIn(["transactionList", "pagination"], action.pagination)
          .setIn(["transactionList", "loading"], false);
      },
      GET_TOP_UP_TRANSACTION_LIST_FAILURE(state) {
        return state.setIn(["transactionList", "loading"], false);
      }
    },
    effects: {
      POST_TOP_UP_REQUEST: [watcherPostTopUpRequest],
      GET_TOP_UP_REQUEST_LIST_REQUEST: [watcherGetTopUpRequestListRequest],
      GET_TOP_UP_TRANSACTION_LIST_REQUEST: [watcherGetTopUpTransactionListRequest]
    }
  };
};
