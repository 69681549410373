import _ from "lodash";

export const getAuthToken = state => _.get(state, "token", null);
export const getAuthAgentToken = state => _.get(state,['agentToken'],null)
export const getAuthFirebaseLoading = state => _.get(state,['auth','firebase','loading'],false)
export const getAuthFirebaseStatus = state => _.get(state,['auth','firebase','status'],false)

export const getAuthSignInLoading = state => _.get(state, ["authSignIn", "loading"], false);
export const getAuthSignInStatus = state => _.get(state, ["authSignIn", "status"], false);

export const getVerifyPhoneNumberLoading = state => _.get(state,['verifyPhoneNumber','loading'],false)
export const getVerifyPhoneNumberStatus = state => _.get(state,['verifyPhoneNumber','status'],false)
export const getVerifyPhoneNumberData = state => _.get(state,['verifyPhoneNumber','data'],null)
export const getVerifyEmailLoading = state => _.get(state,['verifyEmail','loading'],false)
export const getVerifyEmailStatus = state => _.get(state,['verifyEmail','status'],false)
export const getVerifyEmailData = state => _.get(state,['verifyEmail','data'],null)
export const getAuthSignUpData = state => _.get(state,['authSignUp','data'],null)
export const getAuthSignUpLoading = state => _.get(state,['authSignUp','loading'],false)
export const getAuthSignUpStatus = state => _.get(state,['authSignUp','status'],false)
//verification
export const getPhoneVerificationToken = phoneVerification => _.get(phoneVerification, ["token"], null);
export const getPhoneVerificationExpiryTimestamp = phoneVerification =>
  _.get(phoneVerification, ["expiry_datetime", "timestamp"], null);

//sign up user
export const getSignUpUserPrefix = signUpUser => _.get(signUpUser, ["prefix"], null);
export const getSignUpUserPhoneNumber = signUpUser => _.get(signUpUser, ["phoneNumber"], null);
export const getSignUpUserVerificationCode = signUpUser => _.get(signUpUser, ["verificationCode"], null);


//phone verify
export const getPhoneVerifyAttributeIsValid = phoneVerify=>_.get(phoneVerify,['attribute','is_valid'],false)
export const getPhoneVerifyAttributeIsExist = phoneVerify => _.get(phoneVerify,['attribute','is_exist'],false)
export const getPhoneVerifyMobileComplete = phoneVerify => _.get(phoneVerify,['mobile','complete'],"")
export const getPhoneVerifyMobileNumber = phoneVerify => _.get(phoneVerify,['mobile','number'],"")
export const getPhoneVerifyMobilePrefix = phoneVerify => _.get(phoneVerify,['mobile','prefix'],"")
export const getPhoneVerifyMobileReason = phoneVerify => _.get(phoneVerify,['reason'],"")

