import Immutable from "seamless-immutable";
import * as userActions from "../actions/user";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";

export default api => {
  function* watcherGetUserDataRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getUserData, id);

      yield put(userActions.getUserDataSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, userActions.getUserDataFailure, effects);
    }
  }

  function* watcherGetUserBlogDataRequest({ page, authorParam }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getBlogList, page, authorParam);

      const { items, _meta, author } = response.data;

      yield put(userActions.getUserBlogDataSuccess(items, _meta, author));
    } catch (error) {
      yield call(httpErrorHelpers, error, userActions.getUserBlogDataFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    user: null
  });

  return {
    namespace: "user",
    state: INITIAL_STATE,
    reducers: {
      GET_USER_DATA_REQUEST(state) {
        return state.setIn(["loading"], true);
      },
      GET_USER_DATA_SUCCESS(state, action) {
        return state.setIn(["loading"], false).setIn(["user"], action.data);
      },
      GET_USER_DATA_FAILURE(state) {
        return state.setIn(["loading"], false);
      },
      GET_USER_BLOG_DATA_REQUEST(state) {
        return state.setIn(["loading"], true);
      },
      GET_USER_BLOG_DATA_SUCCESS(state, action) {
        const { currentPage } = action._meta;
        const { blogData } = state;
        return state
          .setIn(["blogData", "items", currentPage], action.items)
          .setIn(["blogData", "_meta"], action._meta)
          .setIn(["blogData", "author"], action.author)
          .setIn(["loading"], false);
      },
      GET_USER_BLOG_DATA_FAILURE(state) {
        return state.setIn(["loading"], false);
      }
    },
    effects: {
      GET_USER_DATA_REQUEST: [watcherGetUserDataRequest],
      GET_USER_BLOG_DATA_REQUEST: [watcherGetUserBlogDataRequest]
    }
  };
};
