import Immutable from "seamless-immutable";
import * as globalActions from "../actions/global";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";

export default api => {
  function* watcherUpdateLanguage({ language = "en" }, effects) {
    const { put, call } = effects;

    const formatLanguage = language.substr(0, 2);

    //   yield call(api.setHeaderLanguage, formatLanguage);
    localStorage.setItem("EWF_LANGUAGE", formatLanguage);
    yield put(globalActions.updateLanguageCompleted(formatLanguage));
  }

  function* watcherGetHomeDataRequest({ payload }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getHomeData);

      yield put(globalActions.getHomeDataSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, globalActions.getHomeDataFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    language: process.browser ? localStorage.getItem("EWF_LANGUAGE") : null,
    testing: null
  });

  return {
    namespace: "global",
    state: INITIAL_STATE,
    reducers: {
      CHANGE_LANGUAGE(state, action) {
        return state.setIn(["language"], action.data);
      },
      REHYDRATE_COMPLETED(state) {
        return state.setIn(["isRehydrationCompleted"], true);
      },
      UPDATE_LANGUAGE_COMPLETED(state, action) {
        return state.setIn(["language"], action.language);
      },
      GET_HOME_DATA_REQUEST(state) {
        return state.setIn(["home", "loading"], true);
      },
      GET_HOME_DATA_SUCCESS(state, action) {
        return state.setIn(["home", "loading"], false).setIn(["home", "data"], action.data);
      },
      GET_HOME_DATA_FAILURE(state) {
        return state.setIn(["home", "loading"], false);
      },
      SHOW_SHOULD_UPDATE_PHONE(state,action) {
        return state.setIn(["home", "shouldUpdatePhone"], action.isUpdate);
      }
    },
    effects: {
      UPDATE_LANGUAGE: [watcherUpdateLanguage],
      GET_HOME_DATA_REQUEST: [watcherGetHomeDataRequest]
    }
  };
};
