import React, { Component } from "react";
import styled from "styled-components";

import { withTheme } from "styled-components";
import Container from "@mui/material/Container";
import { Color as Colors } from "../../../../../src/utils/Color";
import CustomFont from "../../../../../components/CustomFont";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import calculatorConstants from "../../../../../src/utils/calculatorConstants";
import Helpers from "../../../../../src/utils/Helpers";
import _ from "lodash";
import NumberFormat, { InputAttributes } from "react-number-format";
import Constants from "/src/constants";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SearchIcon from '@mui/icons-material/Search';
import {maxDevice} from "../../../../../src/utils/breakpoints";

const CustomSelect = styled(Select)`
  width: 100%;
`;
const TextFieldContainer = styled(TextField)`
  background-color: white;
  font-size: 1.0rem;
  font-weight: 600;
  width: 100%;
  & .MuiInputLabel-root {
    margin-top: 5px;
  }

  & .Mui-focused {
    color: white;
  }
  & .MuiFilledInput-root {
    background-color: white;
    font-size: 1.4rem;
    font-weight: 600;

    &:before {
      border-bottom: 0 !important;
    }
    &:hover {
      outline: 0 !important;
    }
    &:focus {
      outline: 0 !important;
    }
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: rgba(0, 0, 0, 0.23);
      color: #2c2c2c;
      font-weight: 600;
    }
    & :hover fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
    & .Mui-focused fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
  & .MuiOutlinedInput-input {
    font-size: 1.0rem;
    color: #2c2c2c;
    font-weight: 600;
  }
  // & .MuiOutlinedInput-input::placeholder {
  //   font-size: 1.4rem;
  //   font-weight: 600;
  //   color: red !important;
  // }
`;

const DownPaymentTypeData = [
  {
    label: "Amount",
    value: Constants.DOWNPAYMENT_TYPE_AMOUNT
  },
  {
    label: "Percentage",
    value: Constants.DOWNPAYMENT_TYPE_RATE
  }
];

const CustomLoanTitleText = styled.div`
  font-size: 1.3rem;
  color: ${Colors.primaryColor};
  font-weight: 700;

  @media ${maxDevice.md} {
    font-size: 0.8rem;
  }
`;

function NumberFormatCustom(props) {
  const { inputRef, onChange, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      isAllowed={values => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || floatValue;
      }}
      value={value}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
}

function NumberFormatCustomPercentage(props) {
  const { inputRef, onChange, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      isAllowed={values => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || floatValue <= 100;
      }}
      value={value}
      thousandSeparator
      allowNegative={false}
      isNumericString
      allowEmptyFormatting
    />
  );
}
class HomeLoanCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyPrice: 0,
      interestRate: 0,
      loanTerm: 0,
      downPaymentRate: 0,
      downPayment: 0,
      downPaymentType: Constants.DOWNPAYMENT_TYPE_RATE
    };
  }

  componentDidMount() {
    const { propertyPrice } = this.props;
    setTimeout(
      () =>
        this.setState({ propertyPrice: propertyPrice }, () => {
          this.setState({
            downPayment: this.isDownpaymentTypeAmount()
              ? Helpers.downPaymentCalculateWithAmount(this.state.propertyPrice, this.state.downPayment)
              : Helpers.downPaymentCalculate(this.state.propertyPrice, this.state.downPaymentRate),
            interestRate: calculatorConstants.HOME_CALCULATOR_INTEREST_RATE,
            loanTerm: calculatorConstants.HOME_CALCULATOR_LOAN_TERM,
            downPaymentRate: 0
          });
        }),
      500
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.propertyPrice !== prevProps.propertyPrice) {
      setTimeout(
        () =>
          this.setState({ propertyPrice: this.props.propertyPrice }, () => {
            this.setState({
              downPayment: this.isDownpaymentTypeAmount()
                ? Helpers.downPaymentCalculateWithAmount(this.state.propertyPrice, this.state.downPayment)
                : Helpers.downPaymentCalculate(this.state.propertyPrice, this.state.downPaymentRate)
            });
          }),
        500
      );
    }
  }

  isDownpaymentTypeAmount = () => {
    return _.isEqual(this.state.downPaymentType, Constants.DOWNPAYMENT_TYPE_AMOUNT);
  };

  onChangePropertyPriceText = e => {
    const { onChangePropertyPriceText } = this.props;
    this.setState({ propertyPrice: e.target.value }, () => {
      this.setState({
        downPayment: this.isDownpaymentTypeAmount()
          ? Helpers.downPaymentCalculateWithAmount(this.state.propertyPrice, this.state.downPayment)
          : Helpers.downPaymentCalculate(this.state.propertyPrice, this.state.downPaymentRate)
      });
    });

    onChangePropertyPriceText && onChangePropertyPriceText(e.target.value);
  };

  onChangeInterestRateText = e => {
    this.setState({ interestRate: parseFloat(e.target.value).toFixed(2) });
  };

  onChangeLoanTermText = e => {
    this.setState({ loanTerm: e.target.value });
  };

  onChangeDownPaymentRateText = e => {
    if (this.state.downPaymentType === Constants.DOWNPAYMENT_TYPE_AMOUNT) {
      this.setState(
        {
          downPaymentRate: e.target.value
        },
        () => {

        }
      );
    } else {
      this.setState(
        {
          downPaymentRate: parseFloat(e.target.value).toFixed(2)
        },
        () => {
          this.setState({
            downPayment: Helpers.downPaymentCalculate(this.state.propertyPrice, this.state.downPaymentRate)
          });
        }
      );
    }
  };

  handleDownPaymentChange = e => {
    this.setState({
      downPaymentType: e.target.value
    });
  };

  onChangeDownPaymentText = e => {
    if (this.state.downPaymentType === Constants.DOWNPAYMENT_TYPE_AMOUNT) {
      this.setState(
        {
          downPayment: e.target.value
        },
        () => {
          this.setState({
            downPaymentRate: parseFloat((this.state.downPayment / this.state.propertyPrice) * 100).toFixed(2)
          });
        }
      );
    } else {
      this.setState({
        downPayment: e.target.value
      });
    }
  };

    onClickLoanForm =(propertyPrice, interestRate, loanTerm) =>{
        const { onClickLoanForm } = this.props;
        onClickLoanForm && onClickLoanForm(propertyPrice, interestRate, loanTerm);
    }

  render() {
    const {
      propertyPrice,
      loanTerm,
      downPaymentRate,
      downPayment,
      interestRate,
      downPaymentType
    } = this.state;

    const loanAmount = Helpers.getLoanAmount(propertyPrice, downPayment);

    const monthLoan = Helpers.getMonthlyLoan(loanAmount, interestRate, loanTerm);

    return (
      <div style={{ marginTop: "1.2rem" }}>
        <CustomFont
          variant="caption"
          size="tlarge"
          styles={{
            color: Colors.primaryColor,
            fontWeight: "600"
          }}
        >
          RM {monthLoan.toLocaleString()}
          <span
            style={{
              color: Colors.blackColor,
              fontSize: "1rem",
              fontWeight: "normal"
            }}
          >
            /per month
          </span>
        </CustomFont>
        <br/>
        <br/>
        <CustomFont
          variant="caption"
          size="normal"
          styles={{
            color: Colors.blackColor,
            fontSize: "1rem",
            marginTop: "1rem"
          }}
        >
          Loan amount: RM
            {propertyPrice !== 0 ? parseFloat(loanAmount).toLocaleString() : '0'}

        </CustomFont>
        <Grid container spacing={2} style={{ marginTop: "1.5rem" }}>
          <Grid item md={4} style={{ width: "100%", paddingTop: "10px" }}>
            <TextFieldContainer
              label="Property Price(RM)"
              value={propertyPrice}
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.onChangePropertyPriceText}
              InputProps={{
                inputComponent: NumberFormatCustom,
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={4} style={{ width: "100%", paddingTop: "10px" }}>
            <FormControl fullWidth >
              <InputLabel
                id="demo-simple-select-label"
                shrink
                style={{
                  background: "white",
                  paddingLeft: "3px",
                  paddingRight: "5px"
                }}
              >
                Down Payment Type
              </InputLabel>
              <CustomSelect
                value={downPaymentType}
                label="Down Payment Type"
                onChange={this.handleDownPaymentChange}
              >
                {_.map(DownPaymentTypeData, item => {
                  const name = _.get(item, ["label"], "");
                  const value = _.get(item, ["value"], "");
                  return <MenuItem value={value}>{name}</MenuItem>;
                })}
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid item md={4} style={{ width: "100%", paddingTop: "10px" }}>
            <TextFieldContainer
              label="Down Payment(%)"
              disabled={_.isEqual(downPaymentType, Constants.DOWNPAYMENT_TYPE_AMOUNT)}
              value={downPaymentRate}
              onChange={this.onChangeDownPaymentRateText}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <CustomFont
                    size="tlarge"
                    styles={{
                      fontWeight: "600",
                      color: Colors.blackColor
                    }}
                    position="end"
                  >
                    {_.isEqual(downPaymentType, Constants.DOWNPAYMENT_TYPE_RATE) ? "%" : ""}
                  </CustomFont>
                ),
                inputComponent: _.isEqual(downPaymentType, Constants.DOWNPAYMENT_TYPE_RATE)
                  ? NumberFormatCustomPercentage
                  : NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item md={4} style={{ width: "100%", paddingTop: "10px" }}>
            <TextFieldContainer
              disabled={_.isEqual(downPaymentType, Constants.DOWNPAYMENT_TYPE_RATE)}
              label="Down Payment(RM)"
              value={downPayment}
              onChange={this.onChangeDownPaymentText}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item md={4} style={{ width: "100%", paddingTop: "10px" }}>
            <TextFieldContainer
              label="Annual Interest Rate(%)"
              onChange={this.onChangeInterestRateText}
              InputLabelProps={{
                shrink: true
              }}
              value={interestRate}
              InputProps={{
                endAdornment: (
                  <CustomFont
                    size="tlarge"
                    styles={{
                      fontWeight: "600",
                      color: Colors.blackColor
                    }}
                    position="end"
                  >
                    %
                  </CustomFont>
                ),
                inputComponent: NumberFormatCustomPercentage
              }}
            />
          </Grid>
          <Grid item md={4} style={{ width: "100%", paddingTop: "10px" }}>
            <TextFieldContainer
              label="Loan Term(Years)"
              value={loanTerm}
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.onChangeLoanTermText}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
          </Grid>
        </Grid>


<div style={{ marginTop: "20px", display: "flex", flexDirection: "column" }}>
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px" }}>
          {/*<CustomFont*/}
          {/*    variant="caption"*/}
          {/*    styles={{*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        fontWeight: "700"*/}
          {/*    }}*/}
          {/*>*/}
          {/*    Check your home loan eligibility*/}
          {/*</CustomFont>*/}
        <CustomLoanTitleText>
            Check your home loan eligibility
        </CustomLoanTitleText>
        <Button
            style={{
                backgroundColor: Colors.primaryColor,
                width: "fit-content",
                fontWeight: "600",
                fontSize: "0.8rem",
                borderRadius: "10px",
                borderWidth: "2px",
                alignItems: "center",
                padding: "5px 15px"
            }}
            variant="contained"
            endIcon={
                <SearchIcon style={{ fontSize: "20px"}} />
            }
            onClick={e => {
                e.preventDefault();
                this.onClickLoanForm(propertyPrice, interestRate, loanTerm);
            }}
        >
            Apply Now!
        </Button>
    </div>
          <CustomFont
              variant="caption"
              size="xsmall"
          >
              Find out instantly if you qualify for a home loan with our partner bank. Quick and easy application!
          </CustomFont>

</div>

      </div>
    );
  }
}

export default withTheme(HomeLoanCalculator);
