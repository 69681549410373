export const getCommonDataRequest = () => ({
  type: "common/GET_COMMON_DATA_REQUEST"
});

export const getCommonDataSuccess = data => ({
  type: "GET_COMMON_DATA_SUCCESS",
  data
});

export const getCommonDataFailure = message => ({
  type: "GET_COMMON_DATA_FAILURE",
  message
});

export const getCategoryListRequest = () => ({
  type: "common/GET_CATEGORY_LIST_REQUEST"
});

export const getCategoryListSuccess = data => ({
  type: "GET_CATEGORY_LIST_SUCCESS",
  data
});

export const getCategoryListFailure = message => ({
  type: "GET_CATEGORY_LIST_FAILURE",
  message
});

export const getTagListRequest = () => ({
  type: "common/GET_TAG_LIST_REQUEST"
});

export const getTagListSuccess = data => ({
  type: "GET_TAG_LIST_SUCCESS",
  data
});

export const getTagListFailure = message => ({
  type: "GET_TAG_LIST_FAILURE",
  message
});

export const getTopUpContentRequest = () => ({
  type: "common/GET_TOP_UP_CONTENT_REQUEST"
});

export const getTopUpContentSuccess = data => ({
  type: "GET_TOP_UP_CONTENT_SUCCESS",
  data
});

export const getTopUpContentFailure = message => ({
  type: "GET_TOP_UP_CONTENT_FAILURE",
  message
});

export const postLoggingInterestRequest = (postData) => ({
  type:"common/POST_LOGGING_INTEREST_REQUEST",
  postData
})

export const postLoggingInterestSuccess = data => ({
  type:"POST_LOGGING_INTEREST_SUCCESS",
  data
})

export const postLoggingInterestFailure = message => ({
  type:"POST_LOGGING_INTEREST_FAILURE",
  message
})

export const getCommonFaqGeneralRequest = () => ({
  type:"common/GET_COMMON_FAQ_GENERAL_REQUEST"
})

export const getCommonFaqGeneralSuccess = (data) => ({
  type:"GET_COMMON_FAQ_GENERAL_SUCCESS",
  data
})

export const getCommonFaqGeneralFailure = messages => ({
  type:"GET_COMMON_FAQ_GENERAL_FAILURE",
  messages
})
// Loan calculation Form
export const postLoggingInterestLoanRequest = (postData) => ({
  type:"common/POST_LOGGING_INTEREST_LOAN_REQUEST",
  postData
})

export const postLoggingInterestLoanSuccess = data => ({
  type:"POST_LOGGING_INTEREST_LOAN_SUCCESS",
  data
})

export const postLoggingInterestLoanFailure = message => ({
  type:"POST_LOGGING_INTEREST_LOAN_FAILURE",
  message
})