import Helpers from "./Helpers";

//live
export default {
  API_DOMAIN: Helpers.isProduction
    ? "https://api.propertygenie.com.my/"
    : "https://api.uat.propertygenie.com.my/",
  GOOGLE_MAP_KEY: Helpers.isProduction
    ? "AIzaSyACX0ae7Okf_LRgJa3dE1r9rlUXX_IhKMc"
    : "AIzaSyA1QGIKdtvrs8A38qmkn2X3_Ics7cmnK2g",
  WEBSITE_URL: Helpers.isProduction
      ? "https://propertygenie.com.my/"
      : "http://localhost:3000/"
};

//staging
// export default {
//   API_DOMAIN: Helpers.isProduction
//       ? "https://api.propertygenie.com.my/"
//       : "https://api.propertygenie.com.my/",
//     GOOGLE_MAP_KEY: Helpers.isProduction ? "AIzaSyACX0ae7Okf_LRgJa3dE1r9rlUXX_IhKMc" : "AIzaSyACX0ae7Okf_LRgJa3dE1r9rlUXX_IhKMc"
// };

// export default {
//   API_DOMAIN: Helpers.isProduction
//     ? "https://api.uat.propertygenie.com.my/"
//     : "https://api.uat.propertygenie.com.my/",
//   GOOGLE_MAP_KEY: Helpers.isProduction
//     ? "AIzaSyA1QGIKdtvrs8A38qmkn2X3_Ics7cmnK2g"
//     : "AIzaSyA1QGIKdtvrs8A38qmkn2X3_Ics7cmnK2g"
// };
