export const setRehydrateCompleted = () => ({
  type: "global/REHYDRATE_COMPLETED"
});

export const updateLanguage = language => ({
  type: "global/UPDATE_LANGUAGE",
  language
});

export const updateLanguageCompleted = language => ({
  type: "UPDATE_LANGUAGE_COMPLETED",
  language
});

export const getHomeDataRequest = () => ({
  type: "global/GET_HOME_DATA_REQUEST"
});

export const getHomeDataSuccess = data => ({
  type: "GET_HOME_DATA_SUCCESS",
  data
});

export const getHomeDataFailure = message => ({
  type: "GET_HOME_DATA_FAILURE",
  message
});

export const showShouldUpdatePhone = (isUpdate =false) => ({
  type:"global/SHOW_SHOULD_UPDATE_PHONE",
  isUpdate
})
