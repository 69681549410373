import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as productActions from "../actions/product";

export default api => {
  function* watcherGetProductListRequest({ page = 1 }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getProductList, page);

      const { items } = response.data;

      yield put(productActions.getProductListSuccess(items));
    } catch (error) {
      yield call(httpErrorHelpers, error, productActions.getProductListFailure, effects);
    }
  }

  function* watcherGetProductDetailRequest({ id }, effects) {
    const { call, put, select } = effects;

    try {
      const response = yield call(api.getProductDetail, id);

      const { items, _meta } = response.data;

      yield put(productActions.getProductDetailSuccess(id, response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, productActions.getProductDetailFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    commonData: null
  });

  return {
    namespace: "product",
    state: INITIAL_STATE,
    reducers: {
      GET_PRODUCT_LIST_REQUEST(state, action) {
        return state.setIn(["productList", "loading"], true);
      },
      GET_PRODUCT_LIST_SUCCESS(state, action) {
        return state.setIn(["productList", "loading"], false).setIn(["productList", "items"], action.data);
      },
      GET_PRODUCT_LIST_FAILURE(state, action) {
        return state.setIn(["productList", "loading"], false);
      },
      GET_PRODUCT_DETAIL_REQUEST(state, action) {
        return state.setIn(["productDetail", "loading"], true);
      },
      GET_PRODUCT_DETAIL_SUCCESS(state, action) {
        return state
          .setIn(["productDetail", action.id], action.data)
          .setIn(["productDetail", "loading"], false);
      },
      GET_PRODUCT_DETAIL_FAILURE(state) {
        return state.setIn(["productDetail", "loading"], false);
      }
    },
    effects: {
      GET_PRODUCT_LIST_REQUEST: [watcherGetProductListRequest],
      GET_PRODUCT_DETAIL_REQUEST: [watcherGetProductDetailRequest]
    }
  };
};
