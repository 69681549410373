import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as newsActions from "../actions/news";

export default api => {
  function* watcherGetNewsListRequest({ page = 1 }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getNewsList, page);

      const { items, _meta } = response.data;

      yield put(newsActions.getNewsListSuccess(items, _meta, page));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        newsActions.getNewsListFailure,
        effects
      );
    }
  }

  const INITIAL_STATE = Immutable({
    news: null
  });

  return {
    namespace: "news",
    state: INITIAL_STATE,
    reducers: {
      GET_NEWS_LIST_REQUEST(state, action) {
        return state.setIn(["newsList", "loading"], true);
      },
      GET_NEWS_LIST_SUCCESS(state, action) {
        return state
          .setIn(["newsList", "loading"], false)
          .setIn(["newsList", "items", action.page], action.data)
          .setIn(["newsList", "pagination"], action.pagination);
      },
      GET_NEWS_LIST_FAILURE(state, action) {
        return state.setIn(["newsList", "loading"], false);
      }
    },
    effects: {
      GET_NEWS_LIST_REQUEST: [watcherGetNewsListRequest]
    }
  };
};

