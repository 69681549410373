export const getUserDataRequest = id => ({
  type: "user/GET_USER_DATA_REQUEST",
  id
});

export const getUserDataSuccess = data => ({
  type: "GET_USER_DATA_SUCCESS",
  data
});

export const getUserDataFailure = message => ({
  type: "GET_USER_DATA_FAILURE",
  message
});

export const getUserBlogDataRequest = (page, authorParam) => ({
  type: "user/GET_USER_BLOG_DATA_REQUEST",
  page,
  authorParam
});

export const getUserBlogDataSuccess = (items, _meta, author) => ({
  type: "GET_USER_BLOG_DATA_SUCCESS",
  items,
  _meta,
  author
});

export const getUserBlogDataFailure = message => ({
  type: "GET_USER_BLOG_DATA_FAILURE",
  message
});
