import React, { useEffect } from "react";

import Head from "next/head";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../src/theme";
import dva from "dva-no-router";
import { registerModels } from "../src/models";
import { appWithTranslation } from "next-i18next";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import NProgress from "nprogress"; //nprogress module
import "nprogress/nprogress.css";

import * as commonActions from "../src/actions/common";
import * as globalActions from "../src/actions/global";
import * as profileActions from "../src/actions/profile";
import _ from "lodash";
import Router from "next/router";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "../src/cache";
import "../styles/globals.css";
import { Color as Colors, Color } from "../src/utils/Color";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Constants from "../src/constants";
import "../styles/scss/main.scss";
import LoadingOverlay from "../components/LoadingOverlay";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import * as agentSelectors from "../src/selectors/agent";
import * as agentActions from "../src/actions/agent";
import styled, { withTheme } from "styled-components";
import Container from "@material-ui/core/Container";
import * as globalSelectors from "../src/selectors/global";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { isMobile, isDesktop, isMobileOnly } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import Helper from "../src/utils/Helpers";
import SimpleImageSlider from "react-simple-image-slider";
import Cookies from "js-cookie";
import { NextSeo } from "next-seo";
import Immutable from "seamless-immutable";
import {
  getHomeShouldUpdatePhone,
  getPopupImageMobileHugeSrc
} from "../src/selectors/global";
import VerifyPhoneNumberModal from "../components/VerifyPhoneNumberModal";
import * as authActions from "../src/actions/auth";
import * as authSelectors from "../src/selectors/auth";
import * as verificationActions from "../src/actions/verification";
import * as verificationSelectors from "../src/selectors/verification";
import Script from "next/script";
import Hidden from "@material-ui/core/Hidden";
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1200,
  bgcolor: "white",
  borderRadius: 5,
  boxShadow: 24
};
const styleModalSubscription = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 1200,
  bgcolor: "white",
  borderRadius: 5,
  boxShadow: 24
};

const DEFAULT_SEO = {
  title: "Property Genie | Buy And Rent Property",
  description:
    "Buy or Rent Property in Malaysia with the Most Comprehensive Listings of Property for Sale or for Rent on Property Genie Malaysia, Asia’s Leading Property Portal.",
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://www.propertygenie.com.my",
    title: "Property Genie | Buy And Rent Property",
    description:
      "Buy or Rent Property in Malaysia with the Most Comprehensive Listings of Property for Sale or for Rent on Property Genie Malaysia, Asia’s Leading Property Portal.",
    image: "/logo.jpg",
    siteName: "https://www.propertygenie.com.my"
  },
  twitter: {
    handle: "@property_genie",
    cardType: "summary_large_image"
  }
};

function AppContainer(props) {
  const {
    Component,
    router,
    previousPathname,
    app,
    classes,
    pageProps,
    ...rest
  } = props;

  const scrollRef = React.createRef();
  // const { ...rest } = props;
  const [state, setState] = React.useState({
    openDrawer: false,
    scrolled: false,
    registerAgentSuccessVisible: false,
    adsModalVisible: false
  });

  const [value, setValue] = React.useState(_.split(router.pathname, "/")[1]);

  useEffect(async () => {
    // Remove the server-side injected CSS.
    // AOS.init();
    const authModel = _.find(app && app._models, { namespace: "auth" });
    const profileModel = _.find(app && app._models, { namespace: "profile" });
    const agentModel = _.find(app && app._models, { namespace: "agency" });
    const token = _.get(authModel, ["state", "token"], null);
    const admin = _.get(profileModel, ["admin_id"], null);
    const globalModel = _.find(app && app._models, { namespace: "global" });
    const signUpSuccessStatus = _.get(
      agentModel,
      ["state", "agentAccount", "status"],
      false
    );
    if (signUpSuccessStatus) {
      setState({ ...state, registerAgentSuccessVisible: true });
    }
    if (token === null) {
    }

    const jssStyles = document.querySelector("#jss-server-side");

    // if (jssStyles) {
    //   jssStyles.parentElement.removeChild(jssStyles);
    // }

    /* ====== Temporary disable SW.js ======= */
    // if ("serviceWorker" in navigator) {
    //   window.addEventListener("load", function() {
    //     navigator.serviceWorker.register("/sw.js").then(
    //       function(registration) {
    //         console.log(
    //           "Service Worker registration successful with scope: ",
    //           registration.scope
    //         );
    //       },
    //       function(err) {
    //         console.log("Service Worker registration failed: ", err);
    //       }
    //     );
    //   });
    // }

    window.addEventListener("scroll", checkScrollTop);
  }, []);

  const checkScrollTop = () => {
    if (window.pageYOffset > 70) {
      setState({ ...state, scrolled: true });
    } else {
      setState({ ...state, scrolled: false });
    }
  };

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, openDrawer: open });
  };

  const isIOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const onClickHome = e => {
    e.preventDefault();

    Router.push("/");
  };

  const onClickHistory = e => {
    e.preventDefault();

    Router.push("/history");
  };

  const onClickRequest = e => {
    e.preventDefault();

    Router.push("/request");
  };

  const onClickAnnouncement = e => {
    e.preventDefault();

    Router.push("/announcement");
  };

  const cache = createEmotionCache();

  const handleSuccessRegisterPopupClose = () => {};

  const handleClose = () => {
    setState({ ...state, adsModalVisible: false });
  };

  return (
    <React.Fragment>
      <Head>
        {/*<link*/}
        {/*  rel="stylesheet"*/}
        {/*  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"*/}
        {/*  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"*/}
        {/*  crossOrigin="anonymous"*/}
        {/*/>*/}
        <meta name="theme-color" content={Colors.primaryColor} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin={true}
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin={true}
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        {/*<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />*/}
        <link
          rel="preload"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          as="style"
          onLoad="this.onload=null;this.rel='stylesheet'"
        />
        <noscript>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
        </noscript>
        {/*<link href="https://fonts.googleapis.com/css2?family=Nunito&display=swap" rel="stylesheet" />*/}
        {/*<link*/}
        {/*    rel="preload"*/}
        {/*    href="https://fonts.googleapis.com/css2?family=Nunito&display=swap"*/}
        {/*    as="style"*/}
        {/*    onLoad="this.onload=null;this.rel='stylesheet'"*/}
        {/*/>*/}
        {/*<noscript>*/}
        {/*  <link href="https://fonts.googleapis.com/css2?family=Nunito&display=swap" rel="stylesheet" />*/}
        {/*</noscript>*/}
        <link
          rel="icon"
          href="https://www.propertygenie.com.my/favicon.ico"
          type="image/x-icon"
        />
        <link
          rel="shortcut icon"
          href="https://www.propertygenie.com.my/favicon.ico"
          type="image/x-icon"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="https://www.propertygenie.com.my/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="https://www.propertygenie.com.my/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://www.propertygenie.com.my/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="https://www.propertygenie.com.my/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="https://www.propertygenie.com.my/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://www.propertygenie.com.my/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="https://www.propertygenie.com.my/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://www.propertygenie.com.my/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://www.propertygenie.com.my/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://www.propertygenie.com.my/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="https://www.propertygenie.com.my/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://www.propertygenie.com.my/favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="144x144"
          href="https://www.propertygenie.com.my/favicon-144x144.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="https://www.propertygenie.com.my/android-icon-192x192.png"
        />
        <link
          rel="manifest"
          href="https://www.propertygenie.com.my/manifest.json"
        />
        <meta
          name="msapplication-TileImage"
          content="https://www.propertygenie.com.my/ms-icon-144x144.png"
        />
        <meta name="msapplication-TileColor" content="#00aff7" />

        {/*<link*/}
        {/*  rel="stylesheet"*/}
        {/*  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"*/}
        {/*/>*/}
        <link
          rel="preload"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          as="style"
          onLoad="this.onload=null;this.rel='stylesheet'"
        />
        <noscript>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
        </noscript>
        <meta
          name="facebook-domain-verification"
          content="57tvutw9zb1o1mrvloz6cyzn4a4ni8"
        />

        <meta
          name="facebook-domain-verification"
          content="ydwi7mmlnoxarjlheok5yfvre28cdh"
        />

        <meta name="yandex-verification" content="2523f76b4b3f17af" />

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <noscript id="jss-insertion-point" />
        {/*<script async src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA1QGIKdtvrs8A38qmkn2X3_Ics7cmnK2g&libraries=places&fields=geometry"/>*/}

        <link href="..." />

        {/*<script*/}
        {/*  async*/}
        {/*  crossOrigin="anonymous"*/}
        {/*  id="respondio__growth_tool"*/}
        {/*  src="https://cdn.respond.io/widget/widget.js?wId=4ba21fa1-baba-4054-9894-a5b5e53eec34"*/}
        {/*/>*/}
{/*        <script>*/}
{/*          {`*/}

{/*          !function(f,b,e,v,n,t,s)*/}

{/*          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?*/}

{/*            n.callMethod.apply(n,arguments):n.queue.push(arguments)};*/}

{/*            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';*/}

{/*            n.queue=[];t=b.createElement(e);t.async=!0;*/}

{/*            t.src=v;s=b.getElementsByTagName(e)[0];*/}

{/*            s.parentNode.insertBefore(t,s)}(window, document,'script',*/}

{/*          'https://connect.facebook.net/en_US/fbevents.js');*/}

{/*          fbq('init', '732749425209594');*/}

{/*          fbq('track', 'PageView');*/}
{/*`}*/}
{/*        </script>*/}

{/*        <script*/}
{/*          async*/}
{/*          defer*/}
{/*          crossOrigin="anonymous"*/}
{/*          src="https://connect.facebook.net/en_US/sdk.js"*/}
{/*        />*/}

{/*        <script>*/}
{/*          {`window.fbAsyncInit = function() {*/}
{/*          FB.init({*/}
{/*            appId      : '1397160940782930',*/}
{/*            cookie     : true,                    */}
{/*            xfbml      : true,                    */}
{/*            version    : 'v14.0'*/}
{/*          });*/}
{/*          */}
{/*              FB.AppEvents.logPageView();   */}
{/*      */}
{/*  };*/}
{/*          */}
{/*            (function(d, s, id){*/}
{/*     var js, fjs = d.getElementsByTagName(s)[0];*/}
{/*     if (d.getElementById(id)) {return;}*/}
{/*     js = d.createElement(s); js.id = id;*/}
{/*     js.src = "https://connect.facebook.net/en_US/sdk.js";*/}
{/*     fjs.parentNode.insertBefore(js, fjs);*/}
{/*   }(document, 'script', 'facebook-jssdk'));*/}
{/*          `}*/}
{/*        </script>*/}

        {/* ===== Facebook Pixel Script ===== */}

        <script
            dangerouslySetInnerHTML={{
              __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '732749425209594');
              fbq('track', 'PageView');
            `,
            }}
        />

        {/* ====== Facebook SDK Initialization ======  */}

        <script
            dangerouslySetInnerHTML={{
              __html: `
              window.fbAsyncInit = function() {
                FB.init({
                  appId      : '1397160940782930',
                  cookie     : true,                    
                  xfbml      : true,                    
                  version    : 'v14.0'
                });
                FB.AppEvents.logPageView();
              };

              (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
            `,
            }}
        />

        {/*<style jsx>{`*/}
        {/*  .hideAds {*/}
        {/*    display: block; */}
        {/*  }*/}
        {/*  @media (max-width: 768px) {*/}
        {/*    .hideAds {*/}
        {/*      display: none!important; */}
        {/*    }*/}
        {/*  }*/}
        {/*`}</style>*/}
        {/*<script*/}
        {/*    async*/}
        {/*    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5038584083200880"*/}
        {/*    crossOrigin="anonymous"*/}
        {/*/>*/}
        {/*<ins*/}
        {/*    className="adsbygoogle hideAds"*/}
        {/*    data-ad-format="fluid"*/}
        {/*    data-ad-layout-key="-6j+cm+2g-1s+7r"*/}
        {/*    data-ad-client="ca-pub-5038584083200880"*/}
        {/*    data-ad-slot="6572123800"*/}
        {/*/>*/}
        {/*<script>(adsbygoogle = window.adsbygoogle || []).push({});</script>*/}

      </Head>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5VQVWTJ');
          `}
          </Script>

          <Script id="Organization" type="application/ld+json">
            {`{
            "@context": "https://schema.org/",
            "@type": "Organization",
            "url": "https://www.propertygenie.com.my/",
            "logo": "https://www.propertygenie.com.my/images/Logo/pg-logo-main.png",
            "name": "PropertyGenie Sdn Bhd",
            "image": "https://www.propertygenie.com.my/_next/image?url=%2Fimages%2FLogo%2Fpg-logo-main.png&w=3840&q=75",
            "email": "info@propertygenie.com.my",
            "description": "Malaysia’s fastest growing and expanding property portal for users and real estate agents alike. With PropertyGenie, discovering your ideal property - be it for buying, renting or investing in residential or commercial spaces across Malaysia.",
            "address": "D-6-1, Menara Suezcap 1, No.2, Jalan Kerinchi, Gerbang Kerinchi Lestari, 59200 Kuala Lumpur W.P",
            "telephone": "+603 5626 0022"
          }`}
          </Script>

          <Script id="Website-Browser" type="application/ld+json">
            {`{
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "PropertyGenie Sdn Bhd",
            "url": "https://www.propertygenie.com.my/",
            "description": "Malaysia’s fastest growing and expanding property portal for users and real estate agents alike. With PropertyGenie, discovering your ideal property - be it for buying, renting or investing in residential or commercial spaces across Malaysia.",
            "image": "https://www.propertygenie.com.my/_next/image?url=%2Fimages%2FLogo%2Fpg-logo-main.png&w=3840&q=75",
            "alternateName": "PropertyGenie"
          }`}
          </Script>
          <Script id="Local-Business" type="application/ld+json">
            {`{
            "@context": "https://schema.org/",
            "@type": "LocalBusiness",
            "name": "PropertyGenie Sdn Bhd",
            "address": "D-6-1, Menara Suezcap 1, No.2, Jalan Kerinchi, Gerbang Kerinchi Lestari, 59200 Kuala Lumpur W.P",
            "image": "https://www.propertygenie.com.my/_next/image?url=%2Fimages%2FLogo%2Fpg-logo-main.png&w=3840&q=75",
            "geo": {
            "@type": "GeoCoordinates",
            "latitude": "3.1137607939407386",
            "longitude": "101.662801"
          },
            "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "opens": "00:00",
            "closes": "00:00",
            "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
            ]
          },
            "telephone": "+603 5626 0022",
            "url": "https://www.propertygenie.com.my/"
          }`}
          </Script>

          {/*<NextSeo {...DEFAULT_SEO} />*/}
          <Component
            key={router.route}
            scrollRef={scrollRef}
            theme={theme}
            props={props}
          />
        </ThemeProvider>
      </CacheProvider>
    </React.Fragment>
  );
}

let currentValue;

class MyApp extends React.Component {
  state = {
    previousPathname: null,
    loading: true,
    registerSuccessVisible: false,
    adsModalVisible: false,
    isFirst: false,
    currentPopupData: null,
    imageStartIndex: 0,
    imageCurrentIndex: 0,
    verifyPhoneNumberModalVisible: false,
    phoneNumber: "",
    otp: "",
    isUpdatePhone: false,
    bindPhoneStatus: false,
    bindPhoneLoading: false,
    verifyPhoneStatus: false,
    verifyPhoneLoading: false,
    verificationStatus: false,
    verificationLoading: false,
    globalShouldUpdatePhone: false,
    agentSubscriptionModal: false
  };

  handleChange = value => {
    const { app } = this.props;
    const State = app._store.getState();
    const globalState = _.get(State, ["global", "home", "data"], "");
    const isUpdatePhone = _.get(
      State,
      ["profile", "profile", "attribute", "should_update_phone"],
      false
    );
    const bindPhoneStatus = _.get(
      State,
      ["profile", "bindPhoneNumber", "status"],
      false
    );
    const bindPhoneLoading = _.get(
      State,
      ["profile", "bindPhoneNumber", "loading"],
      false
    );
    const verifyPhoneLoading = _.get(
      State,
      ["auth", "verifyPhoneNumberInRoot", "loading"],
      false
    );
    const verifyPhoneStatus = _.get(
      State,
      ["auth", "verifyPhoneNumberInRoot", "status"],
      false
    );
    const verifyPhoneData = _.get(
      State,
      ["auth", "verifyPhoneNumberInRoot", "data"],
      false
    );
    const verificationStatus = _.get(
      State,
      ["verification", "verificationInRoot", "status"],
      false
    );
    const verificationLoading = _.get(
      State,
      ["verification", "verificationInRoot", "loading"],
      false
    );
    const verificationData = _.get(
      State,
      ["verification", "verificationInRoot", "data"],
      null
    );
    const globalShouldUpdatePhone = _.get(
      State,
      ["global", "home", "shouldUpdatePhone"],
      false
    );
    const currentValue = globalSelectors.getHomePopupItems(globalState);

    if (this.state.verificationStatus !== verificationStatus) {
      this.setState({ verificationStatus: verificationStatus });
      if (verificationStatus) {
        this.handleVerificationData(verificationData);
        this.setState({ verificationData: verificationData });
      }
    }

    if (this.state.verificationLoading !== verificationLoading) {
      this.setState({ verificationLoading: verificationLoading });
    }

    if (this.state.verifyPhoneStatus !== verifyPhoneStatus) {
      this.setState({ verifyPhoneStatus: verifyPhoneStatus });
      if (verifyPhoneStatus) {
        this.handleVerifyPhoneData(verifyPhoneData);
        // this.setState({verifyPhoneNumberModalVisible:true})
      }
    }
    if (this.state.verifyPhoneLoading !== verifyPhoneLoading) {
      this.setState({ verifyPhoneLoading: verifyPhoneLoading });
    }
    if (this.state.globalShouldUpdatePhone !== globalShouldUpdatePhone) {
      this.setState(
        { globalShouldUpdatePhone: globalShouldUpdatePhone },
        () => {
          app._store.dispatch(globalActions.showShouldUpdatePhone(false));
        }
      );

      if (globalShouldUpdatePhone) {
        this.setState({
          verifyPhoneNumberModalVisible: true
        });
      }
    }
    // if (this.state.isUpdatePhone !== isUpdatePhone) {
    //   this.setState({ isUpdatePhone: isUpdatePhone });
    //   if (isUpdatePhone) {
    //     this.setState({ verifyPhoneNumberModalVisible: true });
    //   }
    // }
    if (this.state.bindPhoneStatus !== bindPhoneStatus) {
      this.setState({ bindPhoneStatus: bindPhoneStatus });
      if (bindPhoneStatus) {
        this.setState({ verifyPhoneNumberModalVisible: false });
      }
    }
    if (this.state.bindPhoneLoading !== bindPhoneLoading) {
      this.setState({ bindPhoneLoading: bindPhoneLoading });
    }
    const cookie = Cookies.get(Constants.COOKIES_POPUP_DIALOG);
    if (!_.isEmpty(currentValue) && cookie !== "true") {
      if (Router.asPath === "/") {
        this.setState({ currentPopupData: currentValue });

        this.setState({ adsModalVisible: true });
      }
    }
  };

  handleVerificationData = verificationData => {
    const timestamp = verificationSelectors.getVerificationExpiryDatetimeTimestamp(
      verificationData
    );

    const dateNow = Math.ceil(Date.now() / 1000);
    const resendTime = timestamp - dateNow;

    if (resendTime > 0) {
      this.setState(
        {
          isResend: true,
          expiryTime: resendTime
        },
        () => {
          const interval = setInterval(() => {
            if (this.state.expiryTime >= 1) {
              this.setState({
                expiryTime: this.state.expiryTime - 1
              });
            } else {
              clearInterval(interval);
              this.setState({
                expiryTime: 0
              });
            }
          }, 1000);
        }
      );
    }
  };

  handleVerifyPhoneData = verifyPhoneData => {
    const { app } = this.props;
    const isValid = authSelectors.getPhoneVerifyAttributeIsValid(
      verifyPhoneData
    );
    const reason = authSelectors.getPhoneVerifyMobileReason(verifyPhoneData);

    if (isValid) {
      const number = authSelectors.getPhoneVerifyMobileNumber(verifyPhoneData);
      const prefix = authSelectors.getPhoneVerifyMobilePrefix(verifyPhoneData);
      const complete = authSelectors.getPhoneVerifyMobileComplete(
        verifyPhoneData
      );

      this.setState({
        phoneNumber: number,
        completeNumber: complete,
        prefix: prefix
      });

      app._store.dispatch(
        verificationActions.postVerificationRequestInRoot({
          type: "phone",
          prefix: prefix,
          number: number
        })
      );
    } else {
      this.setState({
        errorPhoneReason: reason
      });
    }
  };

  onCloseVerifyPhoneNumberModal = () => {
    this.setState({
      verifyPhoneNumberModalVisible: false
    });
  };

  onChangePhoneNumberText = value => {
    this.setState({
      phoneNumber: value,
      errorPhoneReason: ""
    });
  };

  componentDidMount() {
    if (window.location.pathname === "/agent-profile") {
      Router.replace("/looking-for-agent");
    }

    Router.events.on("routeChangeStart", () => this.routeChange(false));
    Router.events.on("routeChangeComplete", () => this.routeChange(true, true));
    Router.events.on("routeChangeError", () => this.routeChange(true));
    Router.events.on("beforeHistoryChange", () => this.routeChange(false));
    Router.ready(() => this.setState({ loading: false }));
    const { app } = this.props;
    app._store.subscribe(this.handleChange);
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
      } else {
        // User is signed out
        // ...
      }
    });


    // aos.init();
  }
  componentDidUpdate(prevProps) {
    // const prevPropsAgentModel = _.find(prevProps.app._models, {
    //   namespace: "agent"
    // });
    // const currentPropsAgentModel = _.find(this.props.app._models, {
    //   namespace: "agent"
    // });
    // const prevPropsGlobalModel = _.find(prevProps.app._models, {
    //   namespace: "global"
    // });
    //
    // const currentPropsGlobalModel = _.find(this.props.app._models, {
    //   namespace: "global"
    // });
    // if (
    //   globalSelectors.getHomeShouldUpdatePhone(prevPropsGlobalModel.state) !==
    //     globalSelectors.getHomeShouldUpdatePhone(currentPropsGlobalModel.state)
    // ) {
    //   if (globalSelectors.getHomeShouldUpdatePhone(currentPropsGlobalModel.state)) {
    //   //  this.setState({ registerSuccessVisible: true });
    //   }
    // }
    //
    // if (
    //   agentSelectors.getAgentAccountStatus(prevPropsAgentModel.state) !==
    //   agentSelectors.getAgentAccountStatus(currentPropsAgentModel.state)
    // ) {
    //   if (agentSelectors.getAgentAccountStatus(currentPropsAgentModel.state)) {
    //     this.setState({ registerSuccessVisible: true });
    //   }
    // }
    // if (
    //   globalSelectors.getHomePopupItems(currentPropsGlobalModel.state) !==
    //   globalSelectors.getHomePopupItems(prevPropsGlobalModel.state)
    // ) {
    //   if (
    //     !_.isEmpty(
    //       globalSelectors.getHomePopupItems(currentPropsGlobalModel.state)
    //     )
    //   ) {
    //   }
    // }
  }

  routeChange = (isEnd = true, isSuccess = false) => {
    // const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
    const { app } = this.props;
    const State = app._store.getState();
    const authCheck = _.get(State, ["auth", "token"], "");
    const isSubscribe = _.get(
      State,
      ["profile", "profile", "attribute", "has_active_subscription"],
      ""
    );
    const inOneMinutes = 1 * 60 * 1000;
    const lastShownTime =
      parseInt(Cookies.get("lastSubscribleModalShowed"), 10) || 0;
    const currentTime = Date.now();
    if (isEnd) {
      this.setState({ loading: !isEnd });

      // ====== Temporary on hold this popoutmodal for subscription =====
      // if (currentTime - lastShownTime > inOneMinutes) {
      //   if (!_.isEmpty(authCheck) && !isSubscribe) {
      //     this.setState({ agentSubscriptionModal: true });
      //     Cookies.set('lastSubscribleModalShowed', currentTime, { expires: new Date(currentTime + inOneMinutes) });
      //   }
      // }
      // ====== End of temporary on hold this popoutmodal for subscription =====

      NProgress.done();
    } else {
      window.scrollTo({
        top: 0
      });
      NProgress.start();
      this.setState({ loading: !isEnd });
    }
  };

  handleRouteChange = url => {
    const pathname = url.split("?")[0];
    if (
      this.state.previousPathname &&
      this.state.previousPathname !== pathname
    ) {
    }
    this.setState({ previousPathname: pathname });
  };

  handleCloseRegisterSuccessModal = () => {
    this.setState({ registerSuccessVisible: false });
  };

  handleClose = () => {
    const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);

    Cookies.set(Constants.COOKIES_POPUP_DIALOG, "true", {
      expires: inFifteenMinutes
    });

    this.setState({ adsModalVisible: false, agentSubscriptionModal: false });
  };

  onClickMore = (number, event) => {
    event.preventDefault();
    event.stopPropagation();
    const { imageCurrentIndex, currentPopupData } = this.state;

    const currentPopUpObject = currentPopupData[imageCurrentIndex];
    const link = globalSelectors.getPopupCtaDataLink(currentPopUpObject);
    const target = globalSelectors.getPopupCtaTarget(currentPopUpObject);
    if (_.isEmpty(link)) {
      return;
    }

    window.open(link, target);
  };

  onClickMobileMore = (number, event) => {
    event.preventDefault();
    event.stopPropagation();
    const { imageCurrentIndex, currentPopupData } = this.state;

    const currentPopUpObject = currentPopupData[imageCurrentIndex];
    const link = globalSelectors.getPopupCtaDataLink(currentPopUpObject);
    const target = globalSelectors.getPopupCtaTarget(currentPopUpObject);
    if (_.isEmpty(link)) {
      return;
    }

    window.open(link, target);
  };

  onClickResend = () => {
    this.setState({
      isResend: false
    });
    this.onClickVerifyPhoneNumber();
  };

  onClickSubmitSignUp = () => {
    const { verificationData, phoneNumber, prefix, otp } = this.state;

    const { app } = this.props;

    const token = verificationSelectors.getVerificationToken(verificationData);

    app._store.dispatch(
      profileActions.postBindPhoneNumberRequest({
        prefix: prefix,
        number: phoneNumber,
        token: token,
        secret: otp
      })
    );
  };

  onClickVerifyPhoneNumber = () => {
    const { app } = this.props;
    const { phoneNumber } = this.state;

    if (_.isEmpty(phoneNumber)) {
      Toast.error("Please enter your phone number");
      return;
    }
    app._store.dispatch(
      authActions.postVerifyPhoneNumberRequestInRoot({
        type: "phone",
        value: `+60${phoneNumber}`
      })
    );
  };

  onChangeOTPText = value => {
    this.setState({
      otp: value
    });
  };
// === default Script ====
  // renderScript = () => {
  //   if(process.browser){
  //     if(window.innerWidth >= 600){
  //       return <><script
  //           type="text/javascript"
  //           dangerouslySetInnerHTML={{
  //             __html: ` <script
  //       async
  //       src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5038584083200880"
  //       crossOrigin="anonymous"
  //           />`,
  //           }}
  //       />
  //         <ins
  //             className="adsbygoogle hideAds"
  //             data-ad-format="fluid"
  //             data-ad-layout-key="-6j+cm+2g-1s+7r"
  //             data-ad-client="ca-pub-5038584083200880"
  //             data-ad-slot="6572123800"
  //         />
  //         <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
  //       </>
  //     }
  //     return false
  //   }
  //   return false
  // }

  renderScript = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth >= 600) {
        const scriptId = "adsbygoogle-script";

        if (!document.getElementById(scriptId)) {
          const script = document.createElement("script");
          script.id = scriptId;
          script.async = true;
          script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5038584083200880";
          script.crossOrigin = "anonymous";
          document.body.appendChild(script);
        }

        // Render the ad container
        return (
            <>
              <ins
                  className="adsbygoogle hideAds"
                  style={{ display: "block" }}
                  data-ad-format="fluid"
                  data-ad-layout-key="-6j+cm+2g-1s+7r"
                  data-ad-client="ca-pub-5038584083200880"
                  data-ad-slot="6572123800"
              />
              <script>
                {`(adsbygoogle = window.adsbygoogle || []).push({});`}
              </script>
            </>
        );
      }
      return null;
    }
    return null;
  };
  render() {
    const {
      previousPathname,
      loading,
      registerSuccessVisible,
      adsModalVisible,
      currentPopupData,
      imageStartIndex,
      imageCurrentIndex,
      phoneNumber,
      otp,
      verifyPhoneNumberModalVisible,
      errorPhoneReason,
      verifyPhoneLoading,
      verificationLoading,
      expiryTime,
      isResend,
      signUpLoading
    } = this.state;
    const width = process.browser && window && (window.innerWidth * 80) / 100;

    const imageHeight = 1080;
    const imageWidth = 1920;

    let height = 0;
    let imageWidthSize = 0;
    if (width) {
      if (width > 1200) {
        height = (1200 / imageWidth) * imageHeight;
        imageWidthSize = 1200;
      } else {
        height = (width / imageWidth) * imageHeight;
        imageWidthSize = width;
      }
    }

    const { history, data } = this.props;

    const formatedCurrentImage =
      currentPopupData &&
      currentPopupData.map(item => {
        const image = globalSelectors.getPopupImageHugeSrc(item);
        return image;
      });

    const currentMobileImage =
      currentPopupData &&
      currentPopupData.map(item => {
        const image = globalSelectors.getPopupImageMobileHugeSrc(item);
        const otherImage = globalSelectors.getPopupImageHugeSrc(item);

        return !_.isEmpty(image) ? image : otherImage;
      });

    const formatedCurrentLink =
      currentPopupData &&
      currentPopupData.map(item => {
        const link = globalSelectors.getPopupCtaDataLink(item);
        return link;
      });

    return (
      <div
        style={{
          position: "relative",
          overflow: loading ? "hidden" : "auto",
          height: loading ? "100vh" : "100%",
          maxHeight: loading ? "100vh" : "100%",
          minHeight: loading ? "100vh" : "100%"
        }}
        className="body-layout"
      >
        {loading ? (
          <div
            style={{
              width: "100vw",
              position: "relative"
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                //  flexGrow: 1,
                flexDirection: "column",
                height: "100vh",
                width: "100vw",
                alignItems: "center",
                justifyContent: "center",
                //  margin: "auto",
                backgroundColor: Color.backgroundPrimary,
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: 9999
              }}
            >
              <LoadingOverlay hideFont />
              {/*<ReactLoading type="balls" color={Color.primaryColor} />*/}
            </div>
          </div>
        ) : (
          false
        )}
        <AppContainer
          // navigationToCalculator={this.navigationToCalculator}
          previousPathname={previousPathname}
          loading={loading}
          {...this.props}
        />
        <Container>
          <Modal
            // sx={style2}
            open={this.state.adsModalVisible}
            onClose={this.handleClose}
          >
            <Box
              id="ads_pop"
              sx={style}
              style={{ height: height ? height : 0 }}
            >
              <Grid
                container
                // style={{
                //   backgroundImage: 'url(' + image + ')',
                //   backgroundSize: 'contain',
                //   backgroundRepeat: 'no-repeat',
                //   height: '100%',
                //   position: 'relative'
                // }}
              >
                <Grid item md={12} xs={12}>
                  <Hidden mdUp>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // padding: '40px 20px',
                        height: "100%",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        position: "relative",
                        borderRadius: "15px"
                      }}
                    >
                      <SimpleImageSlider
                        width={imageWidthSize}
                        height={height}
                        images={currentMobileImage}
                        showNavs={true}
                        loop={false}
                        autoPlay={false}
                        startIndex={imageCurrentIndex}
                        onClickNav={toRight => {
                          toRight
                            ? this.setState({
                                imageCurrentIndex: imageCurrentIndex + 1
                              })
                            : this.setState({
                                imageCurrentIndex: imageCurrentIndex - 1
                              });
                        }}
                        onClick={(number, e) => {
                          this.onClickMore(number, e);
                        }}
                      />
                    </div>
                  </Hidden>
                  <Hidden smDown>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // padding: '40px 20px',
                        height: "100%",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        position: "relative",
                        borderRadius: "15px"
                      }}
                      //onClick={e => this.onClickMore(e)}
                    >
                      <SimpleImageSlider
                        width={imageWidthSize}
                        height={height}
                        images={formatedCurrentImage}
                        showNavs={true}
                        loop={false}
                        autoPlay={false}
                        startIndex={imageCurrentIndex}
                        onClickNav={toRight => {
                          toRight
                            ? this.setState({
                                imageCurrentIndex: imageCurrentIndex + 1
                              })
                            : this.setState({
                                imageCurrentIndex: imageCurrentIndex - 1
                              });
                        }}
                        onClick={(number, e) => {
                          this.onClickMore(number, e);
                        }}
                      />
                    </div>
                  </Hidden>
                </Grid>
                <CloseButtonContainer onClick={() => this.handleClose()}>
                  <CloseIcon
                    style={{ color: "white", fontSize: isMobile ? 20 : 40 }}
                  />
                </CloseButtonContainer>
              </Grid>
            </Box>
          </Modal>
          {/* ======== For agent subscription pop out modal ========== */}
          <Modal
            open={this.state.agentSubscriptionModal}
            onClose={this.handleClose}
          >
            <Box
              id="ads_pop"
              sx={styleModalSubscription}
              style={{ height: height ? height : 0 }}
            >
              <p> Happy Merdeka!</p>
              <p> PropertyGenie are giveaway 200 FREE Listings between </p>
              <p>
                {" "}
                1st August until 31 August to celebrate Malaysia National Days!
              </p>
              <p> *Valid only 31-Days provided for this period* </p>

              <CloseButtonContainer onClick={() => this.handleClose()}>
                <CloseIcon
                  style={{ color: "white", fontSize: isMobile ? 20 : 40 }}
                />
              </CloseButtonContainer>
            </Box>
          </Modal>
          <VerifyPhoneNumberModal
            handleClose={this.onCloseVerifyPhoneNumberModal}
            onChangePhoneNumberText={this.onChangePhoneNumberText}
            phoneNumber={phoneNumber}
            visible={verifyPhoneNumberModalVisible}
            onClickVerifyPhoneNumber={this.onClickVerifyPhoneNumber}
            errorPhoneReason={errorPhoneReason}
            verifyPhoneLoading={verifyPhoneLoading || verificationLoading}
            expiryTime={expiryTime}
            isResend={isResend}
            onClickResend={this.onClickResend}
            onClickSubmitSignUp={this.onClickSubmitSignUp}
            signUpLoading={signUpLoading}
            otp={otp}
            onChangeOTPText={this.onChangeOTPText}
            //isBindPhone={true}
          />
        </Container>
        { this.renderScript()}
      </div>
    );
  }
}

//
// const SetTransform = createTransform(
//   // transform state on its way to being serialized and persisted.
//   (inboundState, key) => {
//     // convert mySet to an Array.
//     return { ...inboundState };
//   },
//   // transform state being rehydrated
//   (outboundState, key) => {
//     if (outboundState) {
//       outboundState.mergeDeep = x => x;
//     }
//     return Immutable(outboundState);
//   }
// );
const saveSubsetWhiteListGlobal = createWhitelistFilter("global", ["language"]);

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    return { ...inboundState };
  }, // transform state being rehydrated
  (outboundState, key) => {
    if (outboundState) {
      outboundState.mergeDeep = x => x;
    }
    return Immutable(outboundState);
  }
  // define which reducers this transform gets called for.
);

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    }
  };
};
const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();

const persistConfig = {
  key: "root",
  storage: storage,
  transforms: [SetTransform, saveSubsetWhiteListGlobal],
  whitelist: ["global"]
};
//staging
// const firebaseConfig = Helper.isProduction
//   ? {
//       apiKey: "AIzaSyCQyoDHZO6fSgYNX12XM9YBSojUthea5RA",
//       authDomain: "property-genie-uat.firebaseapp.com",
//       projectId: "property-genie-uat",
//       storageBucket: "property-genie-uat.appspot.com",
//       messagingSenderId: "837948259344",
//       appId: "1:837948259344:web:adc75c889e9449effe9e95",
//       measurementId: "G-0GYBN0E87S"
//     }
//   : {
//       apiKey: "AIzaSyCQyoDHZO6fSgYNX12XM9YBSojUthea5RA",
//       authDomain: "property-genie-uat.firebaseapp.com",
//       projectId: "property-genie-uat",
//       storageBucket: "property-genie-uat.appspot.com",
//       messagingSenderId: "837948259344",
//       appId: "1:837948259344:web:adc75c889e9449effe9e95",
//       measurementId: "G-0GYBN0E87S"
//     };

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID
};

export const firebaseApp = process.browser
  ? initializeApp(firebaseConfig)
  : false;
const analytics = process.browser ? getAnalytics(firebaseApp) : false;
const app = dva({
  onReducer: rootReducer => persistReducer(persistConfig, rootReducer)
});

registerModels(app);

app.router(data => <MyApp {...data} />);

const ReactApp = app.start();

persistStore(app._store, null, () =>
  app._store.dispatch(globalActions.setRehydrateCompleted())
);

process.browser
  ? app._store.dispatch(commonActions.getCommonDataRequest())
  : false;

// process.browser ? app._store.dispatch(globalActions.getHomeDataRequest()) : false;
process.browser
  ? app._store.dispatch(agentActions.getAgentCommonStateRequest())
  : false;
process.browser
  ? app._store.dispatch(agentActions.getAgentCommonCityRequest())
  : false;
process.browser
  ? app._store.dispatch(agentActions.getAgentCommonDataRequest())
  : false;

const token = process.browser ? localStorage.getItem(Constants.PG_TOKEN) : null;

const agentToken = process.browser
  ? localStorage.getItem(Constants.PG_AGENT_TOKEN)
  : null;

if (!_.isEmpty(token)) {
  app._store.dispatch(profileActions.getMeDataRequest());
  Cookies.set(Constants.PG_TOKEN, token, {
    expires: 7
  });
}
if (!_.isEmpty(agentToken)) {
  Cookies.set(Constants.PG_AGENT_TOKEN, agentToken, {
    expires: 7
  });
}

export default appWithTranslation(ReactApp);
