import React, { PureComponent } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { withTheme } from "styled-components";

const StyledHover = styled(Typography)`
  &:hover {
    cursor: pointer;
    color: ${props => props.hovercolor} !important;
  }
`;

const StyledTypograph = styled(Typography)`

`;

class CustomFont extends PureComponent {
  getSize = (size = "normal") => {
    switch (size) {
      case "xsmall":
        return "0.8rem";
      case "small":
        return "0.9rem";
      case "normal":
        return "1rem";
      case "large":
        return "1.2rem";
      case "tlarge":
        return "1.3rem";
      case "xlarge":
        return "1.5rem";
      case "H1":
        return "1.875rem";
      case "xalarge":
        return "2rem";
      case "xxlarge":
        return "2.2rem";
        case "xxxlarge":
        return "3.0rem";
    }
  };

  render() {
    const { size, styles, children, hover = false,variant,hoverColor='#2c2c2c', onClick} = this.props;

    return hover ? (
      <StyledHover
        style={{ color: '#2c2c2c', fontSize: this.getSize(size), ...styles }}
        variant={variant}
        hovercolor={hoverColor}
        variantMapping={{"body1":"p"}}
        onClick={onClick}
      >
        {children}
      </StyledHover>
    ) : (
      <Typography
        style={{ color: "#2c2c2c", fontSize: this.getSize(size), ...styles  }}
        variant={variant}
        variantMapping={{"body1":"p"}}
        onClick={onClick}
      >
        {children}
      </Typography>
    );
  }
}
CustomFont.defaultProps = {
  theme: {
    main: '#2c2c2c'
  }
};
export default withTheme(CustomFont);
