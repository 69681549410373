export const getProjectListRequest = (route, page) => ({
  type: "project/GET_PROJECT_LIST_REQUEST",
  route,
  page
});

export const getProjectListSuccess = (route, page, data, pagination) => ({
  type: "GET_PROJECT_LIST_SUCCESS",
  route,
  page,
  data,
  pagination
});

export const getProjectListFailure = message => ({
  type: "GET_PROJECT_LIST_FAILURE",
  message
});

export const putProjectDetailWishlistRequest = (id, route) => ({
  type: "project/PUT_PROJECT_DETAIL_WISHLIST_REQUEST",
  id,
  route
});

export const putProjectDetailWishlistSuccess = (id, data, route) => ({
  type: "PUT_PROJECT_DETAIL_WISHLIST_SUCCESS",
  id,
  data,
  route
});

export const putProjectDetailWishlistFailure = messages => ({
  type: "PUT_PROJECT_DETAIL_WISHLIST_FAILURE",
  messages
});
