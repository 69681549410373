import Immutable from "seamless-immutable";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as verificationActions from "../actions/verification";
import _ from "lodash"
import Toast from "../utils/Toast"

export default api => {
  function* watcherPostVerificationRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postVerification, postData);
      const {messages,item} = response.data
      if(!_.isEmpty(messages[0].text)){
        Toast.success(messages[0].text)

      }
      yield put(verificationActions.postVerificationSuccess(item));
    } catch (error) {
      yield call(httpErrorHelpers, error, verificationActions.postVerificationFailure, effects);
    }
  }

  function* watcherPostVerificationRequestInRoot({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postVerification, postData);
      const {messages,item} = response.data
      if(!_.isEmpty(messages[0].text)){
        Toast.success(messages[0].text)

      }
      yield put(verificationActions.postVerificationSuccessInRoot(item));
    } catch (error) {
      yield call(httpErrorHelpers, error, verificationActions.postVerificationFailureInRoot, effects);
    }
  }

  function* watcherPostVerificationChangePhoneRequest({postData},effects){
    const { call, put } = effects;
    try {
      const response = yield call(api.postVerification, postData);
      const {messages,item} = response.data;
      if(!_.isEmpty(messages[0].text)){
        Toast.success(messages[0].text)

      }
      yield put(verificationActions.postVerificationChangePhoneSuccess(item));
    } catch (error) {
      yield call(httpErrorHelpers, error, verificationActions.postVerificationChangePhoneFailure, effects);
    }
  }
  function* watcherPostVerificationChangeEmailRequest({postData},effects){
    const { call, put } = effects;
    try {
      const response = yield call(api.postVerification, postData);
      const {messages,item} = response.data
      if(!_.isEmpty(messages[0].text)){
        Toast.success(messages[0].text)

      }
      yield put(verificationActions.postVerificationChangeEmailSuccess(item));
    } catch (error) {
      yield call(httpErrorHelpers, error, verificationActions.postVerificationChangeEmailFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    verification: null
  });

  return {
    namespace: "verification",
    state: INITIAL_STATE,
    reducers: {
      POST_VERIFICATION_REQUEST(state, action) {
        return state.setIn(["verification", "loading"], true).setIn(["verification", "status"], false);
      },
      POST_VERIFICATION_SUCCESS(state, action) {
        return state
          .setIn(["verification", "loading"], false)
          .setIn(["verification", "status"], true)
          .setIn(["verification","data"], action.data);
      },
      POST_VERIFICATION_FAILURE(state, action) {
        return state.setIn(["verification", "loading"], false).setIn(["verification", "status"], false);
      },
      POST_VERIFICATION_REQUEST_IN_ROOT(state, action) {
        return state.setIn(["verificationInRoot", "loading"], true).setIn(["verificationInRoot", "status"], false);
      },
      POST_VERIFICATION_SUCCESS_IN_ROOT(state, action) {
        return state
            .setIn(["verificationInRoot", "loading"], false)
            .setIn(["verificationInRoot", "status"], true)
            .setIn(["verificationInRoot","data"], action.data);
      },
      POST_VERIFICATION_FAILURE_IN_ROOT(state, action) {
        return state.setIn(["verificationInRoot", "loading"], false).setIn(["verificationInRoot", "status"], false);
      },
      POST_VERIFICATION_CHANGE_EMAIL_REQUEST(state,action){
        return state.setIn(['verificationChangeEmail','loading'],true).setIn(['verificationChangeEmail','status'],false)
      },
      POST_VERIFICATION_CHANGE_EMAIL_SUCCESS(state,action){
        return state.setIn(['verificationChangeEmail','loading'],false).setIn(['verificationChangeEmail','status'],true).setIn(['verificationChangeEmail','data'],action.data)
      },
      POST_VERIFICATION_CHANGE_EMAIL_FAILURE(state,action){
        return state.setIn(['verificationChangeEmail','loading'],false).setIn(['verificationChangeEmail','status'],false)
      },
      POST_VERIFICATION_CHANGE_PHONE_REQUEST(state,action){
        return state.setIn(['verificationChangePhone','loading'],true).setIn(['verificationChangePhone','status'],false)
      },
      POST_VERIFICATION_CHANGE_PHONE_SUCCESS(state,action){
        return state.setIn(['verificationChangePhone','loading'],false).setIn(['verificationChangePhone','status'],true).setIn(['verificationChangePhone','data'],action.data)
      },
      POST_VERIFICATION_CHANGE_PHONE_FAILURE(state,action){
        return state.setIn(['verificationChangePhone','loading'],false).setIn(['verificationChangePhone','status'],false)
      },
    },
    effects: {
      POST_VERIFICATION_REQUEST: [watcherPostVerificationRequest],
      POST_VERIFICATION_REQUEST_IN_ROOT: [watcherPostVerificationRequestInRoot],
      POST_VERIFICATION_CHANGE_PHONE_REQUEST:[watcherPostVerificationChangePhoneRequest],
      POST_VERIFICATION_CHANGE_EMAIL_REQUEST:[watcherPostVerificationChangeEmailRequest],
    }
  };
};
