import React, { Component } from "react";
import styled from "styled-components";
import _ from "lodash"
import { withTheme } from "styled-components";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Color as Colors } from "../../../../../src/utils/Color";
import CustomFont from "../../../../../components/CustomFont";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import NumberFormat from "react-number-format";

const TextFieldContainer = styled(TextField)`
  background-color: white;
  font-size: 1.4rem;
  font-weight: 600;
  width: 100%;
  & .MuiInputLabel-root {
    margin-top: 5px;
  }

  & .Mui-focused {
    color: white;
  }
  & .MuiFilledInput-root {
    background-color: white;
    font-size: 1.4rem;
    font-weight: 600;

    &:before {
      border-bottom: 0 !important;
    }
    &:hover {
      outline: 0 !important;
    }
    &:focus {
      outline: 0 !important;
    }
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: rgba(0, 0, 0, 0.23);
      color: #2c2c2c;
      font-weight: 600;
    }
    & :hover fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
    & .Mui-focused fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
  & .MuiOutlinedInput-input {
    font-size: 1.4rem;
    color: #2c2c2c;
    font-weight: 600;
  }
  & .MuiOutlinedInput-input::placeholder {
    font-size: 1.4rem;
    font-weight: 600;
    color: red !important;
  }
`;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      isAllowed={values => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || floatValue;
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
}

class CustomCalculator extends Component {
  onChangeText = e => {
    const { onChangeText } = this.props;

    onChangeText && onChangeText(e);
  };

  render() {
    const { title, titleSub, desc, label, value } = this.props;
    return (
      <div style={{ marginTop: "1.2rem" }}>
        <CustomFont
          variant="caption"
          size="tlarge"
          styles={{
            color: Colors.primaryColor,
            fontWeight: "600"
          }}
        >
          {title}
          {/*<span*/}
            {/*style={{*/}
              {/*color: "#2c2c2c",*/}
              {/*fontSize: "1rem",*/}
              {/*fontWeight: "normal"*/}
            {/*}}*/}
          {/*>*/}
            {/*/per month*/}
          {/*</span>*/}
        </CustomFont>
        <CustomFont
          size="normal"
          styles={{
            color: Colors.blackColor,
            fontWeight: "normal",
            marginTop: "1rem"
          }}
        >
          {desc}
        </CustomFont>
        <Grid container spacing={2} style={{ marginTop: "1.5rem" }}>
          <Grid item md={12} style={{ width: "100%" }}>
            <TextFieldContainer
              label={label}
              value={value}
              onChange={this.onChangeText}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
              InputLabelProps={{
                  shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTheme(CustomCalculator);
