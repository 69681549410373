import _ from "lodash";

export const getGlobalLanguage = state => (state && state.language ? state.language : "en");
export const getIsRehydrationCompleted = state => _.get(state,['isRehydrationCompleted'],false)
export const getHomeData = state => _.get(state,['home','data'],null)
export const getHomeLoading = state => _.get(state,['home','loading'],false)
export const getHomeShouldUpdatePhone = state => _.get(state,['home','shouldUpdatePhone'],false)

//home
export const getHomeProjectLatestItems = home => _.get(home,['project','latest','items'],[])
export const getHomeGuidesLatestItems = home => _.get(home,['guides','latest','items'],[])
export const getHomePropertyHandpickItems = home => _.get(home,['property','handpick','items'],[])
export const getHomeNewsLatestItems = home => _.get(home,['news','latest','items'],[])
export const getHomePopupItems = home => _.get(home,['popup','items'],[])


//popuo
export const getPopupCtaTarget = popup => _.get(popup,['cta','data','target'],"")
export const getPopupCtaType = popup => _.get(popup,['cta','type'],"")
export const getPopupCtaDataLink = popup => _.get(popup,['cta','data','link'],"")
export const getPopupTitle = popup => _.get(popup,['title'],"")
export const getPopupImageHugeSrc = popup => _.get(popup,['huge','src'],"")
export const getPopupImageMobileHugeSrc = popup => _.get(popup,['mobile','huge','src'],"")
