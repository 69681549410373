import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as propertyActions from "../actions/property";
import _ from "lodash";
import * as searchActions from "../actions/search";
import { putWishlistSuccess } from "../actions/wishlist";
import * as wishlistActions from "../actions/wishlist";
import Toast from "../utils/Toast";
import * as agentAction from "../actions/agent";
import * as agentSelectors from "../selectors/agent";
import * as propertySelectors from "../selectors/property";
import { getUserPropertyListingID } from "../selectors/property";
import * as userActions from '../actions/user'
import { getUserPropertyDataFilterFailure } from '../actions/property'

export default api => {
  function* watcherGetPropertyListRequest(
    { route = null, page = 1, filterParam = null },
    effects
  ) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getPropertyList, page);

      const { items, _meta } = response.data;

      yield put(
        propertyActions.getPropertyListSuccess(route, page, items, _meta)
      );
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.getPropertyDetailFailure,
        effects
      );
    }
  }

  function* watcherGetPropertyDetailRequest({ id }, effects) {
    const { call, put, select } = effects;

    try {
      const response = yield call(api.getPropertyDetail, id);

      yield put(propertyActions.getPropertyDetailSuccess(id, response.data));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.getPropertyDetailFailure,
        effects
      );
    }
  }

  function* watcherPutPropertyDetailWishlistRequest({ id, route }, effects) {
    const { call, put, select } = effects;

    try {
      const response = yield call(api.putPropertyDetailWishlist, id);

      const { item, messages } = response.data;

      yield put(
        propertyActions.putPropertyDetailWishlistSuccess(id, item, route)
      );
      yield put(
        searchActions.putPropertyDetailWishlistSuccess(id, item, route)
      );
      yield put(wishlistActions.putWishlistSuccess(id, item));
      if (!_.isEmpty(messages[0])) {
        Toast.success(messages[0].text);
      }
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.putPropertyDetailWishlistFailure,
        effects
      );
    }
  }

  //PropertyUser
  function* watcherPutUserPropertyListingDataRequest({ id, putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putUserPropertyListingData, id, putData);

      const { item } = response.data;

      yield put(propertyActions.putUserPropertyListingDataSuccess(id, item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.putUserPropertyListingDataFailure,
        effects
      );
    }
  }

  function* watcherPutAgentListingDetailImageRequest({ id, putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putAgentListingDetailImage, id, putData);
      const { item } = response.data;

      yield put(agentAction.putAgentListingDetailImageSuccess(item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        agentAction.putAgentListingDetailImageFailure,
        effects
      );
    }
  }
  function* watcherPostUserPropertyProjectCreateRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postUserPropertyProjectCreate, postData);

      const { item } = response.data;

      yield put(propertyActions.postUserPropertyProjectCreateSuccess(item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.postUserPropertyProjectCreateFailure,
        effects
      );
    }
  }

  function* watcherPostUserPropertyListingRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postUserPropertyListing, postData);

      const { item } = response.data;

      const id = propertySelectors.getUserPropertyListingID(item);

      yield put(propertyActions.postUserPropertyListingSuccess(id, item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.postUserPropertyListingFailure,
        effects
      );
    }
  }

  function* watcherPostUserPropertySearchProjectRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postUserPropertySearchProject, postData);

      const { items } = response.data;

      yield put(propertyActions.postUserPropertySearchProjectSuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.postUserPropertySearchProjectFailure,
        effects
      );
    }
  }

  function* watcherPostUserPropertySearchStateRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postUserPropertySearchState, postData);

      const { items } = response.data;

      yield put(propertyActions.postUserPropertySearchStateSuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.postUserPropertySearchStateFailure,
        effects
      );
    }
  }

  function* watcherPostUserPropertySearchCityRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postUserPropertySearchCity, postData);

      const { items } = response.data;

      yield put(propertyActions.postUserPropertySearchCitySuccess(items));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.postUserPropertySearchCityFailure,
        effects
      );
    }
  }

  function* watcherGetUserPropertyListingDetailRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getUserPropertyListingDetail, id);

      yield put(
        propertyActions.getUserPropertyListingDataSuccess(id, response.data)
      );
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.getUserPropertyListingDataFailure,
        effects
      );
    }
  }

  function* watcherPutUserPropertyListingDetailImageRequest({ id, putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putUserPropertyListingDetailImage, id,putData);
      const { item } = response.data;

      yield put(propertyActions.putUserPropertyListingDetailImageSuccess(item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.putUserPropertyListingDetailImageFailure,
        effects,
      );
    }
  }

  function* watcherPostUserPropertyListingDataImageDefaultRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postUserPropertyListingDataImageDefault, id);

      const { item } = response.data;
      yield put(propertyActions.postUserPropertyListingDataImageDefaultSuccess(id, id));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.postUserPropertyListingDataImageDefaultFailure,
        effects,
        false,
        false,
        id
      );
    }
  }

  function* watcherDeleteUserPropertyListingImageRequest({ id, postData, randomID }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.deleteUserPropertyListingImageData, id);
      const { item } = response.data;

      yield put(propertyActions.deleteUserPropertyListingImageDataSuccess(id));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.deleteUserPropertyListingImageDataFailure,
        effects,
        false,
        false,
        id
      );
    }
  }

  function* watcherPostUserPropertyListingDataImageRequest({ id, postData, randomID }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postUserPropertyListingDataImage, id, postData);

      const { item } = response.data;

      yield put(propertyActions.postUserPropertyListingDataImageSuccess(id, item, randomID));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.postUserPropertyListingDataImageFailure,
        effects,
        false,
        false,
        randomID
      );
    }
  }

  function* watcherPutUserPropertyListingPriceRequest({ id, putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putUserPropertyListingPrice, id,putData);
      const { item } = response.data;

      yield put(propertyActions.putUserPropertyListingPriceSuccess(id, item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.putUserPropertyListingPriceFailure,
        effects,
      );
    }
  }


  function* watcherPutUserPropertyStatusRequest({ id, putData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.putUserPropertyStatus, id,putData);
      const { item } = response.data;

      yield put(propertyActions.putUserPropertyStatusSuccess(id, item));
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.putUserPropertyStatusFailure,
        effects,
      );
    }
  }


  function* watcherGetUserPropertyDataFilterRequest({ page,propertyStatus }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getUserPropertyDataFilter, page,propertyStatus);
      const { items, _meta } = response.data;
      yield put(
        propertyActions.getUserPropertyDataFilterSuccess(page,propertyStatus,items,_meta)
      );
    } catch (error) {
      yield call(
        httpErrorHelpers,
        error,
        propertyActions.getUserPropertyDataFilterFailure,
        effects
      );
    }
  }

  function* watcherPutPropertyDetailStatusRequest({ id, putData }, effects) {
    const { call, put, select } = effects;

    try {
      const response = yield call(api.putPropertyDetailStatus, id,putData);

      const { item,messages } = response.data;

      yield put(propertyActions.putPropertyDetailStatusSuccess(id, item));
      if (!_.isEmpty(messages[0])) {
        Toast.success(messages[0].text);
      }
    } catch (error) {
      yield call(httpErrorHelpers, error, propertyActions.putPropertyDetailStatusFailure, effects);
    }
  }

  function* watcherGetMyPropertyListingRequest({page},effects){
    const { call, put, select } = effects;

    try {
      const response = yield call(api.getUserPropertyListing,page);

      const { items,_meta } = response.data;

      yield put(propertyActions.getMyPropertyListingSuccess(page,items,_meta));
    } catch (error) {
      yield call(httpErrorHelpers, error, propertyActions.getMyPropertyListingFailure, effects);
    }
  }

  function* watcherGetPropertyMeStatisticRequest({},effects){
    const { call, put, select } = effects;

    try {
      const response = yield call(api.getPropertyMeStatistic);

      const { items } = response.data;

      yield put(propertyActions.getPropertyMeStatisticSuccess(items));
    } catch (error) {
      yield call(httpErrorHelpers, error, propertyActions.getPropertyMeStatisticFailure, effects);
    }
  }


  const INITIAL_STATE = Immutable({
    commonData: null
  });

  return {
    namespace: "property",
    state: INITIAL_STATE,
    reducers: {
      GET_PROPERTY_LIST_REQUEST(state, action) {
        return state.setIn(["propertyList", "loading"], true);
      },
      GET_PROPERTY_LIST_SUCCESS(state, action) {
        return state
          .setIn(["propertyList", "loading"], false)
          .setIn(
            ["propertyList", action.route, "items", action.page],
            action.data
          )
          .setIn(
            ["propertyList", action.route, "pagination"],
            action.pagination
          );
      },
      GET_PROPERTY_LIST_FAILURE(state, action) {
        return state.setIn(["propertyList", "loading"], false);
      },
      GET_PROPERTY_DETAIL_REQUEST(state, action) {
        return state.setIn(["propertyDetail", "loading"], true);
      },
      GET_PROPERTY_DETAIL_SUCCESS(state, action) {
        return state
          .setIn(["propertyDetail", action.id], action.data)
          .setIn(["propertyDetail", "loading"], false);
      },
      GET_PROPERTY_DETAIL_FAILURE(state) {
        return state.setIn(["propertyDetail", "loading"], false);
      },
      PUT_PROPERTY_DETAIL_WISHLIST_REQUEST(state) {
        return state
          .setIn(["putPropertyWishlist", "loading"], true)
          .setIn(["toggleWishlistStatus"], false);
      },
      PUT_PROPERTY_DETAIL_WISHLIST_SUCCESS(state, action) {
        return state
          .setIn(["putPropertyWishlist", "loading"], false)
          .setIn(["toggleWishlistStatus"], true);
      },
      PUT_PROPERTY_DETAIL_WISHLIST_FAILURE(state) {
        return state
          .setIn(["putPropertyWishlist", "loading"], false)
          .setIn(["toggleWishlistStatus"], false);
      },

      PUT_USER_PROPERTY_LISTING_DATA_SUCCESS(state, action) {
        return state
          .setIn(["putUserPropertyListing", "loading"], false)
          .setIn(["userPropertyListingData", "items", action.id], action.data)
          .setIn(["putUserPropertyListing", "status"], true);
      },
      PUT_USER_PROPERTY_LISTING_DATA_REQUEST(state) {
        return state
          .setIn(["putUserPropertyListing", "loading"], true)
          .setIn(["putUserPropertyListing", "status"], false);
      },
      PUT_USER_PROPERTY_LISTING_DATA_FAILURE(state) {
        return state
          .setIn(["putUserPropertyListing", "loading"], false)
          .setIn(["putUserPropertyListing", "status"], false);
      },
      //Create
      POST_USER_PROPERTY_LISTING_REQUEST(state) {
        return state
          .setIn(["postUserPropertyListing", "loading"], true)
          .setIn(["postUserPropertyListing", "status"], false);
      },
      POST_USER_PROPERTY_LISTING_SUCCESS(state, action) {
        return state
          .setIn(["postUserPropertyListing", "loading"], false)
          .setIn(["postUserPropertyListing", "status"], true)
          .setIn(["postUserPropertyListing", "item"], action.data)
          .setIn(["userPropertyListingData", "items", action.id], action.data);
      },
      POST_USER_PROPERTY_LISTING_FAILURE(state) {
        return state
          .setIn(["postUserPropertyListing", "loading"], false)
          .setIn(["postUserPropertyListing", "status"], false);
      },
      //
      POST_USER_PROPERTY_PROJECT_CREATE_REQUEST(state) {
        return state
          .setIn(["postUserPropertyProjectCreate", "loading"], true)
          .setIn(["postUserPropertyProjectCreate", "status"], false);
      },
      POST_USER_PROPERTY_PROJECT_CREATE_SUCCESS(state, action) {
        return state
          .setIn(["postUserPropertyProjectCreate", "loading"], false)
          .setIn(["postUserPropertyProjectCreate", "status"], true)
          .setIn(["postUserPropertyProjectCreate", "items"], action.data);
      },
      POST_USER_PROPERTY_PROJECT_CREATE_FAILURE(state) {
        return state
          .setIn(["postUserPropertyProjectCreate", "loading"], false)
          .setIn(["postUserPropertyProjectCreate", "status"], false);
      },
      POST_USER_PROPERTY_SEARCH_PROJECT_REQUEST(state) {
        return state.setIn(["userPropertySearchProject", "loading"], true);
      },
      POST_USER_PROPERTY_SEARCH_PROJECT_SUCCESS(state, action) {
        return state
          .setIn(["userPropertySearchProject", "loading"], false)
          .setIn(["userPropertySearchProject", "items"], action.data);
      },
      POST_USER_PROPERTY_SEARCH_PROJECT_FAILURE(state) {
        return state.setIn(["userPropertySearchProject", "loading"], false);
      },
      POST_USER_PROPERTY_SEARCH_STATE_REQUEST(state) {
        return state.setIn(["postUserPropertySearchState", "loading"], true);
      },
      POST_USER_PROPERTY_SEARCH_STATE_SUCCESS(state, action) {
        return state
          .setIn(["postUserPropertySearchState", "loading"], false)
          .setIn(["postUserPropertySearchState", "items"], action.data);
      },
      POST_USER_PROPERTY_SEARCH_STATE_FAILURE(state) {
        return state.setIn(["postUserPropertySearchState", "loading"], false);
      },
      POST_USER_PROPERTY_SEARCH_CITY_REQUEST(state) {
        return state.setIn(["postUserPropertySearchCity", "loading"], true);
      },
      POST_USER_PROPERTY_SEARCH_CITY_SUCCESS(state, action) {
        return state
          .setIn(["postUserPropertySearchCity", "loading"], false)
          .setIn(["postUserPropertySearchCity", "items"], action.data);
      },
      POST_USER_PROPERTY_SEARCH_CITY_FAILURE(state) {
        return state.setIn(["postUserPropertySearchCity", "loading"], false);
      },
      SET_CURRENT_USER_PROPERTY_PROJECT_ID(state, action) {
        return state.setIn(["currentProjectID"], action.id);
      },
      GET_USER_PROPERTY_LISTING_DETAIL_REQUEST(state) {
        return state.setIn(["userPropertyListingDetail", "loading"], true);
      },
      GET_USER_PROPERTY_LISTING_DETAIL_SUCCESS(state, action) {
        return state
          .setIn(["userPropertyListingDetail", "items", action.id], action.data)
          .setIn(["userPropertyListingDetail", "loading"], false);
      },
      GET_USER_PROPERTY_LISTING_DETAIL_FAILURE(state, action) {
        return state.setIn(["userPropertyListingDetail", "loading"], false);
      },
      POST_USER_PROPERTY_LISTING_DATA_IMAGE_DEFAULT_REQUEST(state, action) {
        return state
          .setIn(["userPropertyListingImageDefault", "loading", action.id], true)
          .setIn(["userPropertyListingImageDefault", "image"], null)
          .setIn(["userPropertyListingImageDefault", "status"], false);
      },
      POST_USER_PROPERTY_LISTING_DATA_IMAGE_DEFAULT_SUCCESS(state, action) {
        return state
          .setIn(["userPropertyListingImageDefault", "loading", action.id], false)
          .setIn(["userPropertyListingImageDefault", "image"], action.id)
          .setIn(["userPropertyListingImageDefault", "status"], true);
      },
      POST_USER_PROPERTY_LISTING_DATA_IMAGE_DEFAULT_FAILURE(state, action) {
        return state
          .setIn(["userPropertyListingImageDefault", "loading", action.id], false)
          .setIn(["userPropertyListingImageDefault", "image"], null)
          .setIn(["userPropertyListingImageDefault", "status"], false);
      },
      DELETE_USER_PROPERTY_LISTING_IMAGE_DATA_REQUEST(state, action) {
        return state.setIn(["deleteListingImage", "loading", action.id], true);
      },
      DELETE_USER_PROPERTY_LISTING_IMAGE_DATA_SUCCESS(state, action) {
        return state
          .setIn(["deleteListingImage", "loading", action.id], false)
          .setIn(["deleteListingImage", "image", action.id], action.id);
      },
      DELETE_USER_PROPERTY_LISTING_IMAGE_DATA_FAILURE(state, action) {
        return state.setIn(["deleteListingImage", "loading", action.id], false);
      },
      POST_USER_PROPERTY_LISTING_DATA_IMAGE_REQUEST(state, action) {
        return state
          .setIn(["userPropertyListingImage", "loading", action.randomID], true)
          .setIn(["userPropertyListingImage", "status", action.randomID], false);
      },
      POST_USER_PROPERTY_LISTING_DATA_IMAGE_SUCCESS(state, action) {
        return state
          .setIn(["userPropertyListingImage", "loading", action.randomID], false)
          .setIn(["userPropertyListingImage", "image", action.randomID], action.data)
          .setIn(["userPropertyListingImage", "status", action.randomID], true);
      },
      POST_USER_PROPERTY_LISTING_DATA_IMAGE_FAILURE(state, action) {
        return state
          .setIn(["userPropertyListingImage", "loading", action.randomID], false)
          .setIn(["userPropertyListingImage", "status", action.randomID], false);
      },
      //For price
      PUT_USER_PROPERTY_LISTING_PRICE_REQUEST(state) {
        return state
          .setIn(["putUserPropertyListingPrice", "loading"], true)
          .setIn(["putUserPropertyListingPrice", "status"], false);
      },
      PUT_USER_PROPERTY_LISTING_PRICE_SUCCESS(state, action) {
        return state
          .setIn(["putUserPropertyListingPrice", "loading"], false)
          .setIn(["userPropertyListingDataPrice", "items", action.id], action.data)
          .setIn(["putUserPropertyListingPrice", "status"], true);
      },
      PUT_USER_PROPERTY_LISTING_PRICE_FAILURE(state) {
        return state
          .setIn(["putUserPropertyListingPrice", "loading"], false)
          .setIn(["putUserPropertyListingPrice", "status"], false);
      },
      //For Status
      PUT_USER_PROPERTY_STATUS_REQUEST(state) {
        return state
          .setIn(["putUserPropertyStatus", "loading"], true)
          .setIn(["putUserPropertyStatus", "status"], false);
      },
      PUT_USER_PROPERTY_STATUS_SUCCESS(state, action) {
        return state
          .setIn(["putUserPropertyStatus", "loading"], false)
          .setIn(["putUserPropertyStatus", "status"], true);
      },
      PUT_USER_PROPERTY_STATUS_FAILURE(state) {
        return state
          .setIn(["putUserPropertyStatus", "loading"], false)
          .setIn(["putUserPropertyStatus", "status"], false);
      },
      // Data Filter
      GET_USER_PROPERTY_DATA_FILTER_REQUEST(state, action) {
        return state
          .setIn(["userPropertyData", "loading"], true);
      },
      GET_USER_PROPERTY_DATA_FILTER_SUCCESS(state, action) {
        return state
          .setIn(["userPropertyData",'items', action.propertyStatus,action.page], action.data)
          .setIn(["userPropertyData", "pagination",action.propertyStatus], action.pagination)
          .setIn(["userPropertyData", "loading"], false);
      },
      GET_USER_PROPERTY_DATA_FILTER_FAILURE(state) {
        return state.setIn(["userPropertyData", "loading"], false);
      },
      PUT_PROPERTY_DETAIL_STATUS_REQUEST(state){
        return state.setIn(['putPropertyDetailStatus','loading'],true).setIn(['putPropertyDetailStatus','status'],false)
      },
      PUT_PROPERTY_DETAIL_STATUS_SUCCESS(state,action){
        return state.setIn(['putPropertyDetailStatus','loading'],false).setIn(['putPropertyDetailStatus','status'],true)
      },
      PUT_PROPERTY_DETAIL_STATUS_FAILURE(state){
        return state.setIn(['putPropertyDetailStatus','loading'],false).setIn(['putPropertyDetailStatus','status'],false)
      },
      GET_MY_PROPERTY_LISTING_REQUEST(state){
        return state.setIn(['myPropertyListing','loading'],true)
      },
      GET_MY_PROPERTY_LISTING_SUCCESS(state,action){
        return state.setIn(['myPropertyListing','loading'],false).setIn(['myPropertyListing','items',action.page],action.data).setIn(['myPropertyListing','pagination'],action.pagination)
      },
      GET_MY_PROPERTY_LISTING_FAILURE(state){
        return state.setIn(['myPropertyListing','loading'],false)
      },
      GET_PROPERTY_ME_STATISTIC_REQUEST(state){
        return state.setIn(['propertyStatistic','loading'],true)
      },
      GET_PROPERTY_ME_STATISTIC_SUCCESS(state,action){
        return state.setIn(['propertyStatistic','loading'],false).setIn(['propertyStatistic','data'],action.data)
      },
      GET_PROPERTY_ME_STATISTIC_FAILURE(state){
        return state.setIn(['propertyStatistic','loading'],false)

      }
    },
    effects: {
      GET_PROPERTY_LIST_REQUEST: [watcherGetPropertyListRequest],
      GET_PROPERTY_DETAIL_REQUEST: [watcherGetPropertyDetailRequest],
      PUT_USER_PROPERTY_LISTING_DATA_REQUEST: [
        watcherPutUserPropertyListingDataRequest
      ],
      POST_USER_PROPERTY_LISTING_REQUEST: [
        watcherPostUserPropertyListingRequest
      ],
      POST_USER_PROPERTY_PROJECT_CREATE_REQUEST: [
        watcherPostUserPropertyProjectCreateRequest
      ],
      POST_USER_PROPERTY_SEARCH_PROJECT_REQUEST: [
        watcherPostUserPropertySearchProjectRequest
      ],
      POST_USER_PROPERTY_SEARCH_STATE_REQUEST: [
        watcherPostUserPropertySearchStateRequest
      ],
      POST_USER_PROPERTY_SEARCH_CITY_REQUEST: [
        watcherPostUserPropertySearchCityRequest
      ],
      GET_USER_PROPERTY_LISTING_DETAIL_REQUEST: [
        watcherGetUserPropertyListingDetailRequest
      ],
      PUT_USER_PROPERTY_LISTING_DETAIL_IMAGE_REQUEST: [
        watcherPutUserPropertyListingDetailImageRequest
      ],
      POST_USER_PROPERTY_LISTING_DATA_IMAGE_DEFAULT_REQUEST: [
        watcherPostUserPropertyListingDataImageDefaultRequest
      ],
      DELETE_USER_PROPERTY_LISTING_IMAGE_DATA_REQUEST: [
        watcherDeleteUserPropertyListingImageRequest
      ],
      POST_USER_PROPERTY_LISTING_DATA_IMAGE_REQUEST: [
        watcherPostUserPropertyListingDataImageRequest
      ],
      PUT_USER_PROPERTY_LISTING_PRICE_REQUEST: [
        watcherPutUserPropertyListingPriceRequest
      ],
      PUT_USER_PROPERTY_STATUS_REQUEST: [
        watcherPutUserPropertyStatusRequest
      ],
      GET_USER_PROPERTY_DATA_FILTER_REQUEST: [
      watcherGetUserPropertyDataFilterRequest
      ],
      PUT_PROPERTY_DETAIL_WISHLIST_REQUEST: [watcherPutPropertyDetailWishlistRequest],
      PUT_PROPERTY_DETAIL_STATUS_REQUEST:[watcherPutPropertyDetailStatusRequest],
      GET_MY_PROPERTY_LISTING_REQUEST:[watcherGetMyPropertyListingRequest],
      GET_PROPERTY_ME_STATISTIC_REQUEST:[watcherGetPropertyMeStatisticRequest]
    }
  };
};
