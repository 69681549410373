export const Color = {
  backgroundPrimary: "#F0F4FF",
  yellowColorPrimary: "#FFF500",
  yellowColorPrimaryLight: "#FEE9C0",
  primaryColor: "#3462F4",
  primaryLightColor: "#C8D4F4",
  primaryRed: "#D93F2A",
  primaryLightRed: "#FFD8D2",
  primaryPurple: "#7951EA",
  primaryLightPurple: "#D5CAF3",
  primaryLightGrey: "#BDBDBD",
  facebookColor: "#3b5998",
  borderColor: "#dddddd",
  greyBackgroundColor: "#f8f8f8",
  primaryDarkColor: "#244fb6",
  darkGreyBackground: "#f2f2f2",
  agentFirstColor: "#355cee",
  agentSecondColor: "#3721b4",
  darkGreyColor: "#868686",
  noteBackground: "#fffdf0",
  noteTextColor: "#ed9115",
  iconColor: "#969696",
  EnhanceIconColor: "#545454",
  orangeTextColor: "#ec8d02",
  categoryColor: "#e5e5e5",
  secondaryTextColor: "#7c7c7c",
  lightGrey: "#f1f1f1",
  homeBannerColor: "#233255",
  homeBannerColor2: "#151d33",
  homeBannerColor3: "#0c1d49",
  greyBackground:"#f7f7f7",
  guidePink:"#f4efec",
  guideBlue:"#a6c8de",
  guideGray:"#ececee",
  blackColor:"#2c2c2c",
  transparent: "#ffffff00",
  textWhite: "#ffffff",
  whatsappGreen: "#25d336",
  yellowColor: "#FFDE59",
  darkGrey: "#636363",
  darkOrange: "#FFC700",
  propertyListingLabel: "#707070"
};
