/**
 * Copyright 2020 - present, Alpstein Technology Sdn Bhd.
 * All rights reserved.
 */

import cogoToast from "cogo-toast";

const Toast = {
  success(message = "") {
    cogoToast.success(message);
  },
  info(message = "") {
    cogoToast.info(message);
  },
  error(message = "") {
    cogoToast.error(message);
  },
  warning(message = "") {
    cogoToast.warn(message);
  },
  loading(message = "") {
    cogoToast.loading(message);
  }
};

export default Toast;
