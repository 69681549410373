export const updateProfileDetailInLocal = data => ({
  type: "UPDATE_PROFILE_DETAIL",
  data
});

export const updateProfileDetail = data => ({
  type: "profile/UPDATE_PROFILE_DETAIL",
  data
});

export const getMeDataRequestInLocal = () => ({
  type: "GET_ME_DATA_REQUEST"
});

export const getMeDataRequest = () => ({
  type: "profile/GET_ME_DATA_REQUEST"
});

export const getMeDataSuccess = data => ({
  type: "GET_ME_DATA_SUCCESS",
  data
});

export const getMeDataFailure = message => ({
  type: "GET_ME_DATA_FAILURE",
  message
});

export const putChangePasswordRequest = putData => ({
  type: "profile/PUT_CHANGE_PASSWORD_REQUEST",
  putData
});

export const putChangePasswordSuccess = data => ({
  type: "PUT_CHANGE_PASSWORD_SUCCESS",
  data
});

export const putChangePasswordFailure = messages => ({
  type: "PUT_CHANGE_PASSWORD_FAILURE",
  messages
});

export const putProfileAvatarRequest = putData => ({
  type: "profile/PUT_PROFILE_AVATAR_REQUEST",
  putData
});

export const putProfileAvatarSuccess = data => ({
  type: "PUT_PROFILE_AVATAR_SUCCESS",
  data
});

export const putProfileAvatarFailure = message => ({
  type: "PUT_PROFILE_AVATAR_FAILURE",
  message
});

export const putProfileDataRequest = putData => ({
  type: "profile/PUT_PROFILE_DATA_REQUEST",
  putData
});

export const putProfileDataSuccess = data => ({
  type: "PUT_PROFILE_DATA_SUCCESS",
  data
});

export const putProfileDataFailure = message => ({
  type: "PUT_PROFILE_DATA_FAILURE",
  message
});

export const updateAgentTokenRequest = token => ({
  type:"profile/UPDATE_AGENT_TOKEN_REQUEST",
  token
})

export const postBindPhoneNumberRequest = postData => ({
  type:"profile/POST_BIND_PHONE_NUMBER_REQUEST",
  postData
})

export const postBindPhoneNumberSuccess = data => ({
  type:"POST_BIND_PHONE_NUMBER_SUCCESS",
  data
})

export const postBindPhoneNumberFailure = messages => ({
  type:"POST_BIND_PHONE_NUMBER_FAILURE",
  messages
})

export const getAgentAccountRequest = id=> ({
  type:"profile/GET_AGENT_ACCOUNT_REQUEST",
  id
})

export const getAgentAccountSuccess = (id,data)=>({
  type:"GET_AGENT_ACCOUNT_SUCCESS",
  id,data
})

export const getAgentAccountFailure = message => ({
  type:"GET_AGENT_ACCOUNT_FAILURE",
  message
})

export const getAccountListAgentRequest = (page=1,keyword="",stateValue="") => ({
  type:"profile/GET_ACCOUNT_LIST_AGENT_REQUEST",
  page,
  keyword,
  stateValue
})

export const getAccountListAgentSuccess = (page,data,pagination) => ({
  type:"GET_ACCOUNT_LIST_AGENT_SUCCESS",
  page,data,pagination
})

export const getAccountListAgentFailure = messages => ({
  type:"GET_ACCOUNT_LIST_AGENT_FAILURE",
  messages
})

export const getAccountListDeveloperRequest = (page=1,keyword="",stateValue) => ({
  type:"profile/GET_ACCOUNT_LIST_DEVELOPER_REQUEST",
  page,
  keyword,
  stateValue
})

export const getAccountListDeveloperSuccess = (page,data,pagination) => ({
  type:"GET_ACCOUNT_LIST_DEVELOPER_SUCCESS",
  page,data,pagination
})

export const getAccountListDeveloperFailure = messages => ({
  type:"GET_ACCOUNT_LIST_DEVELOPER_FAILURE",
  messages
})

export const postMeChangePhoneRequest = (postData) => ({
  type:"profile/POST_ME_CHANGE_PHONE_REQUEST",
  postData
})
export const postMeChangePhoneSuccess = (data) => ({
  type:"POST_ME_CHANGE_PHONE_SUCCESS",
  data
})
export const postMeChangePhoneFailure = (messages) => ({
  type:"POST_ME_CHANGE_PHONE_FAILURE",
  messages
})
export const postMeChangeEmailRequest = (postData) => ({
  type:"profile/POST_ME_CHANGE_EMAIL_REQUEST",
  postData
})
export const postMeChangeEmailSuccess = (data) => ({
  type:"POST_ME_CHANGE_EMAIL_SUCCESS",
  data
})
export const postMeChangeEmailFailure = (messages) => ({
  type:"POST_ME_CHANGE_EMAIL_FAILURE",
  messages
})

// Get Referral Code
export const getReferralCodeRequest = (page = 1, listingStatus, createdAt) => ({
  type: "profile/GET_REFERRAL_CODE_REQUEST",
  page,
  listingStatus,
  createdAt
});

export const getReferralCodeSuccess = (data, pagination, page) => ({
  type: "GET_REFERRAL_CODE_SUCCESS",
  data,
  pagination,
  page
});

export const getReferralCodeFailure = message => ({
  type: "GET_REFERRAL_CODE_FAILURE",
  message
});

export const getAgentListSaleDetailRequest = (page, slug) => ({
  type:"profile/GET_AGENT_LIST_SALE_DETAIL_REQUEST",
  page, slug
})

export const getAgentListSaleDetailSuccess = (page,data,pagination) => ({
  type:"GET_AGENT_LIST_SALE_DETAIL_SUCCESS",
  page,data,pagination
})

export const getAgentListSaleDetailFailure = messages => ({
  type:"GET_AGENT_LIST_SALE_DETAIL_FAILURE",
  messages
})
export const getAgentListRentDetailRequest = (page, slug) => ({
  type:"profile/GET_AGENT_LIST_RENT_DETAIL_REQUEST",
  page, slug
})

export const getAgentListRentDetailSuccess = (page,data,pagination) => ({
  type:"GET_AGENT_LIST_RENT_DETAIL_SUCCESS",
  page,data,pagination
})

export const getAgentListRentDetailFailure = messages => ({
  type:"GET_AGENT_LIST_RENT_DETAIL_FAILURE",
  messages
})
export const getDeveloperListDetailRequest = (page, slug) => ({
  type:"profile/GET_DEVELOPER_LIST_DETAIL_REQUEST",
  page, slug
})

export const getDeveloperListDetailSuccess = (page,data,pagination) => ({
  type:"GET_DEVELOPER_LIST_DETAIL_SUCCESS",
  page,data,pagination
})

export const getDeveloperListDetailFailure = messages => ({
  type:"GET_DEVELOPER_LIST_DETAIL_FAILURE",
  messages
})

