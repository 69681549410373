export const postSearchListingRequest = (route, page, postData,queryParam,suggestion) => ({
  type: "search/POST_SEARCH_LISTING_REQUEST",
  route,
  page,
  postData,
  queryParam,
  suggestion
});

export const postSearchListingSuccess = (route, page, data, pagination,suggestion) => ({
  type: "POST_SEARCH_LISTING_SUCCESS",
  route,
  page,
  data,
  pagination,
  suggestion
});

export const postSearchListingFailure = message => ({
  type: "POST_SEARCH_LISTING_FAILURE",
  message
});

export const setFilterData = (route, filterData) => ({
  type: "search/SET_FILTER_DATA",
  route,
  filterData
});
export const setSortData = (route, sortData) => ({
  type: "search/SET_SORT_DATA",
  route,
  sortData
});

export const clearSortData = () => ({
  type: "search/CLEAR_SORT_DATA"
});

export const clearFilterData = () => ({
  type: "search/CLEAR_FILTER_DATA"
});

export const postSearchSuggestionRequest = (route, postData) => ({
  type: "search/POST_SEARCH_SUGGESTION_REQUEST",
  route,
  postData
});

export const postSearchSuggestionSuccess = (route, data) => ({
  type: "POST_SEARCH_SUGGESTION_SUCCESS",
  route,
  data
});

export const postSearchSuggestionFailure = message => ({
  type: "POST_SEARCH_SUGGESTION_FAILURE",
  message
});

export const clearSearchSuggestion = route => ({
  type: "search/CLEAR_SEARCH_SUGGESTION",
  route
});

export const setCoordinateData = coordinate => ({
  type: "search/SET_COORDINATE_DATA",
  coordinate
});

export const clearCoordinateData = () => ({
  type: "search/CLEAR_COORDINATE_DATA"
});

export const putPropertyDetailWishlistSuccess = (id,data,route) => ({
  type:"search/PUT_PROPERTY_DETAIL_WISHLIST_SUCCESS",
  id,
  data,
  route
})
export const putProjectDetailWishlistSuccess = (id,data,route) => ({
  type:"search/PUT_PROJECT_DETAIL_WISHLIST_SUCCESS",
  id,
  data,
  route
})
