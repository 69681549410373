// For Transaction Searching Data
export const postTransactionSearchDataRequest = postData => ({
    type: "listingTrans/POST_TRANSACTION_SEARCH_DATA_REQUEST",
    postData
});

export const postTransactionSearchDataSuccess = data => ({
    type: "POST_TRANSACTION_SEARCH_DATA_SUCCESS",
    data
});

export const postTransactionSearchDataFailure =  message => ({
    type: "POST_TRANSACTION_SEARCH_DATA_FAILURE",
    message
});
// For Transaction Listing Data
export const getTransactionDataListingRequest = (page,data, category,filter) => ({
    type: "listingTrans/GET_TRANSACTION_DATA_LISTING_REQUEST",
    page,
    data,
    category,
    filter
});

export const getTransactionDataListingSuccess = (page,data,category,slug) => ({
    type: "GET_TRANSACTION_DATA_LISTING_SUCCESS",
    page,
    data,
    category,
    slug
});

export const getTransactionDataListingFailure = message => ({
    type: "GET_TRANSACTION_DATA_LISTING_FAILURE",
    message
});
//Get Transaction common
export const getTransactionCommonRequest = () => ({
    type: "listingTrans/GET_TRANSACTION_COMMON_REQUEST",
});

export const getTransactionCommonSuccess = data => ({
    type: "GET_TRANSACTION_COMMON_SUCCESS",
    data
});

export const getTransactionCommonFailure =  message => ({
    type: "GET_TRANSACTION_COMMON_FAILURE",
    message
});
