export const postAgentAccountRequest = postData => ({
  type: "agent/POST_AGENT_ACCOUNT_REQUEST",
  postData
});

export const postAgentAccountSuccess = data => ({
  type: "POST_AGENT_ACCOUNT_SUCCESS",
  data
});


export const postAgentAccountFailure = message => ({
  type: "POST_AGENT_ACCOUNT_FAILURE",
  message
});

export const postAgentAccountVerifyRequest = postData => ({
  type: "agent/POST_AGENT_ACCOUNT_VERIFY_REQUEST",
  postData
});

export const postAgentAccountVerifySuccess = data => ({
  type: "POST_AGENT_ACCOUNT_VERIFY_SUCCESS",
  data
});

export const postAgentAccountVerifyFailure = message => ({
  type: "POST_AGENT_ACCOUNT_VERIFY_FAILURE",
  message
});

export const postAgentSearchAgencyRequest = postData => ({
  type: "agent/POST_AGENT_SEARCH_AGENCY_REQUEST",
  postData
});

export const postAgentSearchAgencySuccess = data => ({
  type: "POST_AGENT_SEARCH_AGENCY_SUCCESS",
  data
});

export const postAgentSearchAgencyFailure = message => ({
  type: "POST_AGENT_SEARCH_AGENCY_FAILURE",
  message
});

export const postAgentSearchProjectRequest = postData => ({
  type: "agent/POST_AGENT_SEARCH_PROJECT_REQUEST",
  postData
});

export const postAgentSearchProjectSuccess = data => ({
  type: "POST_AGENT_SEARCH_PROJECT_SUCCESS",
  data
});

export const postAgentSearchProjectFailure = message => ({
  type: "POST_AGENT_SEARCH_PROJECT_FAILURE",
  message
});

export const postAgentListingRequest = postData => ({
  type: "agent/POST_AGENT_LISTING_REQUEST",
  postData
});

export const postAgentListingSuccess = (id, data) => ({
  type: "POST_AGENT_LISTING_SUCCESS",
  id,
  data
});

export const postAgentListingFailure = message => ({
  type: "POST_AGENT_LISTING_FAILURE",
  message
});

export const postAgentListingv2Request = (page, postData) => ({
  type: "agent/POST_AGENT_LISTING_v2_REQUEST",
  page,
  postData
});

export const postAgentListingv2Success = (page, data, pagination) => ({
  type: "POST_AGENT_LISTING_v2_SUCCESS",
  page,
  data,
  pagination
});

export const postAgentListingv2Failure = message => ({
  type: "POST_AGENT_LISTING_v2_FAILURE",
  message
});

export const putAgentListingDataRequest = (id, putData) => ({
  type: "agent/PUT_AGENT_LISTING_DATA_REQUEST",
  id,
  putData
});

export const putAgentListingDataSuccess = (id, data) => ({
  type: "PUT_AGENT_LISTING_DATA_SUCCESS",
  id,
  data
});

export const putAgentListingDataFailure = message => ({
  type: "PUT_AGENT_LISTING_DATA_FAILURE",
  message
});

export const postAgentListingDataImageRequest = (id, postData, randomID) => ({
  type: "agent/POST_AGENT_LISTING_DATA_IMAGE_REQUEST",
  id,
  postData,
  randomID
});

export const postAgentListingDataImageSuccess = (id, data, randomID) => ({
  type: "POST_AGENT_LISTING_DATA_IMAGE_SUCCESS",
  id,
  data,
  randomID
});

export const postAgentListingDataImageFailure = (randomID, message) => ({
  type: "POST_AGENT_LISTING_DATA_IMAGE_FAILURE",
  randomID,
  message
});

export const postAgentListingDataImageDefaultRequest = id => ({
  type: "agent/POST_AGENT_LISTING_DATA_IMAGE_DEFAULT_REQUEST",
  id
});

export const postAgentListingDataImageDefaultSuccess = id => ({
  type: "POST_AGENT_LISTING_DATA_IMAGE_DEFAULT_SUCCESS",
  id
});

export const postAgentListingDataImageDefaultFailure = (id, message) => ({
  type: "POST_AGENT_LISTING_DATA_IMAGE_DEFAULT_FAILURE",
  id,
  message
});

export const deleteAgentListingImageDataRequest = id => ({
  type: "agent/DELETE_AGENT_LISTING_IMAGE_DATA_REQUEST",
  id
});

export const deleteAgentListingImageDataSuccess = id => ({
  type: "DELETE_AGENT_LISTING_IMAGE_DATA_SUCCESS",
  id
});

export const deleteAgentListingImageDataFailure = id => ({
  type: "DELETE_AGENT_LISTING_IMAGE_DATA_FAILURE",
  id
});

export const setCurrentAgentProjectID = id => ({
  type: "agent/SET_CURRENT_AGENT_PROJECT_ID",
  id
});

export const getAgentListingRequest = (page, status, propertyStatus, keyword, propertyType) => ({
  type: "agent/GET_AGENT_LISTING_REQUEST",
  page,
  status,
  propertyStatus,
  keyword,
  propertyType
});

export const getAgentListingSuccess = (page, data, pagination) => ({
  type: "GET_AGENT_LISTING_SUCCESS",
  page,
  data,
  pagination
});

export const getAgentListingFailure = message => ({
  type: "GET_AGENT_LISTING_FAILURE",
  message
});

export const setFilterData = (filterData) => ({
  type: "agent/SET_FILTER_DATA",
  filterData
});

export const getAgentListingDetailRequest = id => ({
  type: "agent/GET_AGENT_LISTING_DETAIL_REQUEST",
  id
});

export const getAgentListingDataSuccess = (id, data) => ({
  type: "GET_AGENT_LISTING_DETAIL_SUCCESS",
  id,
  data
});

export const getAgentListingDataFailure = message => ({
  type: "GET_AGENT_LISTING_DETAIL_FAILURE",
  message
});

export const getAgentCommonDataRequest = () => ({
  type: "agent/GET_AGENT_COMMON_DATA_REQUEST"
});

export const getAgentCommonDataRequestInLocal = () => ({
  type: "GET_AGENT_COMMON_DATA_REQUEST"
});

export const getAgentCommonDataSuccess = data => ({
  type: "GET_AGENT_COMMON_DATA_SUCCESS",
  data
});

export const getAgentCommonDataFailure = message => ({
  type: "GET_AGENT_COMMON_DATA_FAILURE",
  message
});

export const postAgentListingDataPublishRequest = id => ({
  type: "agent/POST_AGENT_LISTING_DATA_PUBLISH_REQUEST",
  id
});

export const postAgentListingDataPublishSuccess = data => ({
  type: "POST_AGENT_LISTING_DATA_PUBLISH_SUCCESS",
  data
});

export const postAgentListingDataPublishFailure = message => ({
  type: "POST_AGENT_LISTING_DATA_PUBLISH_FAILURE",
  message
});

export const postAgentSearchStateRequest = postData => ({
  type: "agent/POST_AGENT_SEARCH_STATE_REQUEST",
  postData
});

export const postAgentSearchStateSuccess = data => ({
  type: "POST_AGENT_SEARCH_STATE_SUCCESS",
  data
});

export const postAgentSearchStateFailure = message => ({
  type: "POST_AGENT_SEARCH_STATE_FAILURE",
  message
});

export const postAgentSearchCityRequest = postData => ({
  type: "agent/POST_AGENT_SEARCH_CITY_REQUEST",
  postData
});

export const postAgentSearchCitySuccess = data => ({
  type: "POST_AGENT_SEARCH_CITY_SUCCESS",
  data
});

export const postAgentSearchCityFailure = message => ({
  type: "POST_AGENT_SEARCH_CITY_FAILURE",
  message
});

export const postAgentProjectCreateRequest = postData => ({
  type: "agent/POST_AGENT_PROJECT_CREATE_REQUEST",
  postData
});

export const postAgentProjectCreateSuccess = data => ({
  type: "POST_AGENT_PROJECT_CREATE_SUCCESS",
  data
});

export const postAgentProjectCreateFailure = message => ({
  type: "POST_AGENT_PROJECT_CREATE_FAILURE",
  message
});

export const getAgentLeadListRequest = (page = 1, listingStatus, createdAt) => ({
  type: "agent/GET_AGENT_LEAD_LIST_REQUEST",
  page,
  listingStatus,
  createdAt
});

export const getAgentLeadListSuccess = (data, pagination, page) => ({
  type: "GET_AGENT_LEAD_LIST_SUCCESS",
  data,
  pagination,
  page
});

export const getAgentLeadListFailure = message => ({
  type: "GET_AGENT_LEAD_LIST_FAILURE",
  message
});

export const putAgentLeadDetailRequest = (id, putData) => ({
  type: "agent/PUT_AGENT_LEAD_DETAIL_REQUEST",
  id,
  putData
});

export const putAgentLeadDetailSuccess = (id, data) => ({
  type: "PUT_AGENT_LEAD_DETAIL_SUCCESS",
  id,
  data
});

export const putAgentLeadDetailFailure = message => ({
  type: "PUT_AGENT_LEAD_DETAIL_FAILURE",
  message
});

export const getAgentCommonStateRequest = () => ({
  type: "agent/GET_AGENT_COMMON_STATE_REQUEST"
});

export const getAgentCommonStateSuccess = data => ({
  type: "GET_AGENT_COMMON_STATE_SUCCESS",
  data
});

export const getAgentCommonStateFailure = message => ({
  type: "GET_AGENT_COMMON_STATE_FAILURE",
  message
});

export const getAgentCommonCityRequest = () => ({
  type: "agent/GET_AGENT_COMMON_CITY_REQUEST"
});

export const getAgentCommonCitySuccess = data => ({
  type: "GET_AGENT_COMMON_CITY_SUCCESS",
  data
});

export const getAgentCommonCityFailure = message => ({
  type: "GET_AGENT_COMMON_CITY_FAILURE",
  message
});

export const putAgentListingDetailImageRequest = (id, putData) => ({
  type: "agent/PUT_AGENT_LISTING_DETAIL_IMAGE_REQUEST",
  id,
  putData
});

export const putAgentListingDetailImageSuccess = data => ({
  type: "PUT_AGENT_LISTING_DETAIL_IMAGE_SUCCESS",
  data
});

export const putAgentListingDetailImageFailure = message => ({
  type: "PUT_AGENT_LISTING_DETAIL_IMAGE_FAILURE",
  message
});
//Subscription Agent
export const postAgentSubscriptionPackageRequest = id => ({
  type: "agent/POST_AGENT_SUBSCRIPTION_PACKAGE_REQUEST",
  id
});

export const postAgentSubscriptionPackageSuccess = (data) => ({
  type: "POST_AGENT_SUBSCRIPTION_PACKAGE_SUCCESS",
  data
});

export const postAgentSubscriptionPackageFailure = (id, message) => ({
  type: "POST_AGENT_SUBSCRIPTION_PACKAGE_FAILURE",
  id,
  message
});
