export const postTopUpRequest = postData => ({
  type: "topUp/POST_TOP_UP_REQUEST",
  postData
});

export const postTopUpSuccess = data => ({
  type: "POST_TOP_UP_SUCCESS",
  data
});

export const postTopUpFailure = message => ({
  type: "POST_TOP_UP_FAILURE",
  message
});

export const getTopUpRequestListRequest = (page, filterData) => ({
  type: "topUp/GET_TOP_UP_REQUEST_LIST_REQUEST",
  page,
  filterData
});

export const getTopUpRequestListSuccess = (data, pagination, page) => ({
  type: "GET_TOP_UP_REQUEST_LIST_SUCCESS",
  data,
  pagination,
  page
});

export const getTopUpRequestListFailure = message => ({
  type: "GET_TOP_UP_REQUEST_LIST_FAILURE",
  message
});

export const getTopUpTransactionListRequest = (page, filterData) => ({
  type: "topUp/GET_TOP_UP_TRANSACTION_LIST_REQUEST",
  page,
  filterData
});

export const getTopUpTransactionListSuccess = (data, pagination, page) => ({
  type: "GET_TOP_UP_TRANSACTION_LIST_SUCCESS",
  data,
  pagination,
  page
});

export const getTopUpTransactionListFailure = message => ({
  type: "GET_TOP_UP_TRANSACTION_LIST_FAILURE",
  message
});
