export const postCreateOrderRequest = (postData) => ({
  type: "order/POST_CREATE_ORDER_REQUEST",
  postData
});

export const postCreateOrderSuccess = data => ({
  type: "POST_CREATE_ORDER_SUCCESS",
  data
});

export const postCreateOrderFailure = message => ({
  type: "POST_CREATE_ORDER_FAILURE",
  message
});

export const getOrderListRequest = (page, filterData) => ({
  type: "order/GET_ORDER_LIST_REQUEST",
  page,
  filterData
});

export const getOrderListSuccess = (data, pagination,page) => ({
  type: "GET_ORDER_LIST_SUCCESS",
  data,
  pagination,
  page
});

export const getOrderListFailure = message => ({
  type: "GET_ORDER_LIST_FAILURE",
  message
});

//Subscription Order
export const postOrderPaymentDetailRequest = (id, postData) => ({
  type: "order/POST_ORDER_PAYMENT_DETAIL_REQUEST",
  id,
  postData
});

export const postOrderPaymentDetailSuccess = (data) => ({
  type: "POST_ORDER_PAYMENT_DETAIL_SUCCESS",
  data
});

export const postOrderPaymentDetailFailure =  (id, message) => ({
  type: "POST_ORDER_PAYMENT_DETAIL_FAILURE",
  id,
  message
});
//Agent Subscription for 31-Days Special promotion
export const postOrderFreePaymentDetailRequest = (id, postData) => ({
  type: "order/POST_ORDER_FREE_PAYMENT_DETAIL_REQUEST",
  id,
  postData
});

export const postOrderFreePaymentDetailSuccess = (data) => ({
  type: "POST_ORDER_FREE_PAYMENT_DETAIL_SUCCESS",
  data
});

export const postOrderFreePaymentDetailFailure =  (id, message) => ({
  type: "POST_ORDER_FREE_PAYMENT_DETAIL_FAILURE",
  id,
  message
});
//Subscription Payment Receive Order
export const postOrderPaymentReceiveDetailRequest = (postData) => ({
  type: "order/POST_ORDER_PAYMENT_RECEIVE_DETAIL_REQUEST",
  postData
});

export const postOrderPaymentReceiveDetailSuccess = (data) => ({
  type: "POST_ORDER_PAYMENT_RECEIVE_DETAIL_SUCCESS",
  data
});

export const postOrderPaymentReceiveDetailFailure =  (message) => ({
  type: "POST_ORDER_PAYMENT_RECEIVE_DETAIL_FAILURE",
  message
});
// Get Order History
export const getOrderHistoryListRequest = (page = 1, listingStatus, createdAt) => ({
  type: "order/GET_ORDER_HISTORY_LIST_REQUEST",
  page,
  listingStatus,
  createdAt
});

export const getOrderHistoryListSuccess = (data, pagination, page) => ({
  type: "GET_ORDER_HISTORY_LIST_SUCCESS",
  data,
  pagination,
  page
});

export const getOrderHistoryListFailure = message => ({
  type: "GET_ORDER_HISTORY_LIST_FAILURE",
  message
});
