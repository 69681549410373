import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as imageActions from "../actions/image";
import _ from "lodash";
import Toast from "../utils/Toast";

export default api => {
  function* watcherPostImageRequest({ postData, name }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postImage, postData);

      const { image } = response.data;

      const messages = _.get(response, "data.messages", null);
      if (!_.isEmpty(messages)) {
        const { text, type } = messages[0];
        type === "error" ? Toast.error(text) : Toast.success(text);
      }

      yield put(imageActions.postImageSuccess(image, name));
    } catch (error) {
      yield call(httpErrorHelpers, error, imageActions.postImageFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    image: null,
    loading: false
  });

  return {
    namespace: "image",
    state: INITIAL_STATE,
    reducers: {
      POST_IMAGE_REQUEST(state, action) {
        return state.setIn(["image"], null).setIn(["loading"], true);
      },
      POST_IMAGE_SUCCESS(state, action) {
        if (!_.isEmpty(action.name)) {
          return state.setIn(["image", action.name], action.data).setIn(["loading"], false);
        } else {
          return state.setIn(["image"], action.data).setIn(["loading"], false);
        }
      },
      POST_IMAGE_FAILURE(state, action) {
        return state.setIn(["image"], null).setIn(["loading"], false);
      }
    },
    effects: {
      POST_IMAGE_REQUEST: [watcherPostImageRequest]
    }
  };
};
