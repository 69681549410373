export const postImageRequest = (postData, name) => ({
  type: "image/POST_IMAGE_REQUEST",
  postData,
  name
});

export const postImageSuccess = (data, name) => ({
  type: "POST_IMAGE_SUCCESS",
  data,
  name
});

export const postImageFailure = message => ({
  type: "POST_IMAGE_FAILURE",
  message
});
