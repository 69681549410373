import _ from "lodash"


export const getProjectListLoading = state => _.get(state,['projectList','loading'],false)
export const getProjectListItems = (state,route) => _.get(state,['projectList',route,'items'],null)
export const getProjectListPagination = (state,route) => _.get(state,['projectList',route,'pagination'],null)
export const getProjectDetailLoading = state => _.get(state,['projectDetail','loading'],false)
export const getProjectDetailItems = (state,id) => _.get(state,['projectDetail',id],null)
export const getProjectToggleWishlistStatus = state => _.get(state,['toggleWishlistStatus'],false)

//items
export const getProjectDetailID = project => _.get(project,['id'],null)
export const getProjectDetailName = project => _.get(project,['name'],null)
export const getProjectDetailImageMediumSrc = project => _.get(project,['image','medium','src'],null)
export const getProjectDetailImageHugeSrc = project => _.get(project,['image','huge','src'],null)
export const getProjectDetailKindCode = project => _.get(project,['kind','code'],null)
export const getProjectDetailKindName = project => _.get(project,['kind','name'],null)
export const getProjectDetailTypeCode = project => _.get(project,['type','code'],null)
export const getProjectDetailTypeName = project => _.get(project,['type','name'],null)
export const getProjectDetailCategoryCode = project => _.get(project,['category','code'],null)
export const getProjectDetailCategoryName = project => _.get(project,['category','name'],null)
export const getProjectDetailTenureCode = project => _.get(project,['tenure','code'],null)
export const getProjectDetailTenureName = project => _.get(project,['tenure','name'],null)
export const getProjectDetailBuildStartValueText = project => _.get(project,['build','start','value','text'],null)
export const getProjectDetailBuildStartValueRelative = project => _.get(project,['build','start','value','relative'],null)
export const getProjectDetailBuildStartText = project => _.get(project,['build','start','text'],"")
export const getProjectDetailBuildEndValueText = project => _.get(project,['build','end','value','text'],null)
export const getProjectDetailBuildEndValueRelative = project => _.get(project,['build','end','value','relative'],null)
export const getProjectDetailBuildEndText = project => _.get(project,['build','end','text'],"")
export const getProjectDetailRoomBedText = project => _.get(project,['room','bed','text'],'')
export const getProjectDetailRoomBathText = project => _.get(project,['room','bath','text'],"")
export const getProjectDetailParkingPrimaryText = project => _.get(project,['parking','primary','text'],"")
export const getProjectDetailSizeFloorText = project => _.get(project,['size','floor','text'],"")
export const getProjectDetailSizeLandText = project => _.get(project,['size','land','text'],'')
export const getProjectDetailFurnishingCode = project => _.get(project,['furnishing','code'],null)
export const getProjectDetailFurnishingName = project => _.get(project,['furnishing','name'],null)
export const getProjectDetailFloorLevelCode = project => _.get(project,['floorLevel','code'],null)
export const getProjectDetailFloorLevelName = project => _.get(project,['floorLevel','name'],null)
export const getProjectDetailTagItems = project => _.get(project,['tag','items'],[])
export const getProjectDetailCountryName = project => _.get(project,['country','name'],'')
export const getProjectDetailCountryCode = project => _.get(project,['country','code'],'')
export const getProjectDetailStateName = project => _.get(project,['state','name'],"")
export const getProjectDetailStateCode = project => _.get(project,['state','code'],"")
export const getProjectDetailCityName = project => _.get(project,['city','name'],"")
export const getProjectDetailCityID = project => _.get(project,['city','id'],"")
export const getProjectDetailPostCode = project => _.get(project,['postcode'],null)
export const getProjectDetailCoordinates = project => _.get(project,['coordinates'],null)
export const getProjectDetailAttributeIsImageReady = project => _.get(project,['attribute','isImageReady'],false)
export const getProjectDetailSalePriceText = project => _.get(project,['sale','price','text'],"RM -")
export const getProjectDetailSalePriceValue = project => _.get(project,['sale','price','value'],0)
export const getProjectDetailPerSizeText = project => _.get(project,['perSize','price','text'],"")
export const getProjectDetailPerSizeValue = project => _.get(project,['perSize','price','value'],0)
export const getProjectDetailContent = project => _.get(project,['content'],null)
export const getProjectDetailImages = project => _.get(project,['images'],null)
export const getProjectDetailFeatureItems = project => _.get(project,['feature','items'],[])
export const getProjectDetailAmenityItems = project => _.get(project,['amenity','items'],[])
export const getProjectDetailUnitType = project => _.get(project,['unitType','items'],[])
export const getProjectDetailSale = project =>
    _.get(project, ["sale"], null);
export const getProjectDetailRent = project =>
    _.get(project, ["rent"], null);
export const getProjectDetailSlug = project => _.get(project,['slug'],'')
export const getProjectDetailAccountName = project =>
    _.get(project, ["account", "name"], "");
export const getProjectDetailAccountSlug= project =>
    _.get(project, ["account", "slug"], "");
export const getProjectDetailPublisherName = project =>
    _.get(project, ["publisher", "name"], "");
export const getProjectDetailPublisherRegistrationNumber = project =>
    _.get(project, ["publisher", "registrationNumber"], "");
export const getProjectDetailPublisherImageMediumSrc = project => _.get(project,['publisher','image','medium','src'],"")
export const getProjectDetailAccountContactItems = project =>
    _.get(project, ["account", "contact", "items"], []);
export const getProjectDetailAccountImageSrc = project =>
    _.get(project, ["account", "image", "src]"], null);
export const getProjectDetailPublishAtRelative = project =>
    _.get(project, ["publishedAt", "relative"], "");
export const getProjectDetailSaleOrRentObject = project =>
    !_.isEmpty(getProjectDetailSale(project))
        ? getProjectDetailSale(project)
        : getProjectDetailRent(project)
        ? getProjectDetailRent(project)
        : null;

export const getProjectIsSale = project =>
    !_.isEmpty(getProjectDetailSale(project));
export const getProjectIsRent = project =>
    !_.isEmpty(getProjectDetailRent(project));
export const getProjectDetailVideoUrl = project => _.get(project,['video','url'],"")
//pagination
export const getPaginationPageCount = pagination => _.get(pagination,['pageCount'],0)
export const getPaginationTotalCount = pagination => _.get(pagination,['totalCount'],0)


//coordinate
export const getCoordinateLatitude = coordinate => _.get(coordinate,['latitude'],'')
export const getCoordinateLongitude = coordinate => _.get(coordinate,['longitude'],"")

// unit type
export const getUnitTypeEndPricePerSizeText = unitType => _.get(unitType,['endPrice','perSize','text'],"")
export const getUnitTypeEndPriceText = unitType => _.get(unitType,['endPrice','price','text'],"")
export const getUnitTypeFloorLevelName = unitType => _.get(unitType,['floorLevel','name'],"")
export const getUnitTypeFurnishingName = unitType => _.get(unitType,['furnishing','name'],"")
export const getUnitTypeID = unitType => _.get(unitType,['id'],null)
export const getUnitTypeImageMediumSrc = unitType => _.get(unitType,['image','medium','src'],null)
export const getUnitTypeName = unitType => _.get(unitType,['name'],'')
export const getUnitTypeParkingPrimaryText = unitType => _.get(unitType,['parking','primary','text'],'')
export const getUnitTypeRoomBathText = unitType => _.get(unitType,['room','bath','text'],'')
export const getUnitTypeRoomBedText = unitType => _.get(unitType,['room','bed','text'],'')
export const getUnitTypeSizeFloorText = unitType => _.get(unitType,['size','floor','text'],'')
export const getUnitTypeSizeLandText = unitType => _.get(unitType,['size','land','text'],'')
export const getUnitTypeTagItems = unitType => _.get(unitType,['tag','items'],[])
export const getUnitTypeStartPricePerSizeText = unitType => _.get(unitType,['startPrice','perSize','text'],"")
export const getUnitTypeStartPriceText = unitType => _.get(unitType,['startPrice','price','text'],"")
export const getUnitTypeStartPriceValue = unitType => _.get(unitType,['startPrice','price','value'],0)
