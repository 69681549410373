import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as projectActions from "../actions/project";
import * as propertyActions from "../actions/property";
import * as searchActions from "../actions/search";
import * as wishlistActions from "../actions/wishlist";
import _ from "lodash";
import Toast from "../utils/Toast";

export default api => {
  function* watcherGetProjectListRequest({ route = null, page = 1, filterParam = null }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getProjectList, page);

      const { items, _meta } = response.data;

      yield put(projectActions.getProjectListSuccess(route, page, items, _meta));
    } catch (error) {
      yield call(httpErrorHelpers, error, projectActions.getProjectListFailure, effects);
    }
  }

  function* watcherPutProjectDetailWishlistRequest({ id, route }, effects) {
    const { call, put, select } = effects;

    try {
      const response = yield call(api.putProjectDetailWishlist, id);

      const { item, messages } = response.data;

      yield put(projectActions.putProjectDetailWishlistSuccess(id, item, route));
      yield put(searchActions.putProjectDetailWishlistSuccess(id, item, route));
      yield put(wishlistActions.putWishlistSuccess(id, item));
      if (!_.isEmpty(messages[0])) {
        Toast.success(messages[0].text);
      }
    } catch (error) {
      yield call(httpErrorHelpers, error, projectActions.putProjectDetailWishlistFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    commonData: null
  });

  return {
    namespace: "project",
    state: INITIAL_STATE,
    reducers: {
      GET_PROJECT_LIST_REQUEST(state, action) {
        return state.setIn(["projectList", "loading"], true);
      },
      GET_PROJECT_LIST_SUCCESS(state, action) {
        return state
          .setIn(["projectList", "loading"], false)
          .setIn(["projectList", action.route, "items", action.page], action.data)
          .setIn(["projectList", action.route, "pagination"], action.pagination);
      },
      GET_PROJECT_LIST_FAILURE(state, action) {
        return state.setIn(["projectList", "loading"], false);
      },
      PUT_PROJECT_DETAIL_WISHLIST_REQUEST(state) {
        return state.setIn(["putProjectWishlist", "loading"], true).setIn(["toggleWishlistStatus"], false);
      },
      PUT_PROJECT_DETAIL_WISHLIST_SUCCESS(state, action) {
        return state.setIn(["putProjectWishlist", "loading"], false).setIn(["toggleWishlistStatus"], true);
      },
      PUT_PROJECT_DETAIL_WISHLIST_FAILURE(state) {
        return state.setIn(["putProjectWishlist", "loading"], false).setIn(["toggleWishlistStatus"], false);
      }
    },
    effects: {
      GET_PROJECT_LIST_REQUEST: [watcherGetProjectListRequest],
      PUT_PROJECT_DETAIL_WISHLIST_REQUEST: [watcherPutProjectDetailWishlistRequest]
    }
  };
};
