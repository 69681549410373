import Immutable from "seamless-immutable";
import * as blogActions from "../actions/blog";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import _ from "lodash";
import * as postActions from "../actions/post";
export default api => {
  function* watcherGetPostListingRequest({ page, category }, effects) {
    const { call, put } = effects;
    try {

      let params = `&type=guides${!_.isEmpty(category) ? `&category=${category}` : ""}`;
      const response = yield call(api.getPostListing, page, params);
      const { items, _meta } = response.data;

      yield put(postActions.getPostListingSuccess(page, items, _meta));
    } catch (error) {
      yield call(httpErrorHelpers, error, postActions.getPostListingFailure, effects);
    }
  }

  function* watcherGetPostDetailRequest({ id }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getPostDetail, id);

      yield put(postActions.getPostDetailSuccess(id, response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, postActions.getPostDetailFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    post: null
  });

  return {
    namespace: "post",
    state: INITIAL_STATE,
    reducers: {
      GET_POST_LISTING_REQUEST(state, action) {
        return state.setIn(["postListing", "loading"], true);
      },
      GET_POST_LISTING_SUCCESS(state, action) {
        return state
          .setIn(["postListing", "loading"], false)
          .setIn(["postListing", "items", action.page], action.data)
          .setIn(["postListing", "pagination"], action.pagination);
      },
      GET_POST_LISTING_FAILURE(state, action) {
        return state.setIn(["postListing", "loading"], false);
      },
      SET_POST_CATEGORY(state,action){
        return state.setIn(['postCategoryType'],action.category)
      }
    },
    effects: {
      GET_POST_LISTING_REQUEST: [watcherGetPostListingRequest],
    }
  };
};
