/**
 * This file created by HAUYANG on 03/03/2019.
 * Copyright 2019-present, Alpstein Technology.
 * All rights reserved.
 */

import api from "../services/httpUtilities/httpService";
import authModel from "./auth";
import movementModal from "./movement";
import globalModal from "./global";
import commonModal from "./common";
import profileModal from "./profile";
import passwordModal from "./password";
import dashboard from "./dashboard";
import imageModel from "./image";
import blogModel from "./blog";
import userModel from "./user";
import topUpModel from "./topUp";
import productModel from "./product";
import orderModel from "./order";
import propertyModel from './property';
import projectModel from './project';
import searchModel from "./search"
import newsModel from "./news";
import agentModel from "./agent";
import verificationModel from "./verification";
import postModel from "./post";
import wishlistModel from "./wishlist"
import listingTransModel from "./listingTrans"

export function registerModels(app) {
  app.model(authModel(api));
  app.model(movementModal(api));
  app.model(globalModal(api));
  app.model(commonModal(api));
  app.model(profileModal(api));
  app.model(passwordModal(api));
  app.model(dashboard(api));
  app.model(imageModel(api));
  app.model(blogModel(api));
  app.model(userModel(api));
  app.model(topUpModel(api));
  app.model(productModel(api));
  app.model(orderModel(api));
  app.model(propertyModel(api));
  app.model(projectModel(api));
  app.model(searchModel(api));
  app.model(newsModel(api));
  app.model(agentModel(api));
  app.model(verificationModel(api))
  app.model(postModel(api))
  app.model(wishlistModel(api))
  app.model(listingTransModel(api))
}
