export const getNewsListRequest = (page) => ({
    type:"news/GET_NEWS_LIST_REQUEST",
    page
})

export const getNewsListSuccess = (data,pagination,page) => ({
    type:"GET_NEWS_LIST_SUCCESS",
    data,
    pagination,
    page
})

export const getNewsListFailure = (message) => ({
    type:"GET_NEWS_LIST_FAILURE",
    message
})
