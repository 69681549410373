import React, { Component } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { withTheme } from "styled-components";
import Typography from "@mui/material/Typography";
import HomeLoanCalculator from "../LoanTab/components/HomeLoanCaculator";
import InfoIcon from "@mui/icons-material/Info";
import CustomCalculator from "./components/CustomCalculator";
import Helpers from "../../../src/utils/Helpers";
import * as projectSelectors from "../../../src/selectors/project";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@mui/material/Popover";
import Constant from "../../../src/constants";
import { Color as Colors } from "../../../src/utils/Color";
import CustomFont from "../../../components/CustomFont";
import _ from "lodash";
import * as propertySelectors from "../../../src/selectors/property";

const AccordianContainer = styled(Accordion)`
  background-color: #2c2c2c !important;
  color: white !important;
  border-radius: 10px;
  & .MuiAccordion-root {
    background-color: #2c2c2c;
    color: white;
    border-radius: 10px;
  }
`;

const AccordionDetailsContainer = styled(AccordionDetails)`
  background: white !important;
`;

class LoanTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalPropertyPrice: -1,
      stampDutyPropertyPrice: -1,
      stampDutyLoanAmount: -1,
      desc: "",
      propertyPrice: 0,
      popOverVisible: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        legalPropertyPrice: 0,
        stampDutyPropertyPrice: 0,
        stampDutyLoanAmount: 0
      });
    }, 1000);
    this.updatePrice();
  }

  componentDidUpdate(prevProps) {}

  updatePrice = () => {
    const { isMobile = false, data } = this.props;

    const isSale = propertySelectors.getPropertyIsSale(data);
    const isRent = propertySelectors.getPropertyIsRent(data);
    const priceObject = propertySelectors.getPropertyDetailSaleOrRentObject(data);
    const formattedPrice = _.get(priceObject, ["price", "value"], "-");
    setTimeout(() => {
      this.setState({
        legalPropertyPrice: formattedPrice,
        stampDutyPropertyPrice: formattedPrice,
        stampDutyLoanAmount: formattedPrice,
        propertyPrice: formattedPrice
      });
    }, 1000);
  };

  onChangeLegalFeeText = e => {
    this.setState({ propertyPrice: e.target.value });
  };

  onChangeStampDutyFeeText = e => {
    this.setState({ propertyPrice: e.target.value });
  };

  onChangeStampDutyLoanAmountText = e => {
    this.setState({ propertyPrice: e.target.value });
  };

  onClickDescription = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget,
      popOverVisible: true,
      desc: value
    });
  };

  handlePopoverClose = () => {
    this.setState({
      popOverVisible: false
    });
  };

  onChangePropertyPriceText = value => {
    this.setState({
      propertyPrice: value
    });
  };
    onClickLoanForm =(propertyPrice, interestRate, loanTerm) =>{
        const { onClickLoanForm } = this.props;
        onClickLoanForm && onClickLoanForm(propertyPrice, interestRate, loanTerm);
    }

  render() {
    const { isMobile = false, data } = this.props;

    const {
      legalPropertyPrice,
      stampDutyPropertyPrice,
      stampDutyLoanAmount,
      anchorEl,
      popOverVisible,
      desc,
      propertyPrice
    } = this.state;
    const isSale = propertySelectors.getPropertyIsSale(data);
    const isRent = propertySelectors.getPropertyIsRent(data);
    const priceObject = propertySelectors.getPropertyDetailSaleOrRentObject(data);
    const priceValue = _.get(priceObject, ["price", "value"], "-");
    return (
      <div style={{ marginTop: "2rem" }}>
        <AccordianContainer defaultExpanded style={{ borderRadius: "5px" }}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon style={{ color: Colors.textWhite }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ alignItems: "center" }}
          >
            <CustomFont
              variant="p"
              styles={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                fontSize: isMobile ? "0.9rem" : "1rem",
                color: Colors.textWhite
              }}
            >
              Home Loan Calculator
              {/*<InfoIcon*/}
              {/*style={{*/}
              {/*marginLeft: "10px",*/}
              {/*color: "white",*/}
              {/*fontSize: "1.4rem"*/}
              {/*}}*/}
              {/*/>*/}
            </CustomFont>
          </AccordionSummary>
          <AccordionDetailsContainer>
            <HomeLoanCalculator
              propertyPrice={propertyPrice}
              priceValue={priceValue}
              onClickLoanForm={this.onClickLoanForm}
              onChangePropertyPriceText={this.onChangePropertyPriceText}
            />
          </AccordionDetailsContainer>
        </AccordianContainer>

        <AccordianContainer
          style={{ marginTop: "1.5rem", borderRadius: "5px" }}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ alignItems: "center" }}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <CustomFont
              variant="p"
              styles={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                fontSize: isMobile ? "0.9rem" : "1rem",
                color: Colors.textWhite
              }}
            >
              Legal Fee Calculator
              <IconButton
                style={{ padding: "6px", overflow: "hidden" }}
                onClick={e => this.onClickDescription(e, Constant.CALCULATOR_LEGAL_FEE)}
                aria-label="icon-button"
              >
                <InfoIcon
                  style={{
                    marginLeft: "10px",
                    color: Colors.textWhite,
                    fontSize: "1.4rem"
                  }}
                />
              </IconButton>
            </CustomFont>
          </AccordionSummary>
          <AccordionDetailsContainer>
            <CustomCalculator
              title={`RM ${Helpers.getLegalFee(propertyPrice)}`}
              desc="Total Legal Fee Payable"
              label="Property Purchase Price (RM)"
              value={propertyPrice}
              onChangeText={this.onChangeLegalFeeText}
            />
          </AccordionDetailsContainer>
        </AccordianContainer>
        <AccordianContainer style={{ marginTop: "1.5rem", borderRadius: "5px" }}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ alignItems: "center" }}
          >
            <CustomFont
              variant="p"
              styles={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                fontSize: isMobile ? "0.9rem" : "1rem",
                color: Colors.textWhite
              }}
            >
              Stamp Duty Calculator - Sale & Purchase/Transfer
              <IconButton
                style={{ padding: "6px", overflow: "hidden" }}
                onClick={e => this.onClickDescription(e, Constant.CALCULATOR_STAMP_DUTY_FEE)}
                aria-label="icon-button"
              >
                <InfoIcon
                  style={{
                    marginLeft: "10px",
                    color: "white",
                    fontSize: "1.4rem"
                  }}
                />
              </IconButton>
            </CustomFont>
          </AccordionSummary>
          <AccordionDetailsContainer>
            <CustomCalculator
              title={`RM ${Helpers.getStampDutyFee(propertyPrice)}`}
              desc="Total Stamp Duty Payable By Purchaser"
              label="Property Selling Price / Market Value which ever is higher (RM)"
              value={propertyPrice}
              onChangeText={this.onChangeStampDutyFeeText}
            />
          </AccordionDetailsContainer>
        </AccordianContainer>
        <AccordianContainer style={{ marginTop: "1.5rem", borderRadius: "5px" }}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              alignItems: "center"
            }}
          >
            <CustomFont
              variant="p"
              styles={{
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  color: Colors.textWhite
              }}
            >
              Stamp Duty Calculator - Loan
              {/*<InfoIcon*/}
              {/*style={{*/}
              {/*marginLeft: "10px",*/}
              {/*color: "white",*/}
              {/*fontSize: "1.4rem"*/}
              {/*}}*/}
              {/*/>*/}
            </CustomFont>
          </AccordionSummary>
          <AccordionDetailsContainer>
            <CustomCalculator
              title={`RM ${(propertyPrice * 0.5) / 100}`}
              desc="Total Stamp Duty Payable By Purchaser"
              label="Property Selling Price / Market Value which ever is higher (RM)"
              value={propertyPrice}
              onChangeText={this.onChangeStampDutyLoanAmountText}
            />
          </AccordionDetailsContainer>
        </AccordianContainer>
        <Popover
          open={popOverVisible}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <div style={{ padding: "10px 20px" }}>
            {_.isEqual(desc, Constant.CALCULATOR_LEGAL_FEE)
              ? Helpers.legalFeeDesc()
              : _.isEqual(desc, Constant.CALCULATOR_STAMP_DUTY_FEE)
              ? Helpers.StampDutyDesc()
              : false}
          </div>
        </Popover>
      </div>
    );
  }
}

export default withTheme(LoanTab);
