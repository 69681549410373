export default {
  AGENT: "agent",
  DEVELOPER: "developer",
  HOME_OWNER: "home-owner",
  PG_TOKEN: "pg_token",
  PG_AGENT_TOKEN: "pg_agent_token",
  LOGIN_PROVIDER_GOOGLE: "google-login",
  LOGIN_PROVIDER_EMAIL: "email-login",
  LOGIN_PROVIDER_FACEBOOK: "facebook-login",
  CALCULATOR_STAMP_DUTY_FEE: "stamp-duty-fee",
  CALCULATOR_LEGAL_FEE: "legal-fee",
  VERSION: "v1",
  SECTION_PROPERTY_SALE: "sale",
  SECTION_PROPERTY_RENT: "rent",
  SECTION_PROPERTY_PROJECT: "project-listing",
  PROPERTY_SALE_ROUTE: "property-for-sale",
  PROPERTY_RENT_ROUTE: "property-for-rent",
  PROPERTY_SITUATION_ALL: "all",
  PROPERTY_SITUATION_DRAFT: "draft",
  PROPERTY_SITUATION_EXPIRED: "expired",
  PROPERTY_SITUATION_PRIVATE: "private",
  PROPERTY_SITUATION_ACTIVE: "active",
  PROPERTY_SITUATION_INACTIVE: "inactive",
  PROPERTY_STATUS_DRAFT: "draft",
  PROPERTY_STATUS_EXPIRED: "expired",
  PROPERTY_STATUS_PUBLISHED: "published",
  PROPERTY_TYPE_RESIDENTIAL: "residential",
  PROPERTY_TYPE_COMMERCIAL: "commercial",
  PROPERTY_TYPE_AGRICULTURAL: "agricultural",
  PROPERTY_TYPE_OTHERS: "others",
  PROPERTY_TYPE_INDUSTRIAL: "industrial",
  PROPERTY_TYPE_RESIDENTIAL_RESIDENTIAL_LAND: "resident-land",
  PROPERTY_TYPE_COMMERCIAL_HOTEL_RESORT: "hotel-resort",
  PROPERTY_TYPE_FIELD_BEDROOM:"bedRoom",
  PROPERTY_TYPE_FIELD_BATHROOM:"bathRoom",
  PROPERTY_TYPE_FIELD_FLOOR_LEVEL_CODE:"floorLevelCode",
  PROPERTY_TYPE_FIELD_LANDSIZE:"landSize",
  PROPERTY_TYPE_FIELD_FLOORSIZE:"floorSize",
  PROPERTY_TYPE_FIELD_PRICETYPE:"priceType",
  PROPERTY_TYPE_FIELD_TITLETYPE:"titleType",
  PROPERTY_TYPE_FIELD_BUMILOTTYPE:"bumiLotType",
  PROPERTY_TYPE_FIELD_CONDITION:"condition",
  PROPERTY_TYPE_FIELD_CEILING_HEIGHT:"ceilingHeight",
  PROPERTY_TYPE_FIELD_FLOOR_LOADING:"floorLoading",
  PROPERTY_TYPE_FIELD_PARKING_FEES:"parkingFees",
  PROPERTY_TYPE_FIELD_TOTAL_CARGO_LIFT:"totalCargoLift",
  PROPERTY_TYPE_FIELD_MAXIMUM_LIFT_CAPACITY:"maximumLiftCapacity",
  PROPERTY_TYPE_FIELD_AIR_CONDITIONING:"airConditioning",
  PROPERTY_TYPE_AIRCON_OPERATION_HOURS:"airconOperationHours",
  COOKIES_POPUP_DIALOG:"isPopUpBanner",
  DROPDOWN_MENU:"dropdown",
  DROPDOWN_PROPERTY_TOOLS:"property-tools",
  DROPDOWN_DIRECTORY:"directory",
  DOWNPAYMENT_TYPE_AMOUNT:"downpayment-amount",
  DOWNPAYMENT_TYPE_RATE:"downpayment-rate",
  COOKIES_REDIRECT_URL:"redirectUrl",
  CONTACT_TYPE_MOBILE_PHONE:"mobile-phone",
  PROPERTY_KIND_CODE:"property",
  PROJECT_KIND_CODE:"project",
  AGENT_SUBSCRIPTION_MAX_LISTING_KEY:"maxActiveListing",
  AGENT_SUBSCRIPTION_VALIDITY_DAYS:"validityDays",
  PROPERTY_TYPE_FIELD_USERLANDSIZE:"userLandSize",
  PROPERTY_TYPE_FIELD_USERLANDSIZEUOM:"userLandSizeUom",
  PROPERTY_TYPE_FIELD_USERFLOORSIZE:"userFloorSize",
  PROPERTY_TYPE_FIELD_USERFLOORSIZEUOM:"userFloorSizeUom",

  //======UserProperty update Constants=======
  PROPERTY_TYPE_FIELD_DESCRIPTION: "description",
  PROPERTY_TYPE_FIELD_BUILD_UP_AREA: "floorSize",

  //Page Slug
  PAGE_PROPERTY:"property",
  PAGE_PROJECT:"project",
  PAGE_PROJECT_LISTING:"project-listing",

  //==== For Prefix Country Number ====//
  PREFIX_COUNTRY_MALAYSIA: "+60",
  PREFIX_COUNTRY_SINGAPORE: "+65",

};
