/**
 * Copyright 2020 - present, Alpstein Technology Sdn Bhd.
 * All rights reserved.
 */

export const updateAccessToken = token => ({
  type: "auth/UPDATE_ACCESS_TOKEN",
  token
});


export const updateAccessTokenInLocal = token => ({
  type: "UPDATE_ACCESS_TOKEN",
  token
});

export const updateAgentAccessToken = token => ({
  type: "auth/UPDATE_AGENT_ACCESS_TOKEN",
  token
});

export const updateAgentAccessTokenInLocal = token => ({
  type: "UPDATE_AGENT_ACCESS_TOKEN",
  token
});


export const loginAccountWithPhoneRequest = (phone, password, rememberMe) => ({
  type: "auth/LOGIN_ACCOUNT_WITH_PHONE_REQUEST",
  phone,
  password,
  rememberMe
});

export const loginAccountWithPhoneSuccess = data => ({
  type: "LOGIN_ACCOUNT_WITH_PHONE_SUCCESS",
  data
});

export const loginAccountWithPhoneFailure = message => ({
  type: "LOGIN_ACCOUNT_WITH_PHONE_FAILURE",
  message
});


export const requestLogout = () => ({
  type: "auth/REQUEST_LOGOUT"
});

export const clearAccessToken = () => ({
  type: "CLEAR_ACCESS_TOKEN"
});

export const clearAgentAccessToken = () => ({
  type: "CLEAR_AGENT_ACCESS_TOKEN"
});

export const postAuthFirebaseRequest = (postData) =>({
  type:"auth/POST_AUTH_FIREBASE_REQUEST",
  postData
})

export const postAuthFirebaseSuccess = data => ({
  type:"POST_AUTH_FIREBASE_SUCCESS",
  data
})

export const postAuthFirebaseFailure = message => ({
  type:"POST_AUTH_FIREBASE_FAILURE",
  message
})

export const postVerifyPhoneNumberRequest = postData => ({
  type:"auth/POST_VERIFY_PHONE_NUMBER_REQUEST",
  postData
})

export const postVerifyPhoneNumberSuccess = data => ({
  type:"POST_VERIFY_PHONE_NUMBER_SUCCESS",
  data
})

export const postVerifyPhoneNumberFailure = messages => ({
  type:"POST_VERIFY_PHONE_NUMBER_FAILURE",
  messages
})

export const postVerifyPhoneNumberRequestInRoot = postData => ({
  type:"auth/POST_VERIFY_PHONE_NUMBER_REQUEST_IN_ROOT",
  postData
})

export const postVerifyPhoneNumberSuccessInRoot = data => ({
  type:"POST_VERIFY_PHONE_NUMBER_SUCCESS_IN_ROOT",
  data
})

export const postVerifyPhoneNumberFailureInRoot = messages => ({
  type:"POST_VERIFY_PHONE_NUMBER_FAILURE_IN_ROOT",
  messages
})

export const postAuthSignInRequest = postData => ({
  type:"auth/POST_AUTH_SIGN_IN_REQUEST",
  postData
})

export const postAuthSignInSuccess = data => ({
  type:"POST_AUTH_SIGN_IN_SUCCESS",
  data
})

export const postAuthSignInFailure = message => ({
  type:"POST_AUTH_SIGN_IN_FAILURE",
  message
})

export const postVerifyEmailRequest = postData => ({
  type:"auth/POST_VERIFY_EMAIL_REQUEST",
  postData
})

export const postVerifyEmailSuccess = data => ({
  type:"POST_VERIFY_EMAIL_SUCCESS",
  data
})

export const postVerifyEmailFailure = messages => ({
  type:"POST_VERIFY_EMAIL_FAILURE",
  messages
})




export const postAuthSignUpRequest = (postData) => ({
  type:"auth/POST_AUTH_SIGN_UP_REQUEST",
  postData
})

export const postAuthSignUpSuccess = (data) => ({
  type:"POST_AUTH_SIGN_UP_SUCCESS",
  data
})

export const postAuthSignUFailure = (messages) => ({
  type:"POST_AUTH_SIGN_UP_FAILURE",
  messages
})
