import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as searchActions from "../actions/search";
import _ from "lodash";

export default api => {
  function* watcherPostSearchListingRequest(
    { route = null, page = 1, postData = null, queryParam, suggestion= 1 },
    effects
  ) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postSearchListing, page, postData, queryParam, suggestion);

      const { items, _meta } = response.data;

      yield put(searchActions.postSearchListingSuccess(route, page, items, _meta, suggestion));
    } catch (error) {
      yield call(httpErrorHelpers, error, searchActions.postSearchListingFailure, effects);
    }
  }

  function* watcherPostSearchSuggestionRequest({ route, postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postSearchSuggestion, postData);

      const { items } = response.data;

      yield put(searchActions.postSearchSuggestionSuccess(route, items));
    } catch (error) {
      yield call(httpErrorHelpers, error, searchActions.postSearchSuggestionFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    search: null
  });

  return {
    namespace: "search",
    state: INITIAL_STATE,
    reducers: {
      POST_SEARCH_LISTING_REQUEST(state, action) {
        return state.setIn(["searchListing", "loading"], true);
      },
      POST_SEARCH_LISTING_SUCCESS(state, action) {
        return state
          .setIn(["searchListing", "loading"], false)
          .setIn(["searchListing", action.route, "items", action.page], action.data)
          .setIn(["searchListing", action.route, "pagination"], action.pagination);
      },
      POST_SEARCH_LISTING_FAILURE(state, action) {
        return state.setIn(["searchListing", "loading"], false);
      },
      SET_FILTER_DATA(state, action) {
        return state.setIn(["filterData", action.route], action.filterData);
      },
      SET_SORT_DATA(state, action) {
        return state.setIn(["sortData", action.route], action.sortData);
      },
      POST_SEARCH_SUGGESTION_REQUEST(state, action) {
        return state.setIn(["searchSuggestion", "loading"], true);
      },
      POST_SEARCH_SUGGESTION_SUCCESS(state, action) {
        return state
          .setIn(["searchSuggestion", "loading"], false)
          .setIn(["searchSuggestion", action.route], action.data);
      },
      POST_SEARCH_SUGGESTION_FAILURE(state, action) {
        return state.setIn(["searchSuggestion", "loading"], false);
      },
      CLEAR_SEARCH_SUGGESTION(state, action) {
        return state.setIn(["searchSuggestion", action.route], null);
      },
      CLEAR_FILTER_DATA(state) {
        return state.setIn(["filterData"], null);
      },
      CLEAR_SORT_DATA(state) {
        return state.setIn(["sortData"], null);
      },
      SET_COORDINATE_DATA(state, action) {
        return state.setIn(["coordinate"], action.coordinate);
      },
      CLEAR_COORDINATE_DATA(state, action) {
        return state.setIn(["coordinate"], null);
      },
      PUT_PROPERTY_DETAIL_WISHLIST_SUCCESS(state, action) {
        let targetPage = 1;
        const targetID = action.id;
        const targetItems = action.data;
        let newTargetItems = null;
        let targetIndex = -1;
        const searchListing = _.get(state, ["searchListing", action.route, "items"]);
        if (!_.isEmpty(searchListing)) {
          const totalPage = Object.keys(searchListing);
          _.forEach(totalPage, page => {
            const items = _.get(searchListing, [page], null);
            _.forEach(items, (item, index) => {
              const id = _.get(item, ["id"], null);
              if (_.isEqual(id, targetID)) {
                targetPage = page;
                targetIndex = index;
              }
            });
          });
        }
        if (targetIndex >= 0) {
          return state.setIn(["searchListing", action.route, "items", targetPage, targetIndex], targetItems);
        } else {
          return state.setIn(["searchListing"], state.searchListing);
        }
      },
      PUT_PROJECT_DETAIL_WISHLIST_SUCCESS(state, action) {
        let targetPage = 1;
        const targetID = action.id;
        const targetItems = action.data;
        let newTargetItems = null;
        let targetIndex = -1;
        const searchListing = _.get(state, ["searchListing", action.route, "items"]);
        if (!_.isEmpty(searchListing)) {
          const totalPage = Object.keys(searchListing);
          _.forEach(totalPage, page => {
            const items = _.get(searchListing, [page], null);
            _.forEach(items, (item, index) => {
              const id = _.get(item, ["id"], null);
              if (_.isEqual(id, targetID)) {
                targetPage = page;
                targetIndex = index;
              }
            });
          });
        }
        if (targetIndex >= 0) {
          return state.setIn(["searchListing", action.route, "items", targetPage, targetIndex], targetItems);
        } else {
          return state.setIn(["searchListing"], state.searchListing);
        }
      }
    },
    effects: {
      POST_SEARCH_LISTING_REQUEST: [watcherPostSearchListingRequest],
      POST_SEARCH_SUGGESTION_REQUEST: [watcherPostSearchSuggestionRequest]
    }
  };
};
