export const getBlogDetailRequest = slug => ({
  type: "insider-guide/GET_BLOG_DETAIL_REQUEST",
  slug
});

export const getBlogDetailSuccess = data => ({
  type: "GET_BLOG_DETAIL_SUCCESS",
  data
});

export const getBlogDetailFailure = message => ({
  type: "GET_BLOG_DETAIL_FAILURE",
  message
});

export const getBlogListRequest = (page, queryParam) => ({
  type: "insider-guide/GET_BLOG_LIST_REQUEST",
  page,
  queryParam
});

export const getBlogListSuccess = (items, _meta, category, tag, author) => ({
  type: "GET_BLOG_LIST_SUCCESS",
  items,
  _meta,
  category,
  tag,
  author
});

export const getBlogListFailure = message => ({
  type: "GET_BLOG_LIST_FAILURE",
  message
});
