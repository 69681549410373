export const getPostListingRequest = (page,category) => ({
    type:"post/GET_POST_LISTING_REQUEST",
    page,
    category
})

export const getPostListingSuccess = (page,data,pagination) => ({
    type:"GET_POST_LISTING_SUCCESS",
    page,
    data,
    pagination
})

export const getPostListingFailure = message => ({
    type:"GET_POST_LISTING_FAILURE",
    message
})

export const getPostDetailRequest = (id) => ({
    type:"post/GET_POST_DETAIL_REQUEST",
    id
})

export const getPostDetailSuccess = (id,data)=> ({
    type:"GET_POST_DETAIL_SUCCESS",
    id,data
})

export const getPostDetailFailure = message => ({
    type:"GET_POST_DETAIL_FAILURE",
    message
})

export const setPostCategory = category => ({
    type:"post/SET_POST_CATEGORY",
    category
})
