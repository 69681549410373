import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as authActions from "../actions/auth";
import * as passwordActions from "../actions/password";
import _ from "lodash";
import Toast from "../utils/Toast";

export default api => {
  function* watcherPostPasswordForgetRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postPasswordForget, postData);

      const { messages, item } = response.data;
      if(!_.isEmpty(messages[0].text)){
        Toast.success(messages[0].text)
      }
      yield put(passwordActions.postPasswordForgetSuccess(item));
    } catch (error) {
      yield call(httpErrorHelpers, error, passwordActions.postPasswordForgetFailure, effects);
    }
  }

  function* watcherPostPasswordResetRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postPasswordReset, postData);

      const { messages, item } = response.data;

      yield put(passwordActions.postPasswordResetSuccess(item));
    } catch (error) {
      yield call(httpErrorHelpers, error, passwordActions.postPasswordResetFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    password: null
  });

  return {
    namespace: "password",
    state: INITIAL_STATE,
    reducers: {
      POST_PASSWORD_FORGET_REQUEST(state) {
        return state.setIn(["passwordForget", "loading"], true).setIn(["passwordForget", "status"], false);
      },
      POST_PASSWORD_FORGET_SUCCESS(state, action) {
        return state
          .setIn(["passwordForget", "loading"], false)
          .setIn(["passwordForget", "status"], true)
          .setIn(["passwordForget", "data"], action.data);
      },
      POST_PASSWORD_FORGET_FAILURE(state) {
        return state.setIn(["passwordForget", "loading"], false).setIn(["passwordForget", "status"], false);
      },
      POST_PASSWORD_RESET_REQUEST(state) {
        return state.setIn(["passwordReset", "loading"], true).setIn(["passwordReset", "status"], false);
      },
      POST_PASSWORD_RESET_SUCCESS(state, action) {
        return state
          .setIn(["passwordReset", "loading"], false)
          .setIn(["passwordReset", "status"], true)
          .setIn(["passwordReset", "data"], action.data);
      },
      POST_PASSWORD_RESET_FAILURE(state) {
        return state.setIn(["passwordReset", "loading"], false).setIn(["passwordReset", "status"], false);
      }
    },
    effects: {
      POST_PASSWORD_FORGET_REQUEST: [watcherPostPasswordForgetRequest],
      POST_PASSWORD_RESET_REQUEST: [watcherPostPasswordResetRequest]
    }
  };
};
