import Immutable from "seamless-immutable";
import * as blogActions from "../actions/blog";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";

export default api => {
  function* watcherGetBlogDetailRequest({ slug }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getBlogDetail, slug);

      yield put(blogActions.getBlogDetailSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, blogActions.getBlogDetailFailure, effects);
    }
  }

  function* watcherGetBlogListRequest({ page = 1, queryParam }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getBlogList, page, queryParam);

      const { items, _meta, category, tag, author } = response.data;

      yield put(blogActions.getBlogListSuccess(items, _meta, category, tag, author));
    } catch (error) {
      yield call(httpErrorHelpers, error, blogActions.getBlogListFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    blog: null
  });

  return {
    namespace: "blog",
    state: INITIAL_STATE,
    reducers: {
      GET_BLOG_DETAIL_REQUEST(state, action) {
        return state.setIn(["loading"], true);
      },
      GET_BLOG_DETAIL_SUCCESS(state, action) {
        return state.setIn(["loading"], false).setIn(["blogDetail"], action.data);
      },
      GET_BLOG_DETAIL_FAILURE(state, action) {
        return state.setIn(["loading"], false);
      },
      GET_BLOG_LIST_REQUEST(state) {
        return state.setIn(["loading"], true);
      },
      GET_BLOG_LIST_SUCCESS(state, action) {
        const { currentPage } = action._meta;
        const { blogData } = state;
        return state
          .setIn(
            ["blogData", "items"],
            blogData && blogData.items && currentPage > 1 ? blogData.items.concat(action.items) : action.items
          )
          .setIn(["blogData", "_meta"], action._meta)
          .setIn(["blogData", "tag"], action.tag)
          .setIn(["blogData", "author"], action.author)
          .setIn(["blogData", "category"], action.category)
          .setIn(["loading"], false);
      },
      GET_BLOG_LIST_FAILURE(state) {
        return state.setIn(["loading"], false);
      }
    },
    effects: {
      GET_BLOG_DETAIL_REQUEST: [watcherGetBlogDetailRequest],
      GET_BLOG_LIST_REQUEST: [watcherGetBlogListRequest]
    }
  };
};
