import Immutable from "seamless-immutable";
import * as commonAction from "../actions/common";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as topUpAction from "../actions/topUp";
import _ from "lodash";
import * as orderActions from "../actions/order";
import * as agentAction from '../actions/agent'
import * as propertyActions from '../actions/property'
import {getOrderHistoryListFailure, getOrderListFailure, postOrderFreePaymentDetailSuccess} from '../actions/order'
import {getReferralCodeFailure} from "../actions/profile";
export default api => {
    function* watcherPostCreateOrderRequest({ postData }, effects) {
        const { call, put } = effects;
        try {
            const response = yield call(api.postCreateOrder, postData);

            yield put(orderActions.postCreateOrderSuccess(response.data));
        } catch (error) {
            yield call(httpErrorHelpers, error, orderActions.postCreateOrderFailure, effects);
        }
    }

    function* watcherGetOrderListRequest({ page = 1, filterData }, effects) {
        const { call, put, select } = effects;

        try {
            const startDate = _.get(filterData, ["start_date"], "");
            const endDate = _.get(filterData, ["end_date"], "");
            const response = yield call(api.getOrderList, page, startDate, endDate);

            const { items, _meta } = response.data;

            yield put(orderActions.getOrderListSuccess(items, _meta, page));
        } catch (error) {
            yield call(httpErrorHelpers, error, orderActions.getOrderListFailure, effects);
        }
    }

    function* watcherPostOrderPaymentDetailRequest({ id, postData }, effects) {
        const { call, put } = effects;
      try {
          const response = yield call(api.postOrderPaymentDetail, id, postData);

        const { payment } = response.data;
        yield put(orderActions.postOrderPaymentDetailSuccess(payment));
      } catch (error) {
        yield call(
          httpErrorHelpers,
          error,
          orderActions.postOrderPaymentDetailFailure,
          effects
        );
      }
    }
    // ===== Post order free payment for agent subscription =====
    function* watcherPostOrderFreePaymentDetailRequest({ id, postData }, effects) {
        const { call, put } = effects;
      try {
          const response = yield call(api.postOrderPaymentDetail, id, postData);

        const { payment } = response.data;
        yield put(orderActions.postOrderFreePaymentDetailSuccess(payment));
      } catch (error) {
        yield call(
          httpErrorHelpers,
          error,
          orderActions.postOrderFreePaymentDetailFailure,
          effects
        );
      }
    }
    function* watcherPostOrderPaymentReceiveDetailRequest({ postData }, effects) {
        const { call, put } = effects;
      try {
          const response = yield call(api.postOrderPaymentReceiveDetail, postData);

        const { payment } = response.data;
        yield put(orderActions.postOrderPaymentReceiveDetailSuccess(payment));
      } catch (error) {
        yield call(
          httpErrorHelpers,
          error,
          orderActions.postOrderPaymentReceiveDetailFailure,
          effects
        );
      }
    }
    function* watcherGetOrderHistoryListRequest({ page, listingStatus, createdAt }, effects) {
        const { call, put } = effects;
        try {
            const response = yield call(api.getOrderHistoryList,
              page,
              listingStatus,
              createdAt
            );

            const { items, _meta } = response.data;
            console.log(response.data)
            yield put(orderActions.getOrderHistoryListSuccess(items, _meta, page));
        } catch (error) {
            yield call(
              httpErrorHelpers,
              error,
              orderActions.getOrderListFailure,
              effects
            );
        }
    }



    const INITIAL_STATE = Immutable({
        commonData: null
    });

    return {
        namespace: "order",
        state: INITIAL_STATE,
        reducers: {
            POST_CREATE_ORDER_REQUEST(state, action) {
                return state.setIn(["createOrder", "loading"], true).setIn(["createOrder", "status"], false);
            },
            POST_CREATE_ORDER_SUCCESS(state, action) {
                return state.setIn(["createOrder", "loading"], false).setIn(["createOrder", "status"], true);
            },
            POST_CREATE_ORDER_FAILURE(state, action) {
                return state.setIn(["createOrder", "loading"], false).setIn(["createOrder", "status"], false);
            },
            GET_ORDER_LIST_REQUEST(state, action) {
                return state.setIn(["orderList", "loading"], true);
            },
            GET_ORDER_LIST_SUCCESS(state, action) {
                const { currentPage } = action.pagination;
                const { requestList } = state;

                return state
                    .setIn(["orderList", "items", action.page], action.data)
                    .setIn(["orderList", "pagination"], action.pagination)
                    .setIn(["orderList", "loading"], false);
            },
            GET_ORDER_LIST_FAILURE(state) {
                return state.setIn(["orderList", "loading"], false);
            },
            //Order subscription payment
            POST_ORDER_PAYMENT_DETAIL_REQUEST(state) {
                return state
                  .setIn(["orderPaymentDetail", "loading"], true)
                  .setIn(["orderPaymentDetail", "status"], false)
            },
            POST_ORDER_PAYMENT_DETAIL_SUCCESS(state, action) {
                return state
                  .setIn(["orderPaymentDetail", "loading"], false)
                  .setIn(["orderPaymentDetail", "status"], true)
                  .setIn(["orderPaymentDetail", "items"], action.data);
            },
            POST_ORDER_PAYMENT_DETAIL_FAILURE(state) {
                return state
                  .setIn(["orderPaymentDetail", "loading"], false)
                  .setIn(["orderPaymentDetail", "status"], false)
            },
            //Order Receive subscription payment
            POST_ORDER_PAYMENT_RECEIVE_DETAIL_REQUEST(state) {
                return state
                  .setIn(["orderReceivePaymentDetail", "loading"], true)
                  .setIn(["orderReceivePaymentDetail", "status"], false)
            },
            POST_ORDER_PAYMENT_RECEIVE_DETAIL_SUCCESS(state, action) {
                return state
                  .setIn(["orderReceivePaymentDetail", "loading"], false)
                  .setIn(["orderReceivePaymentDetail", "status"], true)
                  .setIn(["orderReceivePaymentDetail", "items"], action.data);
            },
            POST_ORDER_PAYMENT_RECEIVE_DETAIL_FAILURE(state) {
                return state
                  .setIn(["orderReceivePaymentDetail", "loading"], false)
                  .setIn(["orderReceivePaymentDetail", "status"], false)
            },
            // Get order payment history record
            GET_ORDER_HISTORY_LIST_REQUEST(state) {
                console.log(state)
                return state.setIn(["orderHistoryList", "loading"], true);
            },
            GET_ORDER_HISTORY_LIST_SUCCESS(state, action) {
                console.log(state, action)
                return state
                  .setIn(["orderHistoryList", "loading"], false)
                  .setIn(["orderHistoryList", "items", action.page], action.data);
            },
            GET_ORDER_HISTORY_LIST_FAILURE(state) {
                return state.setIn(["orderHistoryList", "loading"], false);
            },
        },
        effects: {
            GET_ORDER_LIST_REQUEST: [watcherGetOrderListRequest],
            POST_CREATE_ORDER_REQUEST: [watcherPostCreateOrderRequest],
            POST_ORDER_PAYMENT_DETAIL_REQUEST:[watcherPostOrderPaymentDetailRequest],
            POST_ORDER_FREE_PAYMENT_DETAIL_REQUEST:[watcherPostOrderFreePaymentDetailRequest],
            POST_ORDER_PAYMENT_RECEIVE_DETAIL_REQUEST:[watcherPostOrderPaymentReceiveDetailRequest],
            GET_ORDER_HISTORY_LIST_REQUEST: [watcherGetOrderHistoryListRequest],


        }
    };
};
