import Immutable from "seamless-immutable";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as wishlistActions from "../actions/wishlist";
import _ from "lodash";

export default api => {
  function* watcherGetWishlistListRequest({ page = 1 }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.getWishlistListing, page);

      const { items, _meta } = response.data;

      yield put(wishlistActions.getWishlistListSuccess(page, items, _meta));
    } catch (error) {
      yield call(httpErrorHelpers, error, wishlistActions.getWishlistListFailure, effects);
    }
  }

  const INITIAL_STATE = Immutable({
    wishlist: null
  });

  return {
    namespace: "wishlist",
    state: INITIAL_STATE,
    reducers: {
      GET_WISH_LIST_LIST_REQUEST(state, action) {
        return state.setIn(["wishlist", "loading"], true);
      },
      GET_WISH_LIST_LIST_SUCCESS(state, action) {
        return state
          .setIn(["wishlist", "loading"], false)
          .setIn(["wishlist", "items", action.page], action.data)
          .setIn(["wishlist", "pagination"], action.pagination);
      },
      GET_WISH_LIST_LIST_FAILURE(state, action) {
        return state.setIn(["wishlist", "loading"], false);
      },
      PUT_WISHLIST_SUCCESS(state, action) {
        let targetPage = 1;
        const targetID = action.id;
        const targetItems = action.data;
        let newTargetItems = null;
        let targetIndex = -1;
        const wishlistListing = _.get(state, ["wishlist", "items"]);
        if (!_.isEmpty(wishlistListing)) {
          const totalPage = Object.keys(wishlistListing);
          _.forEach(totalPage, page => {
            const items = _.get(wishlistListing, [page], null);
            _.forEach(items, (item, index) => {
              const id = _.get(item, ["id"], null);
              if (_.isEqual(id, targetID)) {
                targetPage = page;
                targetIndex = index;
              }
            });
          });
        }
        if (targetIndex >= 0) {
          return state.setIn(["wishlist", "items", targetPage, targetIndex], targetItems);
        } else {
          return state.setIn(["wishlist"], state.wishlist);
        }
      }
    },
    effects: {
      GET_WISH_LIST_LIST_REQUEST: [watcherGetWishlistListRequest]
    }
  };
};
