import _ from "lodash";

export const getAgentAccountStatus = state => _.get(state, ["agentAccount", "status"], false);
export const getAgentAccountLoading = state => _.get(state, ["agentAccount", "loading"], false);
export const getAgentAccountVerifyStatus = state => _.get(state, ["agentAccountVerify", "status"], false);
export const getAgentAccountVerifyLoading = state => _.get(state, ["agentAccountVerify", "loading"], false);
export const getAgentSearchAgencyLoading = state => _.get(state, ["agentSearchAgency", "loading"], false);
export const getAgentSearchAgencyItems = state => _.get(state, ["agentSearchAgency", "items"], []);
export const getAgentSearchAgentLoading = state => _.get(state, ["agentSearchAgent", "loading"], false);
export const getAgentSearchAgentItems = state => _.get(state, ["agentSearchAgent", "items"], []);
export const getAgentSearchProjectLoading = state => _.get(state, ["agentSearchProject", "loading"], false);
export const getAgentSearchProjectItems = state => _.get(state, ["agentSearchProject", "items"], []);
export const getAgentPostAgentListingLoading = state => _.get(state, ["postAgentListing", "loading"], false);

export const getAgentPostAgentListingStatus = state => _.get(state, ["postAgentListing", "status"], false);

export const getAgentPostAgentListingItem = state => _.get(state, ["postAgentListing", "item"], null);
export const getAgentPostAgentListingItems = (state, id) =>
  _.get(state, ["agentListingData", "items", id], null);
export const getAgentCurrentAgentID = state => _.get(state, ["currentAgentID"], null);
export const getAgentPutAgentListingLoading = state => _.get(state, ["putAgentListing", "loading"]);
export const getAgentListingLoading = state => _.get(state, ["agentListing", "loading"], false);
export const getAgentListingItems = state => _.get(state, ["agentListing", "items"], null);
export const getAgentListingPagination = state => _.get(state, ["agentListing", "pagination"], null);
export const getAgentPostListingItemsv2 = state => _.get(state, ["postAgentListing", "items"], null);
export const getAgentPostListingLoadingv2 = state => _.get(state, ["postAgentListing", "loading"], null);
export const getAgentPostListingPaginationv2 = state => _.get(state, ["postAgentListing", "pagination"], null);
export const getAgentPostListingStatusv2 = state => _.get(state, ["postAgentListing", "status"], null);
export const getAgentListingDetailLoading = state => _.get(state, ["agentListingDetail", "loading"], false);
export const getAgentCommonDataEnum = state => _.get(state, ["agentCommonData", "enum"], null);
export const getAgentCommonDataOptionsAgentLeadStatus = state => _.get(state, ["agentCommonData", "options","agentLeadStatus"], null);
export const getAgentCommonDataOptionsAgentSubscriptions = state => _.get(state, ["agentCommonData", "options","subscriptions"], null);
export const getAgentPutAgentListingStatus = state => _.get(state, ["putAgentListing", "status"], false);
export const getAgentListingImageLoading = state => _.get(state, ["agentListingImage", "loading"], null);
export const getAgentListingImage = state => _.get(state, ["agentListingImage", "image"], null);
export const getAgentDeleteListingImageLoading = state =>
  _.get(state, ["deleteListingImage", "loading"], null);
export const getAgentDeleteListingImageData = state => _.get(state, ["deleteListingImage", "image"], null);
export const getAgentPostDetailPublishLoading = state =>
  _.get(state, ["postDetailPublish", "loading"], false);
export const getAgentPostDetailPublishStatus = state => _.get(state, ["postDetailPublish", "status"], false);
export const getAgentListImageDefaultLoading = state =>
  _.get(state, ["agentListingImageDefault", "loading"], null);
export const getAgentListImageDefaultData = state =>
  _.get(state, ["agentListingImageDefault", "image"], null);
export const getAgentListImageDefaultStatus = state =>
  _.get(state, ["agentListingImageDefault", "status"], null);
export const getAgentSearchStateLoading = state => _.get(state, ["postAgentSearchState", "loading"], false);
export const getAgentSearchStateItems = state => _.get(state, ["postAgentSearchState", "items"], []);
export const getAgentSearchCityLoading = state => _.get(state, ["postAgentSearchCity", "loading"], false);
export const getAgentSearchCityItems = state => _.get(state, ["postAgentSearchCity", "items"], []);
export const getAgentProjectCreateLoading = state =>
  _.get(state, ["postAgentProjectCreate", "loading"], false);
export const getAgentProjectCreateStatus = state => _.get(state, ["postAgentProjectCreate", "status"], false);
export const getAgentProjectCreateItems = state => _.get(state, ["postAgentProjectCreate", "items"], []);
export const getAgentLeadLoading = state => _.get(state, ["agentLead", "loading"], false);
export const getAgentLeadItems = state => _.get(state, ["agentLead", "items"], null);
export const getAgentLeadPagination = state => _.get(state, ["agentLead", "pagination"], null);
export const getPutAgentLeadDetailLoading = state => _.get(state, ["putAgentLeadDetail", "loading"], false);
export const getPutAgentLeadDetailStatus = state => _.get(state, ["putAgentLeadDetail", "status"], false);
export const getAgentCommonStateData = state => _.get(state, ["agentCommonState"], []);
export const getAgentCommonCityData = state => _.get(state, ["agentCommonCity"], []);

//agent item
export const getAgentSearchID = agent => _.get(agent, ["id"], null);
export const getAgentSearchName = agent => _.get(agent, ["name"], "");
export const getAgentSearchRegistrationNumber = agent => _.get(agent, ["registrationNumber"], "");

//agent listing
export const getAgentListingID = agent => _.get(agent, ["id"], null);
export const getAgentListingName = agent => _.get(agent, ["name"], null);
export const getAgentListingKindName = agent => _.get(agent, ["kind", "name"], null);

//agent
export const getAgentDetailID = agent => _.get(agent, ["id"], null);
export const getAgentDetailSlug = agent => _.get(agent, ["slug"], null);
export const getAgentDetailName = agent => _.get(agent, ["name"], null);

export const getAgentDetailImageMediumSrc = agent => _.get(agent, ["image", "medium", "src"], null);
export const getAgentDetailImageHugeSrc = agent => _.get(agent, ["image", "huge", "src"], null);
export const getAgentDetailKindCode = agent => _.get(agent, ["kind", "code"], null);
export const getAgentDetailKindName = agent => _.get(agent, ["kind", "name"], null);
export const getAgentDetailTypeCode = agent => _.get(agent, ["type", "code"], null);
export const getAgentDetailTypeName = agent => _.get(agent, ["type", "name"], null);
export const getAgentDetailCategoryCode = agent => _.get(agent, ["category", "code"], null);
export const getAgentDetailCategoryName = agent => _.get(agent, ["category", "name"], null);
export const getAgentDetailTenureCode = agent => _.get(agent, ["tenure", "code"], null);
export const getAgentDetailTenureName = agent => _.get(agent, ["tenure", "name"], null);
export const getAgentDetailBuildStartValueText = agent =>
  _.get(agent, ["build", "start", "value", "text"], null);
export const getAgentDetailBuildStartValueRelative = agent =>
  _.get(agent, ["build", "start", "value", "relative"], null);
export const getAgentDetailBuildStartText = agent => _.get(agent, ["build", "start", "text"], "");
export const getAgentDetailBuildEndValueText = agent => _.get(agent, ["build", "end", "value", "text"], null);
export const getAgentDetailBuildEndValueRelative = agent =>
  _.get(agent, ["build", "end", "value", "relative"], null);
export const getAgentDetailBuildEndText = agent => _.get(agent, ["build", "end", "text"], "");
export const getAgentDetailRoomBedText = agent => _.get(agent, ["room", "bed", "text"], "");
export const getAgentDetailRoomBedValue = agent => _.get(agent, ["room", "bed", "value"], "");
export const getAgentDetailRoomBathText = agent => _.get(agent, ["room", "bath", "text"], "");
export const getAgentDetailRoomBathValue = agent => _.get(agent, ["room", "bath", "value"], "");
export const getAgentDetailParkingPrimaryText = agent => _.get(agent, ["parking", "primary", "text"], "");
export const getAgentDetailParkingPrimaryValue = agent => _.get(agent, ["parking", "primary", "value"], "");
export const getAgentDetailSizeFloorText = agent => _.get(agent, ["size", "floor", "text"], "");
export const getAgentDetailStatusName = agent => _.get(agent, ["status", "name"], "");
export const getAgentDetailCreateAtRelative = agent => _.get(agent, ["createAt", "relative"], "");
export const getAgentDetailExpiredAtRelative = agent => _.get(agent, ["expiredAt", "relative"], "-");
export const getAgentDetailPublishedAtRelative = agent => _.get(agent, ["publishedAt", "relative"], "-");
export const getAgentDetailSizeFloorValue = agent => _.get(agent, ["size", "floor", "value"], "");
export const getAgentDetailSizeFloorUom = agent => _.get(agent, ["size", "floor", "uom"], "");
export const getAgentDetailSaleObject = agent => _.get(agent, ["sale"], null);
export const getAgentDetailRentObject = agent => _.get(agent, ["rent"], null);
export const getAgentDetailRentOrSalePriceText = agent => _.get(agent, ["price", "text"], "-");
export const getAgentDetailSizeLandText = agent => _.get(agent, ["size", "land", "text"], "");
export const getAgentDetailSizeLandValue = agent => _.get(agent, ["size", "land", "value"], "");
export const getAgentDetailSizeLandUom = agent => _.get(agent, ["size", "land", "uom"], "");
export const getAgentDetailFurnishingCode = agent => _.get(agent, ["furnishing", "code"], null);
export const getAgentDetailFurnishingName = agent => _.get(agent, ["furnishing", "name"], null);
export const getAgentDetailFloorLevelCode = agent => _.get(agent, ["floorLevel", "code"], null);
export const getAgentDetailFloorLevelName = agent => _.get(agent, ["floorLevel", "name"], null);
export const getAgentDetailTagItems = agent => _.get(agent, ["tag", "items"], []);
export const getAgentDetailCountryName = agent => _.get(agent, ["country", "name"], "");
export const getAgentDetailCountryCode = agent => _.get(agent, ["country", "code"], "");
export const getAgentDetailStateName = agent => _.get(agent, ["state", "name"], "");
export const getAgentDetailStateCode = agent => _.get(agent, ["state", "code"], "");
export const getAgentDetailCityName = agent => _.get(agent, ["city", "name"], "");
export const getAgentDetailCityID = agent => _.get(agent, ["city", "id"], "");
export const getAgentDetailPostCode = agent => _.get(agent, ["postcode"], null);
export const getAgentDetailCoordinates = agent => _.get(agent, ["coordinates"], null);
export const getAgentDetailAttributeIsImageReady = agent =>
  _.get(agent, ["attribute", "isImageReady"], false);
export const getAgentDetailSalePriceText = agent => _.get(agent, ["sale", "price", "text"], "");
export const getAgentDetailSalePriceValue = agent => _.get(agent, ["sale", "price", "value"], 0);
export const getAgentDetailPerSizeText = agent => _.get(agent, ["perSize", "price", "text"], "");
export const getAgentDetailPerSizeValue = agent => _.get(agent, ["perSize", "price", "value"], null);
export const getAgentDetailRentPriceText = agent => _.get(agent, ["rent", "price", "text"], null);
export const getAgentDetailRentPriceValue = agent => _.get(agent, ["rent", "price", "value"], 0);
export const getAgentDetailContent = agent => _.get(agent, ["content"], "");
export const getAgentDetailImages = agent => _.get(agent, ["images"], null);
export const getAgentDetailFeatureItems = agent => _.get(agent, ["feature", "items"], []);
export const getAgentDetailAmenityItems = agent => _.get(agent, ["amenity", "items"], []);
export const getAgentDetailUnitType = agent => _.get(agent, ["unitType", "items"], []);
export const getAgentAccountID = agent => _.get(agent, ["account", "id"], null);
export const getAgentAccountLicenseName = agent => _.get(agent, ["account", "licenseName"], null);
export const getAgentAccountLicenseNumber = agent => _.get(agent, ["account", "licenseNumber"], null);
export const getAgentAccountLicenseSinceYear = agent => _.get(agent, ["account", "licenseSinceYear"], null);
export const getAgentAccountName = agent => _.get(agent, ["account", "name"], null);
export const geAgentAttributeIsPublishable = agent => _.get(agent, ["attribute", "isPublishable"], false);
export const geAgentAttributeIsExpired = agent => _.get(agent, ["attribute", "isExpired"], false);
export const geAgentDetailVideoUrl = agent => _.get(agent, ["video", "url"], "");

export const geAgentDetailTikTokUrl = agent => _.get(agent, ["tiktok", "url"], "");
export const getAgentSituationCode = agent => _.get(agent, ["situation", "code"], "");
export const getAgentSituationName = agent => _.get(agent, ["situation", "name"], "");
export const getAgentPriceTypeCode = agent => _.get(agent, ["priceType", "code"], "");
export const getAgentPriceTypeName = agent => _.get(agent, ["priceType", "name"], "");
export const getAgentTitleTypeCode = agent => _.get(agent, ["titleType", "code"], "");
export const getAgentTitleTypeName = agent => _.get(agent, ["titleType", "name"], "");
export const getAgentRequiredFields = agent => _.get(agent, ["requiredFields"], []);
export const getAgentBumiLotTypeCode = agent => _.get(agent, ["bumiLotType", "code"], "");
export const getAgentBumiLotTypeName = agent => _.get(agent, ["bumiLotType", "name"], "");
export const getAgentCondition = agent => _.get(agent, ["condition"], "");
export const getAgentCeilingHeight = agent => _.get(agent, ["ceilingHeight"], "");
export const getAgentFloorLoading = agent => _.get(agent, ["floorLoading"], "");
export const getAgentParkingFees = agent => _.get(agent, ["parkingFees"], "");
export const getAgentTotalCargoLift = agent => _.get(agent, ["totalCargoLift"], "");
export const getAgentMaximumLiftCapacity = agent => _.get(agent, ["maximumLiftCapacity"], "");
export const getAgentAirConditioning = agent => _.get(agent, ["airConditioning"], "");
export const getAgentAirconOperationHours = agent => _.get(agent, ["airconOperationHours"], "");
export const getAgentBuildStart = agent => _.get(agent, ["build", "0", "start", "value", "value"], "");
export const getAgentBuildEnd = agent => _.get(agent, ["build", "0", "end", "value", "value"], "");

export const getAgentIsSale = agent => !_.isEmpty(getAgentDetailSaleObject(agent));
export const getAgentIsRent = agent => !_.isEmpty(getAgentDetailRentObject(agent));

export const getAgentDetailAttributeIsActive = agent => _.get(agent, ["attribute", "isActive"], false);

export const getAgentDetailViewTotal = agent => _.get(agent, ["_metadata", "view", "total"], "");
export const getAgentRemark = agent => _.get(agent, ["remark"], "");

//common
export const getAgentCommonAmenityItems = common => _.get(common, ["amenity", "items"], []);
export const getAgentCommonBuildStatusItems = common => _.get(common, ["buildStatus", "items"], []);
export const getAgentCommonFloorLevelItems = common => _.get(common, ["floorLevel", "items"], []);
export const getAgentCommonFurnishingItems = common => _.get(common, ["furnishing", "items"], []);
export const getAgentCommonPetPolicyItems = common => _.get(common, ["petPolicy", "items"], []);
export const getAgentCommonBedRoomItems = common => _.get(common, ["bedRoom", "items"], []);
export const getAgentCommonBathRoomItems = common => _.get(common, ["bathRoom", "items"], []);
export const getAgentCommonTenureItems = common => _.get(common, ["tenure", "items"], []);
export const getAgentCommonPriceTypeItems = common => _.get(common, ["priceType", "items"], []);
export const getAgentCommonPropertyTitleItems = common => _.get(common, ["propertyTitle", "items"], []);
export const getAgentCommonBumiLotItems = common => _.get(common, ["bumiLot", "items"], []);

//agent name
export const getAgentDetailLicenseName = agent => _.get(agent, ["licenseName"], "");
export const getAgentDetailDescription = agent => _.get(agent, ["description"], "");
export const getAgentDetailLicenseNumber = agent => _.get(agent, ["licenseNumber"], "");
export const getAgentDetailLicenseSinceYear = agent => _.get(agent, ["licenseSinceYear"], "");
export const getAgentDetailListings = agent => _.get(agent, ["listings"], []);
export const getAgentDetailContactItems = agent => _.get(agent, ["contact", "items"], []);
export const getAgentDetailMetadataActiveListingCount= agent => _.get(agent, ["_metadata", "activeListingCount"], 0);
export const getAgentDetailMetadataActiveRentListingCount= agent => _.get(agent, ["_metadata", "activeRentListingCount"], 0);
export const getAgentDetailMetadataActiveSaleListingCount= agent => _.get(agent, ["_metadata", "activeSaleListingCount"], 0);
export const getAgentDetailMetadataMobileVerify= agent => _.get(agent, ["_metadata", "isMobileVerified"], 0);
export const getAgentDetailMetadataRenVerify= agent => _.get(agent, ["_metadata", "isRenVerified"], 0);

export const getAgentDetailPublisherName = agent => _.get(agent,['publisher','name'],"")
export const getAgentDetailRegistrationNumber = agent => _.get(agent,['publisher','registrationNumber'],"")
export const getAgentDetailMediumProfileImage = agent => _.get(agent,['publisher','image', 'medium', 'src'],"")

//agent lead
export const getAgentLeadDetailID = agentLead => _.get(agentLead,['id'],null)
export const getAgentLeadDetailStatus = agentLead => _.get(agentLead,['status'],"")
export const getAgentLeadDetailSource= agentLead => _.get(agentLead,['source'],"")
export const getAgentLeadDetailListingId= agentLead => _.get(agentLead,['listingId'],"")
export const getAgentLeadDetailSourceDetailsTitle = agentLead => _.get(agentLead,['sourceDetails','title'],"")
export const getAgentLeadDetailName = agentLead => _.get(agentLead,['name'],"")
export const getAgentLeadDetailEmail = agentLead => _.get(agentLead,['email'],"")
export const getAgentLeadDetailComplete = agentLead => _.get(agentLead,['complete'],"")
export const getAgentLeadDetailRemark = agentLead => _.get(agentLead,['remark'],"")
export const getAgentLeadDetailCreatedAtText= agentLead => _.get(agentLead,['createAt','text'],"")

//agent subscription
export const getAgentSubscriptionBrief = agentSubscription => _.get(agentSubscription,['brief'],"")
export const getAgentSubscriptionCurrencyCode = agentSubscription => _.get(agentSubscription,['currency','code'],"")
export const getAgentSubscriptionID = agentSubscription => _.get(agentSubscription,['id'],"")
export const getAgentSubscriptionItems = agentSubscription => _.get(agentSubscription,['items'],"")
export const getAgentSubscriptionFeatures = agentSubscription => _.get(agentSubscription,['features'],"")
export const getAgentSubscriptionName = agentPackage => _.get(agentPackage,['name'],"")
export const getAgentSubscriptionDisplayName = agentSubscription => _.get(agentSubscription,['display_name'],"")
export const getAgentSubscriptionRetailPriceText = agentSubscription => _.get(agentSubscription,['retailPrice','text'],"")
export const getAgentSubscriptionRetailPriceValue = agentSubscription => _.get(agentSubscription,['retailPrice','value'],"")
export const getAgentSubscriptionSellingPriceText = agentSubscription => _.get(agentSubscription,['sellingPrice','text'],"")
export const getAgentSubscriptionSellingPriceValue = agentSubscription => _.get(agentSubscription,['sellingPrice','value'],"")
export const getAgentSubscriptionisCampaign = agentSubscription => _.get(agentSubscription,['attribute','isCampaign'],"")

//agentSubscription
export const getAgentSelectedItems = agentPayment => _.get(agentPayment, ['items'], "");
export const getAgentSubscriptionLoading = state => _.get(state, ["agentSubscriptionPackage", "loading"], false);

export const getAgentSubscriptionStatus = state => _.get(state, ["agentSubscriptionPackage", "status"], false);

export const getAgentSubscriptionItem = (state) => _.get(state, ["agentSubscriptionPackage", "items"], null);