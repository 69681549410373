export const getPropertyListRequest = (route='',page =1) => ({
    type:"property/GET_PROPERTY_LIST_REQUEST",
    route,
    page
})

export const getPropertyListSuccess = (route='',page,data,pagination) => ({
    type:"GET_PROPERTY_LIST_SUCCESS",
    route,
    page,
    data,
    pagination
})

export const getPropertyListFailure = message => ({
    type:"GET_PROPERTY_LIST_FAILURE",
    message
})

export const getPropertyDetailRequest = id => ({
    type:"property/GET_PROPERTY_DETAIL_REQUEST",
    id
})

export const getPropertyDetailSuccess = (id,data)=>({
    type:"GET_PROPERTY_DETAIL_SUCCESS",
    id,
    data
})

export const getPropertyDetailFailure = message => ({
    type:"GET_PROPERTY_DETAIL_FAILURE",
    message
})

export const putPropertyDetailWishlistRequest = (id,route) => ({
    type:"property/PUT_PROPERTY_DETAIL_WISHLIST_REQUEST",
    id,
    route
})

export const putPropertyDetailWishlistSuccess = (id,data,route) => ({
    type:"PUT_PROPERTY_DETAIL_WISHLIST_SUCCESS",
    id,data,route
})

export const putPropertyDetailWishlistFailure = messages => ({
    type:"PUT_PROPERTY_DETAIL_WISHLIST_FAILURE",
    messages
})


//Property
export const putAgentListingDataRequest = (id, putData) => ({
    type: "property/PUT_AGENT_LISTING_DATA_REQUEST",
    id,
    putData
});

export const postUserPropertyListingSuccess = (id, data) => ({
    type: "POST_USER_PROPERTY_LISTING_SUCCESS",
    id,
    data
});

export const postUserPropertyListingFailure = message => ({
    type: "POST_USER_PROPERTY_LISTING_FAILURE",
    message
});

export const postUserPropertyProjectCreateRequest = postData => ({
    type: "property/POST_USER_PROPERTY_PROJECT_CREATE_REQUEST",
    postData
});

export const postUserPropertyProjectCreateSuccess = data => ({
    type: "POST_USER_PROPERTY_PROJECT_CREATE_SUCCESS",
    data
});

export const postUserPropertyProjectCreateFailure = message => ({
    type: "POST_USER_PROPERTY_PROJECT_CREATE_FAILURE",
    message
});





export const putAgentListingDetailImageRequest = (id,putData) => ({
    type:"property/PUT_AGENT_LISTING_DETAIL_IMAGE_REQUEST",
    id,putData
})

export const putAgentListingDetailImageSuccess = data => ({
    type:"PUT_AGENT_LISTING_DETAIL_IMAGE_SUCCESS",
    data
})

export const putAgentListingDetailImageFailure = message => ({
    type:"PUT_AGENT_LISTING_DETAIL_IMAGE_FAILURE",
    message
})

export const postUserPropertySearchProjectRequest = postData => ({
    type: "property/POST_USER_PROPERTY_SEARCH_PROJECT_REQUEST",
    postData
});

export const postUserPropertySearchProjectSuccess = data => ({
    type: "POST_USER_PROPERTY_SEARCH_PROJECT_SUCCESS",
    data
});
export const postUserPropertySearchProjectFailure = message => ({
    type: "POST_USER_PROPERTY_SEARCH_PROJECT_FAILURE",
    message
});
export const postUserPropertySearchStateRequest = postData => ({
    type: "property/POST_USER_PROPERTY_SEARCH_STATE_REQUEST",
    postData
});
export const postUserPropertySearchStateSuccess = data => ({
    type: "POST_USER_PROPERTY_SEARCH_STATE_SUCCESS",
    data
});
export const postUserPropertySearchStateFailure = message => ({
    type: "POST_USER_PROPERTY_SEARCH_STATE_FAILURE",
    message
});
export const postUserPropertyListingRequest = postData => ({
    type: "property/POST_USER_PROPERTY_LISTING_REQUEST",
    postData
});
export const postUserPropertySearchCityRequest = postData => ({
    type: "property/POST_USER_PROPERTY_SEARCH_CITY_REQUEST",
    postData
});

export const postUserPropertySearchCitySuccess = data => ({
    type: "POST_USER_PROPERTY_SEARCH_CITY_SUCCESS",
    data
});

export const postUserPropertySearchCityFailure = message => ({
    type: "POST_USER_PROPERTY_SEARCH_CITY_FAILURE",
    message
});
export const setCurrentUserPropertyProjectID = id => ({
    type: "property/SET_CURRENT_USER_PROPERTY_PROJECT_ID",
    id
});
//Property put data
export const putUserPropertyListingDataRequest = (id, putData) => ({
    type: "property/PUT_USER_PROPERTY_LISTING_DATA_REQUEST",
    id,
    putData
});

export const putUserPropertyListingDataSuccess = (id, data) => ({
    type: "PUT_USER_PROPERTY_LISTING_DATA_SUCCESS",
    id,
    data
});

export const putUserPropertyListingDataFailure = message => ({
    type: "PUT_USER_PROPERTY_LISTING_DATA_FAILURE",
    message
});

export const getUserPropertyListingDetailRequest = id => ({
    type: "property/GET_USER_PROPERTY_LISTING_DETAIL_REQUEST",
    id
});

export const getUserPropertyListingDataSuccess = (id, data) => ({
    type: "GET_USER_PROPERTY_LISTING_DETAIL_SUCCESS",
    id,
    data
});

export const getUserPropertyListingDataFailure = message => ({
    type: "GET_USER_PROPERTY_LISTING_DETAIL_FAILURE",
    message
});

export const putUserPropertyListingDetailImageRequest = (id,putData) => ({
    type:"property/PUT_USER_PROPERTY_LISTING_DETAIL_IMAGE_REQUEST",
    id,putData
})

export const putUserPropertyListingDetailImageSuccess = data => ({
    type:"PUT_USER_PROPERTY_LISTING_DETAIL_IMAGE_SUCCESS",
    data
})

export const putUserPropertyListingDetailImageFailure = message => ({
    type:"PUT_USER_PROPERTY_LISTING_DETAIL_IMAGE_FAILURE",
    message
})

export const postUserPropertyListingDataImageDefaultRequest = id => ({
    type: "property/POST_USER_PROPERTY_LISTING_DATA_IMAGE_DEFAULT_REQUEST",
    id
});

export const postUserPropertyListingDataImageDefaultSuccess = id => ({
    type: "POST_USER_PROPERTY_LISTING_DATA_IMAGE_DEFAULT_SUCCESS",
    id
});

export const postUserPropertyListingDataImageDefaultFailure = (id, message) => ({
    type: "POST_USER_PROPERTY_LISTING_DATA_IMAGE_DEFAULT_FAILURE",
    id,
    message
});

export const deleteUserPropertyListingImageDataRequest = id => ({
    type: "property/DELETE_USER_PROPERTY_LISTING_IMAGE_DATA_REQUEST",
    id
});

export const deleteUserPropertyListingImageDataSuccess = id => ({
    type: "DELETE_USER_PROPERTY_LISTING_IMAGE_DATA_SUCCESS",
    id
});

export const deleteUserPropertyListingImageDataFailure = id => ({
    type: "DELETE_USER_PROPERTY_LISTING_IMAGE_DATA_FAILURE",
    id
});

export const postUserPropertyListingDataImageRequest = (id, postData, randomID) => ({
    type: "property/POST_USER_PROPERTY_LISTING_DATA_IMAGE_REQUEST",
    id,
    postData,
    randomID
});

export const postUserPropertyListingDataImageSuccess = (id, data, randomID) => ({
    type: "POST_USER_PROPERTY_LISTING_DATA_IMAGE_SUCCESS",
    id,
    data,
    randomID
});

export const postUserPropertyListingDataImageFailure = (randomID, message) => ({
    type: "POST_USER_PROPERTY_LISTING_DATA_IMAGE_FAILURE",
    randomID,
    message
});
//Price
export const putUserPropertyListingPriceRequest = (id,putData) => ({
    type:"property/PUT_USER_PROPERTY_LISTING_PRICE_REQUEST",
    id,putData
})

export const putUserPropertyListingPriceSuccess = data => ({
    type:"PUT_USER_PROPERTY_LISTING_PRICE_SUCCESS",
    data
})

export const putUserPropertyListingPriceFailure = message => ({
    type:"PUT_USER_PROPERTY_LISTING_PRICE_FAILURE",
    message
})
// UserProperty Status
export const putUserPropertyStatusRequest = (id,putData) => ({
    type:"property/PUT_USER_PROPERTY_STATUS_REQUEST",
    id,putData
})

export const putUserPropertyStatusSuccess = data => ({
    type:"PUT_USER_PROPERTY_STATUS_SUCCESS",
    data
})

export const putUserPropertyStatusFailure = message => ({
    type:"PUT_USER_PROPERTY_STATUS_FAILURE",
    message
})
// UserProperty Data Filter
export const getUserPropertyDataFilterRequest = (page,propertyStatus) => ({
    type:"property/GET_USER_PROPERTY_DATA_FILTER_REQUEST",
   page,propertyStatus
})

export const getUserPropertyDataFilterSuccess = (page,propertyStatus,data,pagination) => ({
    type:"GET_USER_PROPERTY_DATA_FILTER_SUCCESS",
    page,propertyStatus,
    data,
    pagination
})

export const getUserPropertyDataFilterFailure = message => ({
    type:"GET_USER_PROPERTY_DATA_FILTER_FAILURE",
    message
})

export const putPropertyDetailStatusRequest = (id,putData) => ({
    type:"property/PUT_PROPERTY_DETAIL_STATUS_REQUEST",
    id,putData
})

export const putPropertyDetailStatusSuccess = (id,data) => ({
    type:"PUT_PROPERTY_DETAIL_STATUS_SUCCESS",
    id,data
})

export const putPropertyDetailStatusFailure = messages => ({
    type:"PUT_PROPERTY_DETAIL_STATUS_FAILURE",
    messages
})

export const getMyPropertyListingRequest = (page) => ({
    type:"property/GET_MY_PROPERTY_LISTING_REQUEST",
    page
})

export const getMyPropertyListingSuccess = (page,data,pagination) => ({
    type:"GET_MY_PROPERTY_LISTING_SUCCESS",
    page,
    data,
    pagination
})

export const getMyPropertyListingFailure = message => ({
    type:"GET_MY_PROPERTY_LISTING_FAILURE",
    message
})

export const getPropertyMeStatisticRequest = () => ({
    type:"property/GET_PROPERTY_ME_STATISTIC_REQUEST"
})

export const getPropertyMeStatisticSuccess = data => ({
    type:"GET_PROPERTY_ME_STATISTIC_SUCCESS",
    data
})

export const getPropertyMeStatisticFailure = messages => ({
    type:"GET_PROPERTY_ME_STATISTIC_FAILURE",
    messages
})
