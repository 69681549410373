import _ from "lodash";

export const getProfileData = state => _.get(state, ["profile"], null);
export const getProfileLoading = state => _.get(state, ["loading"], false);
export const getProfileChangePasswordStatus = state => _.get(state, ["changePassword",'status'], false);
export const getProfileChangePasswordLoading = state => _.get(state, ["changePassword",'loading'], false);
export const getProfileAvatarLoading = state => _.get(state,['avatarLoading'],false)
export const getProfileUpdateProfileLoading = state => _.get(state,['updateProfileLoading'],false)
export const getAgentProfileLoading = state => _.get(state,['agentProfile','loading'],false)
export const getAgentProfileData= (state,id) => _.get(state,['agentProfile',id],null)
export const getProfileBindPhoneNumberStatus= (state) => _.get(state, ["bindPhoneNumber", "status"], false);
export const getAccountListAgentLoading = state => _.get(state,['accountListAgent','loading'],false)
export const getAccountListAgentItems = state => _.get(state,['accountListAgent','items'],null)
export const getAccountListAgentPagination = state => _.get(state,['accountListAgent','pagination'],null)
export const getAccountListDeveloperLoading = state => _.get(state,['accountListDeveloper','loading'],false)
export const getAccountListDeveloperItems = state => _.get(state,['accountListDeveloper','items'],null)
export const getAccountListDeveloperPagination = state => _.get(state,['accountListDeveloper','pagination'],null)
export const getProfileChangePhoneNumberLoading = state => _.get(state,['changePhone','loading'],false)
export const getProfileChangePhoneNumberStatus = state => _.get(state,['changePhone','status'],false)
export const getProfileChangeEmailLoading = state => _.get(state,['changeEmail','loading'],false)
export const getProfileChangeEmailStatus = state => _.get(state,['changeEmail','status'],false)
export const getAgentListSaleDetailLoading = state => _.get(state,['agentListSale','loading'],false)
export const getAgentListSaleDetailItems = state => _.get(state,['agentListSale','items'],null)
export const getAgentListSaleDetailPagination = state => _.get(state,['agentListSale','pagination'],null)
export const getAgentListRentDetailLoading = state => _.get(state,['agentListRent','loading'],false)
export const getAgentListRentDetailItems = state => _.get(state,['agentListRent','items'],null)
export const getAgentListRentDetailPagination = state => _.get(state,['agentListRent','pagination'],null)
export const getDeveloperListDetailLoading = state => _.get(state,['developerList','loading'],false)
export const getDeveloperListDetailItems = state => _.get(state,['developerList','items'],null)
export const getDeveloperListDetailPagination = state => _.get(state,['developerList','pagination'],null)

//profile
export const getProfileID = profile => _.get(profile, ["id"], null);
export const getProfileName = profile => _.get(profile, ["name"], null);
export const getProfileDisplayName = profile => _.get(profile, ["displayName"], null);
export const getProfileAvatarLargeSrc = profile => _.get(profile, ["avatar", "large", "src"], null);
export const getProfileUsername = profile => _.get(profile,['username'],null)
export const getProfileFirstName = profile => _.get(profile,['firstName'],null)
export const getProfileLastName = profile => _.get(profile,['lastName'],null)
export const getProfleDisplayName = profile => _.get(profile,['displayName'],null)
export const getProfileCountry = profile => _.get(profile,['country','code'],null)
export const getProfileCountryName = profile => _.get(profile,['country','name'],null)
export const getProfileEmailAddress = profile => _.get(profile,['email','address'],null)
export const getProfileEmailAttributeIsVerified = profile => _.get(profile,['email','attribute','isVerified'],false)
export const getProfilePhoneNumber = profile => _.get(profile,['phone','number'],null)
export const getProfilePhoneCompleted = profile => _.get(profile,['phone','complete'],null)
export const getProfilePhoneAttributeIsVerified = profile => _.get(profile,['phone','attribute','isVerified'],false)
export const getProfileGenderCode = profile => _.get(profile,['gender','code'],'')
export const getProfileGenderName = profile => _.get(profile,['gender','name'],'')
export const getProfileSocial = profile => _.get(profile,['social'],null)
export const getProfileDescription = profile => _.get(profile,['description'],"")
export const getProfileReferrerCode = profile => _.get(profile, ["referrerCode"], null);


export const getProfileAttributeIsFacebookConnected = profile =>
  _.get(profile, ["attribute", "is_facebook_connected"], false);
export const getProfileAttributeIsGoogleConnected = profile =>
  _.get(profile, ["attribute", "is_google_connected"], false);
export const getProfileAttributeTotalCount = profile => _.get(profile, ["attribute", "total_count"], 0);
export const getProfileYearOfExperience = profile => _.get(profile, ["year_of_experience"], 0);
export const getProfileFacebookLink = profile => _.get(profile, ["facebook_link"], "");
export const getProfileYoutubeLink = profile => _.get(profile, ["youtube_link"], "");
export const getProfileInstagramLink = profile => _.get(profile, ["instagram_link"], "");
export const getProfileTelegramLink = profile => _.get(profile, ["telegram_link"], "");
export const getProfileSocialLink = profile => _.get(profile, ["social_links"], null);
export const getProfileLanguageCode = profile => _.get(profile, ["language_code"], "en");
export const getProfileWhatsappLink = profile => _.get(profile,['whatsapp_link'],null)
export const getProfileParentNumber = profile => _.get(profile,['parent','mobile_full'],null)
export const getProfileAvatar = profile => _.get(profile,['avatar'],null)
export const getProfileAgent = profile => _.get(profile,['agent','items'],[])
export const getProfileAttributeShouldUpdatePhone = profile => _.get(profile,['attribute','should_update_phone'],false)
export const getProfileAttributeHasActiveSubscription = profile => _.get(profile,['attribute','has_active_subscription'],false)
export const getProfileAttributeHasEnableReferrer = profile => _.get(profile,['attribute','has_enable_referrer'],false)
export const getProfileAttributeUserPropertyCount = profile => _.get(profile,['attribute','user_property_count'],0)
export const getProfileAttributeUserPropertyTotalInstallmentValueText = profile => _.get(profile,['attribute','user_property_total_installment_value','text'],0)
export const getProfileAttributeUserPropertyTotalLoanOutstandingValueText = profile => _.get(profile,['attribute','user_property_total_loan_outstanding_value','text'],0)
export const getProfileAttributeUserPropertyTotalPurchasedValueText = profile => _.get(profile,['attribute','user_property_total_purchased_value','text'],0)
export const getProfileAttributeUserPropertyTotalNettValueText = profile => _.get(profile,['attribute','user_property_total_nett_value','text'],0)

//agent
export const getAgentAccessToken = agent => _.get(agent,['accessToken'],null)
export const getAgentAccountName = agent => _.get(agent,['account', 'name'],null)
export const getAgentAccountId = agent => _.get(agent,['account', 'id'],null)
export const getAgentAccountSummaryActiveListing = agent => _.get(agent,['account', 'summary', 'activeListing'],0)
export const getAgentAccountSubscriptionName = agent => _.get(agent,['account','subscription', 'name'],"")
export const getAgentAccountSubscriptionAvailable = agent => _.get(agent,['account','subscription'],"")
export const getAgentAccountSubscriptionMaxActiveListing = agent => _.get(agent,['account','subscription', 'maxActiveListing'],1)
export const getAgentAccountSubscriptionExpiredAtText = agent => _.get(agent,['account','subscription', 'expiredAt', 'text'],"")
export const getAgentAccountSubscription = agent => _.get(agent,['account','subscription'],"")
export const getAgentAccountSummaryBalanceQuota = agent => _.get(agent,['account','summary','balanceQuota'],0)
export const getAgentAccountCheckAccess = agent => _.get(agent,['account', 'attributes', 'canAccessAgentFunction'],"")
export const getAgentAccountisSubscribeFreePackage = agent => _.get(agent,['account', 'attributes', 'isSubscribeFreePackage'],"")

//point
export const getPointID = point => _.get(point, ["id"], null);
export const getPointExtra = point => _.get(point, ["extra"], null);
export const getPointBalance = point => _.get(point, ["balance"], 0);
export const getPointOnHold = point => _.get(point, ["on_hold"], 0);
export const getPointUsable = point => _.get(point, ["usable"], 0);

//referral code
export const getReferralCodeLoading = state => _.get(state, ["referralCodeList", "loading"], false);
export const getReferralCodeItems = state => _.get(state, ["referralCodeList", "items"], null);
export const getReferralCodePagination = state => _.get(state, ["referralCodeList", "pagination"], null);

export const getReferralHistoryListEnableReferrer = profile => _.get(profile,['attribute','has_enabled_referrer'],false)
export const getReferralHistoryListName = referralCodeList => _.get(referralCodeList,['user', 'displayName'],"")
export const getReferralHistoryListCommissionAmountText = referralCodeList => _.get(referralCodeList,['commissionAmount', 'text'],"")
export const getReferralHistoryListCommissionAmountValue = referralCodeList => _.get(referralCodeList,['commissionAmount', 'value'],"")
export const getReferralHistoryListReferrerAt = referralCodeList => _.get(referralCodeList,['referralAt'],"")
export const getReferralHistoryListSubscriptionCreatedAt = referralCodeList => _.get(referralCodeList,['subscriptionOrderItemCreatedAt'],"")
export const getOrderHistorySubscriptionName= referralCodeList => _.get(referralCodeList,['subscriptionData', 'name'],"")
export const getOrderHistorySubscriptionPaymentAmountText= referralCodeList => _.get(referralCodeList,['paymentAmount', 'text'],"")
export const getOrderHistorySubscriptionPaymentAmountValue= referralCodeList => _.get(referralCodeList,['paymentAmount', 'value'],"")
export const getOrderHistorySubscribedAt= referralCodeList => _.get(referralCodeList,['subscribedAt'],"")
export const getOrderHistoryStatusName= referralCodeList => _.get(referralCodeList,['status', 'name'],"")

