export const getProductListRequest = page => ({
  type: "product/GET_PRODUCT_LIST_REQUEST",
  page
});

export const getProductListSuccess = data => ({
  type: "GET_PRODUCT_LIST_SUCCESS",
  data
});

export const getProductListFailure = message => ({
  type: "GET_PRODUCT_LIST_FAILURE",
  message
});

export const getProductDetailRequest = id => ({
  type:"product/GET_PRODUCT_DETAIL_REQUEST",
  id
})

export const getProductDetailSuccess = (id,data) => ({
  type:"GET_PRODUCT_DETAIL_SUCCESS",
  id,
  data
})

export const getProductDetailFailure = message => ({
  type:"GET_PRODUCT_DETAIL_FAILURE",
  message
})
