import _ from "lodash"

export const getVerificationLoading = state => _.get(state,['verification','loading'],false)
export const getVerificationStatus = state => _.get(state,['verification','status'],false)
export const getVerificationData = state => _.get(state,['verification','data'],null)
export const getVerificationChangeEmailStatus = state => _.get(state,['verificationChangeEmail','status'],false)
export const getVerificationChangeEmailLoading = state => _.get(state,['verificationChangeEmail','loading'],false)
export const getVerificationChangeEmailData = state => _.get(state,['verificationChangeEmail','data'],false)
export const getVerificationChangePhoneStatus = state => _.get(state,['verificationChangePhone','status'],false)
export const getVerificationChangePhoneLoading = state => _.get(state,['verificationChangePhone','loading'],false)
export const getVerificationChangePhoneData = state => _.get(state,['verificationChangePhone','data'],false)

//verification
export const getVerificationToken = verification => _.get(verification,['token'],null)
export const getVerificationExpiryDatetimeTimestamp = verification => _.get(verification,['expiry_datetime','timestamp'],null)
