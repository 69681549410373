import Immutable from "seamless-immutable";
import httpErrorHelpers from "../services/httpUtilities/httpErrorHelpers";
import * as authActions from "../actions/auth";
import * as profileActions from "../actions/profile";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import Router from "next/router";
import Constant from "../constants";
import _ from "lodash";
import * as meActions from "../actions/profile";
import Toast from "../utils/Toast";
import * as globalActions from "../actions/global";
import * as profileSelectors from "../selectors/profile";
import * as agentAction from "../actions/agent";
import Cookies from "js-cookie";
import Constants from "../constants";

export default api => {
  function* watcherUpdateAccessToken({ token }) {
    localStorage.setItem(Constant.PG_TOKEN, token);
    Cookies.set(Constants.PG_TOKEN, token, {
      expires: 7
    });
    //todo if futher use needed
    // setCookie(null, "EWF_TOKEN", token, {
    //   path: "/",
    //   domain: ""
    // });
    api.setHeaderToken(token);
  }

  function* watcherPostVerifyPhoneNumberRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAuthVerify, postData);

      yield put(authActions.postVerifyPhoneNumberSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, authActions.postVerifyPhoneNumberFailure, effects);
    }
  }

  function* watcherPostVerifyPhoneNumberRequestInRoot({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAuthVerify, postData);

      yield put(authActions.postVerifyPhoneNumberSuccessInRoot(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, authActions.postVerifyPhoneNumberFailureInRoot, effects);
    }
  }

  function* watcherPostAuthSignUpRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAuthSignUp, postData);
      const { user, attribute, token, messages, profile } = response.data;
      if (!_.isEmpty(messages[0])) {
        Toast.success(messages[0].text);
      }
      const agent = profileSelectors.getProfileAgent(profile);
      if (!_.isEmpty(agent)) {
        const agentToken = profileSelectors.getAgentAccessToken(_.first(agent));
        yield put(authActions.updateAgentAccessToken(agentToken));
        yield put(agentAction.getAgentCommonDataRequest());
      }
      yield put(profileActions.updateProfileDetail(profile));
      const shouldUpdatePhone = _.get(attribute, ["should_update_phone"], false);
      yield put(authActions.updateAccessTokenInLocal(token));
      yield put(authActions.postAuthSignUpSuccess(response.data));
      // yield put(globalActions.showShouldUpdatePhone(shouldUpdatePhone))
    } catch (error) {
      yield call(httpErrorHelpers, error, authActions.postAuthSignUFailure, effects);
    }
  }

  function* watcherPostAuthSignInRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAuthSignIn, postData);
      const { user, attribute, token, messages, profile } = response.data;
      if (!_.isEmpty(messages[0])) {
        Toast.success(messages[0].text);
      }
      const agent = profileSelectors.getProfileAgent(profile);
      if (!_.isEmpty(agent)) {
        const agentToken = profileSelectors.getAgentAccessToken(_.first(agent));
        yield put(authActions.updateAgentAccessToken(agentToken));
        yield put(agentAction.getAgentCommonDataRequest());
      }
      yield put(profileActions.updateProfileDetail(profile));
      const shouldUpdatePhone = _.get(attribute, ["should_update_phone"], false);
      yield put(authActions.updateAccessTokenInLocal(token));
      yield put(authActions.postAuthSignInSuccess(response.data));
      //yield put(globalActions.showShouldUpdatePhone(shouldUpdatePhone));
    } catch (error) {
      yield call(httpErrorHelpers, error, authActions.postAuthSignInFailure, effects);
    }
  }

  function* watcherPostVerifyEmailRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAuthVerify, postData);

      yield put(authActions.postVerifyEmailSuccess(response.data));
    } catch (error) {
      yield call(httpErrorHelpers, error, authActions.postVerifyEmailFailure, effects);
    }
  }

  function* watcherUpdateAgentAccessToken({ token }) {
    localStorage.setItem(Constant.PG_AGENT_TOKEN, token);
    Cookies.set(Constants.PG_AGENT_TOKEN, token, {
      expires: 7
    });
    //todo if futher use needed
    // setCookie(null, "EWF_TOKEN", token, {
    //   path: "/",
    //   domain: ""
    // });
    api.setHeaderAgentToken(token);
  }

  function* watcherPostAuthFirebaseRequest({ postData }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.postAuthFirebase, postData);

      const { messages, item, user, token, attribute } = response.data;

      yield put(profileActions.updateProfileDetail(user));
      const shouldUpdatePhone = _.get(attribute, ["should_update_phone"], false);
    //  yield put(globalActions.showShouldUpdatePhone(shouldUpdatePhone));

      yield put(authActions.postAuthFirebaseSuccess());
      // if (rememberMe) {
      //   localStorage.setItem("EWF_USERNAME", phone);
      //   localStorage.setItem("EWF_PASSWORD", password);
      // } else {
      //   localStorage.removeItem("EWF_USERNAME");
      //   localStorage.removeItem("EWF_PASSWORD");
      // }

      yield put(authActions.updateAccessTokenInLocal(token));

      yield put(profileActions.getMeDataRequest());
    } catch (error) {
      yield call(httpErrorHelpers, error, authActions.postAuthFirebaseFailure, effects);
    }
  }

  function* watcherLoginAccountWithPhoneRequest({ phone, password, rememberMe }, effects) {
    const { call, put } = effects;
    try {
      const response = yield call(api.loginAccount, phone, password);

      const { messages, item, user, token } = response.data;

      yield put(profileActions.updateProfileDetail(user));
      yield put(authActions.loginAccountWithPhoneSuccess());

      if (rememberMe) {
        localStorage.setItem("EWF_USERNAME", phone);
        localStorage.setItem("EWF_PASSWORD", password);
      } else {
        localStorage.removeItem("EWF_USERNAME");
        localStorage.removeItem("EWF_PASSWORD");
      }

      yield put(authActions.updateAccessTokenInLocal(token));

      yield put(profileActions.getMeDataRequest());
    } catch (error) {
      yield call(httpErrorHelpers, error, authActions.loginAccountWithPhoneFailure, effects);
    }
  }

  function* watcherLogoutRequest({ payload }, effects) {
    const { call, put } = effects;
    Cookies.remove(Constants.PG_TOKEN);
    Cookies.remove(Constants.PG_AGENT_TOKEN);
    yield put(authActions.clearAccessToken());
    yield put(authActions.clearAgentAccessToken());
    yield put(profileActions.updateProfileDetail());

    Router.push("/login");
  }

  function* watcherClearAccessToken({ payload }, effects) {
    localStorage.removeItem(Constant.PG_TOKEN);
    destroyCookie(null, Constant.PG_TOKEN);
  }

  function* watcherClearAgentAccessToken({ payload }, effects) {
    localStorage.removeItem(Constant.PG_AGENT_TOKEN);
    destroyCookie(null, Constant.PG_AGENT_TOKEN);
  }

  const INITIAL_STATE = Immutable({
    phoneVerification: null,
    token: process.browser ? localStorage.getItem(Constant.PG_TOKEN) : null,
    agentToken: process.browser ? localStorage.getItem(Constant.PG_AGENT_TOKEN) : null
  });

  return {
    namespace: "auth",
    state: INITIAL_STATE,
    reducers: {
      UPDATE_ACCESS_TOKEN(state, action) {
        return state.setIn(["token"], action.token);
      },
      CLEAR_ACCESS_TOKEN(state) {
        return state.setIn(["token"], null);
      },
      UPDATE_AGENT_ACCESS_TOKEN(state, action) {
        return state.setIn(["agentToken"], action.token);
      },
      CLEAR_AGENT_ACCESS_TOKEN(state) {
        return state.setIn(["agentToken"], null);
      },
      POST_AUTH_FIREBASE_REQUEST(state) {
        return state
          .setIn(["auth", "firebase", "loading"], true)
          .setIn(["auth", "firebase", "status"], false);
      },
      POST_AUTH_FIREBASE_SUCCESS(state, action) {
        return state
          .setIn(["auth", "firebase", "loading"], false)
          .setIn(["auth", "firebase", "status"], true);
      },
      POST_AUTH_FIREBASE_FAILURE(state) {
        return state
          .setIn(["auth", "firebase", "loading"], false)
          .setIn(["auth", "firebase", "status"], false);
      },
      SET_SIGN_UP_ACCOUNT_DATA(state, action) {
        return state.setIn(["signUp", "user"], action.data);
      },
      LOGIN_ACCOUNT_WITH_PHONE_REQUEST(state) {
        return state.setIn(["signIn", "loading"], true).setIn(["signIn", "status"], false);
      },
      LOGIN_ACCOUNT_WITH_PHONE_SUCCESS(state, action) {
        return state.setIn(["signIn", "loading"], false).setIn(["signIn", "status"], true);
      },
      LOGIN_ACCOUNT_WITH_PHONE_FAILURE(state) {
        return state.setIn(["signIn", "loading"], false).setIn(["signIn", "status"], false);
      },
      POST_VERIFY_PHONE_NUMBER_REQUEST(state) {
        return state
          .setIn(["verifyPhoneNumber", "loading"], true)
          .setIn(["verifyPhoneNumber", "status"], false);
      },
      POST_VERIFY_PHONE_NUMBER_SUCCESS(state, action) {
        return state
          .setIn(["verifyPhoneNumber", "loading"], false)
          .setIn(["verifyPhoneNumber", "status"], true)
          .setIn(["verifyPhoneNumber", "data"], action.data);
      },
      POST_VERIFY_PHONE_NUMBER_FAILURE(state) {
        return state
          .setIn(["verifyPhoneNumber", "loading"], false)
          .setIn(["verifyPhoneNumber", "status"], false);
      },
      POST_VERIFY_PHONE_NUMBER_REQUEST_IN_ROOT(state) {
        return state
          .setIn(["verifyPhoneNumberInRoot", "loading"], true)
          .setIn(["verifyPhoneNumberInRoot", "status"], false);
      },
      POST_VERIFY_PHONE_NUMBER_SUCCESS_IN_ROOT(state, action) {
        return state
          .setIn(["verifyPhoneNumberInRoot", "loading"], false)
          .setIn(["verifyPhoneNumberInRoot", "status"], true)
          .setIn(["verifyPhoneNumberInRoot", "data"], action.data);
      },
      POST_VERIFY_PHONE_NUMBER_FAILURE_IN_ROOT(state) {
        return state
          .setIn(["verifyPhoneNumberInRoot", "loading"], false)
          .setIn(["verifyPhoneNumberInRoot", "status"], false);
      },
      POST_VERIFY_EMAIL_REQUEST(state) {
        return state.setIn(["verifyEmail", "loading"], true).setIn(["verifyEmail", "status"], false);
      },
      POST_VERIFY_EMAIL_SUCCESS(state, action) {
        return state
          .setIn(["verifyEmail", "loading"], false)
          .setIn(["verifyEmail", "status"], true)
          .setIn(["verifyEmail", "data"], action.data);
      },
      POST_VERIFY_EMAIL_FAILURE(state) {
        return state.setIn(["verifyEmail", "loading"], false).setIn(["verifyEmail", "status"], false);
      },
      POST_AUTH_SIGN_IN_REQUEST(state) {
        return state.setIn(["authSignIn", "loading"], true).setIn(["authSignIn", "status"], false);
      },
      POST_AUTH_SIGN_IN_SUCCESS(state, action) {
        return state
          .setIn(["authSignIn", "loading"], false)
          .setIn(["authSignIn", "status"], true)
          .setIn(["authSignIn", "data"], action.data);
      },
      POST_AUTH_SIGN_IN_FAILURE(state) {
        return state.setIn(["authSignIn", "loading"], false).setIn(["authSignIn", "status"], false);
      },
      POST_AUTH_SIGN_UP_REQUEST(state) {
        return state.setIn(["authSignUp", "loading"], true).setIn(["authSignUp", "status"], false);
      },
      POST_AUTH_SIGN_UP_SUCCESS(state, action) {
        return state
          .setIn(["authSignUp", "loading"], false)
          .setIn(["authSignUp", "status"], true)
          .setIn(["authSignUp", "data"], action.data);
      },
      POST_AUTH_SIGN_UP_FAILURE(state) {
        return state.setIn(["authSignUp", "loading"], false).setIn(["authSignUp", "status"], false);
      }
    },
    effects: {
      POST_AUTH_FIREBASE_REQUEST: [watcherPostAuthFirebaseRequest],
      UPDATE_ACCESS_TOKEN: [watcherUpdateAccessToken],
      REQUEST_LOGOUT: [watcherLogoutRequest],
      CLEAR_ACCESS_TOKEN: [watcherClearAccessToken],
      LOGIN_ACCOUNT_WITH_PHONE_REQUEST: [watcherLoginAccountWithPhoneRequest],
      UPDATE_AGENT_ACCESS_TOKEN: [watcherUpdateAgentAccessToken],
      CLEAR_AGENT_ACCESS_TOKEN: [watcherClearAgentAccessToken],
      POST_VERIFY_PHONE_NUMBER_REQUEST: [watcherPostVerifyPhoneNumberRequest],
      POST_VERIFY_PHONE_NUMBER_REQUEST_IN_ROOT: [watcherPostVerifyPhoneNumberRequestInRoot],
      POST_AUTH_SIGN_IN_REQUEST: [watcherPostAuthSignInRequest],
      POST_VERIFY_EMAIL_REQUEST: [watcherPostVerifyEmailRequest],
      POST_AUTH_SIGN_UP_REQUEST: [watcherPostAuthSignUpRequest]
    }
  };
};
